import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

const Wrapper = styled.div`
  background: ${Colors.white};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;

  position: relative;

  width: 912px;

  margin: 0 auto;
  padding: 20px 0;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

const RightSide = styled.div``;

const IconWrapper = styled.div`
  padding-right: 10px;
  margin-top: 4px;
  margin-left: -30px;

  position: absolute;
`;

const BackButton = styled.div`
  width: fit-content;

  display: flex;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;

  color: ${Colors.generalDark};
  margin-bottom: 3px;

  cursor: pointer;

  :hover {
    color: ${Colors.green};

    ${IconWrapper} * {
      stroke: ${Colors.green};
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-warp: nowrap;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.5px;

  color: ${Colors.generalDark};
`;

const LeftContent = styled.div``;

const StyledHeader = {
  Wrapper,
  Container,
  LeftSide,
  RightSide,
  BackButton,
  IconWrapper,
  TitleWrapper,
  Title,
  LeftContent,
};

export default StyledHeader;
