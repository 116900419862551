import { FC, memo } from 'react';

import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

import { StatusPillFullCompleted as StyledStatusPill } from './StatusPillFullCompleted.styles';

const StatusPillFullCompleted: FC<{ children; isSelected?: boolean }> = ({
  children,
  isSelected,
}) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Success} $isSelected={isSelected}>
      Закрыт {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillFullCompleted);
