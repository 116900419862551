import { FC, memo } from 'react';

import { downloadIcon, trashIcon } from '../../assets';

import Styled from './AuditDocumentsActionButtons.styled';

interface IActionButtonsProps {
  id: string;
  url: string;
  fileName: string;
  onDeleteHandler: (id: string, fileName: string) => void;
  isShowDeleteButton: boolean;
}

const AuditDocumentsActionButtons: FC<IActionButtonsProps> = ({
  id,
  fileName,
  url,
  onDeleteHandler,
  isShowDeleteButton,
}) => (
  <Styled.ButtonGroup data-test-id="audit-documents-action-buttons">
    <Styled.IconButton
      as="a"
      download
      href={url}
      data-test-id="audit-documents-action-download"
      $icon={downloadIcon}
    />
    {isShowDeleteButton ? (
      <Styled.IconButton
        data-test-id="audit-documents-action-delete"
        $icon={trashIcon}
        onClick={() => onDeleteHandler(id, fileName)}
      />
    ) : null}
  </Styled.ButtonGroup>
);

export default memo(AuditDocumentsActionButtons);
