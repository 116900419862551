import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const OperationInfo = styled.span`
  margin: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TaskTypeSvg = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 16px;
`;

const StyledOperationTypeCell = {
  TaskTypeSvg,
  Wrapper,
  OperationInfo,
};

export default StyledOperationTypeCell;
