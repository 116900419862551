import { Typography } from '@farmlink/farmik-ui';
import moment from 'moment';

import { IFile } from '../../../../../../../../../api/models/common';
import { TTableHeadData } from '../../../../../../../../common/components/table/Table.types';
import { AuditDocumentsActionButtons, CellWithTooltip } from '../components';

const getAuditDocumentsConfig = ({
  onDeleteHandler,
  getUserNameHandler,
  isShowDeleteButton,
}: {
  onDeleteHandler: (id: string, fileName: string) => void;
  getUserNameHandler: (rowData: IFile) => string;
  isShowDeleteButton: boolean;
}): TTableHeadData[] => {
  return [
    {
      headerName: 'Название',
      type: 'text',
      width: '40%',
      field: 'fileName',
      cellRenderer: (rowData: IFile) => {
        return <CellWithTooltip id={rowData.id}>{rowData?.fileName}</CellWithTooltip>;
      },
    },
    {
      headerName: 'Загрузил',
      type: 'text',
      width: '40%',
      field: 'fileUploadUserId',
      cellRenderer: (rowData: IFile) => {
        return <Typography>{getUserNameHandler(rowData)}</Typography>;
      },
    },
    {
      headerName: 'Дата',
      type: 'text',
      width: '15%',
      field: 'fileUploadDate',
      cellRenderer: (rowData: IFile) => (
        <Typography>{moment(rowData.fileUploadDate).format('DD.MM.YYYY')}</Typography>
      ),
    },
    {
      headerName: '',
      type: 'text',
      width: '5%',
      field: '',
      styles: {
        isNoOverflow: true,
        isRemoveLastCellInRowMargin: true,
      },
      alignItem: 'right',
      cellRenderer: (rowData: IFile) => (
        <AuditDocumentsActionButtons
          id={rowData.id}
          fileName={rowData.fileName}
          url={rowData.downloadUrl}
          onDeleteHandler={onDeleteHandler}
          isShowDeleteButton={isShowDeleteButton}
        />
      ),
    },
  ];
};

export { getAuditDocumentsConfig };
