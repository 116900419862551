import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { IAttributeValue } from '../../../../../../../../../../common/features/DynamicTable/models';
import { TaskReportViewChecklistAttrValue as ViewChecklistAttrValueComponent } from '../../../components';
import { ITaskReportInstance } from '../../../../../../../../../../../api/models/as-fields/task/Task';

function createViewChecklist(
  instanceId: string,
  options?: {
    isAggregateInstance?: boolean;
  }
): IAttributeValue {
  return {
    instanceId,
    stageId: 'system-stage',
    id: uuid(),
    attributeId: 'view-checklist-attr',
    value: options?.isAggregateInstance ? '' : 'Посмотреть чек-лист',
    Component: ViewChecklistAttrValueComponent,
  };
}

function createDateAttr(instance: ITaskReportInstance, date: string): IAttributeValue {
  const formattedDate = instance.type === 'AGGREGATE' ? '—' : moment(date).format('DD.MM.YYYY');

  return {
    instanceId: instance.id,
    stageId: 'system-stage',
    id: uuid(),
    attributeId: 'date-attr',
    value: formattedDate,
  };
}

const TaskReportAttributeValueHelpers = {
  createViewChecklist,
  createDateAttr,
};

export default TaskReportAttributeValueHelpers;
