import React, { MouseEvent, memo, useCallback, forwardRef } from 'react';

import { useDataTestId } from '../../../../../utils/hooks/locators';

import Styled from './TableFiltersBuilderTag.styles';

interface IProps {
  id: string;
  label: string;
  isDisableDeleting?: boolean;
  onDeleteClick?: (id: string) => void;
  dataTestId?: string;
}

const TableFiltersBuilderTag = forwardRef<HTMLDivElement, IProps>(
  ({ id, label, isDisableDeleting, onDeleteClick, dataTestId }, ref) => {
    const handleDeleteClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        onDeleteClick?.(id);
      },
      [onDeleteClick]
    );

    const getDataTestId = useDataTestId(dataTestId || 'tag');

    return (
      <Styled.Wrapper ref={ref} {...getDataTestId()}>
        <Styled.Label {...getDataTestId('label')}>{label}</Styled.Label>

        {!isDisableDeleting ? (
          <Styled.CloseButton onClick={handleDeleteClick} {...getDataTestId('close-button')} />
        ) : null}
      </Styled.Wrapper>
    );
  }
);

TableFiltersBuilderTag.displayName = 'TableFiltersBuilderTag';

export default memo(TableFiltersBuilderTag);
