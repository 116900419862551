import { IFormConfig } from '../../../../../../../../../common/features/form/models';

const REMARKS_FORM_KEY = 'remarks';

interface IRemarksForm {
  planDeviation: string;
  technologicalDeviation: string;
  specialNotes: string;
}

const createRemarksFormConfig = (isViewMode: boolean): IFormConfig<IRemarksForm> => {
  const remarksFormConfig: IFormConfig<IRemarksForm> = {
    formKey: REMARKS_FORM_KEY,
    form: {
      planDeviation: '',
      technologicalDeviation: '',
      specialNotes: '',
    },
    elements: {
      planDeviation: {
        name: 'planDeviation',
        type: 'textarea',
        order: 10,
        isDisabled: isViewMode,
        textareaOptions: {
          maxLength: 500,
        },
      },
      technologicalDeviation: {
        name: 'technologicalDeviation',
        type: 'textarea',
        order: 10,
        isDisabled: isViewMode,
        textareaOptions: {
          maxLength: 500,
        },
      },
      specialNotes: {
        name: 'specialNotes',
        type: 'textarea',
        order: 10,
        isDisabled: isViewMode,
        textareaOptions: {
          maxLength: 500,
        },
      },
    },
  };

  return remarksFormConfig;
};

export type { IRemarksForm };

export { REMARKS_FORM_KEY, createRemarksFormConfig };
