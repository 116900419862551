import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useMap } from '../../../../../../../../../../../common/features/experimentsMap/hooks';

import Styled from './ZonesMap.styles';

const ZonesMap: FC = () => {
  const { registerMap, clearStore } = useMap();

  useEffect(() => {
    registerMap('createExperimentZonesMap');

    return () => {
      clearStore();
    }
  }, []);

  return (
    <Styled.Wrapper id={'createExperimentZonesMap'} data-test-id={'createExperimentZonesMap'} />
  );
};

ZonesMap.displayName = 'ZonesMap';

export default observer(ZonesMap);
