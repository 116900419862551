import { ECommonColor } from '../../../assets/styles/colors';

enum EMessageType {
  Message = 'message',
  Notification = 'notification',
}

enum EMessageStyleType {
  /**
   * Белый
   */
  Default = 'default',
  /**
   * Синий
   */
  Info = 'info',
  /**
   * Оранжевый
   */
  Warning = 'warning',
  /**
   * Зелёный
   */
  Success = 'success',
  /**
   * Красный
   */
  Error = 'error',
  /**
   * Светло-серый
   */
  Draft = 'draft',
}

interface _MessageColorSchemeItem {
  messageBackgroundColor: ECommonColor;
  notificationBackgroundColor: ECommonColor;
  textColor: ECommonColor;
  borderColor: ECommonColor;
}

export type { _MessageColorSchemeItem };

export { EMessageStyleType, EMessageType };
