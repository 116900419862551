import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from '@farmlink/farmik-ui';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import TaskReportController from '../../mobx/controllers/TaskReport.controller';
import TaskReportStore from '../../mobx/stores/TaskReport.store';
import { EAuditRoute } from '../../../../routes';
import { EDashboardRoute } from '../../../../../../../../routes/DashboardRoute';
import { TAuditsParams } from '../../../../../../models/params/AuditsParams.model';

import Styled from './TaskReportHeaderActions.styles';

const TaskReportHeaderActions: FC = () => {
  const taskReportStore = useStore(TaskReportStore);

  const taskReportController = useStore(TaskReportController);

  const { auditId } = useParams<TAuditsParams>();

  const navigate = useNavigate();

  const goToFieldsComparison = () => {
    navigate(
      generatePath(`/${EDashboardRoute.Audits}/${auditId}/${EAuditRoute.Report}`, {
        auditId,
      })
    );
  };

  const calculateAverage = useCallback(async (): Promise<void> => {
    taskReportStore.clearDynamicTableConfig();

    const aggregateInstanceId = await taskReportController.createAggregateInstance();

    if (!aggregateInstanceId) {
      return;
    }

    await taskReportController.getDynamicTableConfig();
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.TitleWrapper>
        <Styled.Title>Отчёт по задаче</Styled.Title>

        {taskReportStore.availableTabList.length > 1 ? (
          <Tabs
            tabWidth="97.33px"
            selectedTabId={taskReportStore.selectedTabId}
            onChangeTab={taskReportController.changeTab}
            content={taskReportStore.availableTabList}
            size="small"
            dataTestId="test"
          />
        ) : null}
      </Styled.TitleWrapper>

      <Styled.ActionsWrapper>
        <Styled.ActionWrapper onClick={goToFieldsComparison}>
          <Styled.ArrowLeft />
          <Styled.Action> К сравнению по участкам</Styled.Action>
        </Styled.ActionWrapper>

        <Styled.Action
          onClick={calculateAverage}
          $isDisabled={!taskReportStore.selectedInstIdList.length}
        >
          Рассчитать среднее
        </Styled.Action>
      </Styled.ActionsWrapper>
    </Styled.Wrapper>
  );
};

TaskReportHeaderActions.displayName = 'TaskReportHeaderActions';

export default observer(TaskReportHeaderActions);
