import { PropsWithChildren, memo } from 'react';

import StyledLabel from './AuditDocumentsUploadFile.styles';

const AuditDocumentsUploadFile = (props: PropsWithChildren<{ onClick?: () => void }>) => {
  return (
    <StyledLabel onClick={props?.onClick}>
      <span>Загрузить документ</span>
      {props.children}
    </StyledLabel>
  );
};

AuditDocumentsUploadFile.displayName = 'AuditDocumentsUploadFile';

export default memo(AuditDocumentsUploadFile);
