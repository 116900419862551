import { FC, memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LinearDropdown } from '../../../../../../../../../../../../../common/features/UI';
import {
  ILinearDropdownChange,
  ILinearDropdownOption,
} from '../../../../../../../../../../../../../common/features/UI/LinearDropdown';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

// @ts-ignore
import blueCircleSvg from './assets/images/blue-circle-16x16px.svg';
// @ts-ignore
import redCircleSvg from './assets/images/red-circle-16x16px.svg';

interface IZoneListTypeCellProps {
  rowData: ICultureZoneCell;
  isDisabled?: boolean;
}

const ZoneListTypeCell: FC<IZoneListTypeCellProps> = ({ rowData, isDisabled }) => {
  const { changeZoneType } = useStore(ZonesController);

  const { changeCultureZoneStyle } = useMap();

  const optionList: ILinearDropdownOption[] = useMemo(() => {
    return [
      {
        label: 'Контрольный',
        value: EExperimentCultureZoneType.Control,
        icon: blueCircleSvg,
        isDisabled: rowData.isDisabledControlTypeSelection,
      },
      {
        label: 'Опытный',
        value: EExperimentCultureZoneType.Experiment,
        icon: redCircleSvg,
        isDefault: true,
      },
    ];
  }, [rowData.isDisabledControlTypeSelection]);

  const handleChange = useCallback<ILinearDropdownChange<any>>(
    value => {
      changeZoneType(
        { ...rowData.fwExpCultureZone, type: value, styleType: value },
        changeCultureZoneStyle
      );
    },
    [rowData.fwExpCultureZone]
  );

  return (
    <LinearDropdown
      optionList={optionList}
      onChange={handleChange}
      selectedOptionValue={rowData.type}
      isDisabled={isDisabled || !rowData.fwExpCultureZone.isSelected}
      styles={{ width: '130px' }}
    />
  );
};

ZoneListTypeCell.displayName = 'ZoneListTypeCell';

export default memo(observer(ZoneListTypeCell));
