import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Input, NewDropdown, useModal } from '@farmlink/farmik-ui';
import {
  TNewDropdownConfig,
  TPaginationConfig,
} from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import {
  EExpZoneStyle,
  EExperimentCultureZoneType,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { SelectContainerHelpers } from '../../../../../../../../../../../../common/features/form/containers/elements/SelectContainer/utils/helpers';
import { useStore } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { RadioButton } from '../../../components/ZonesList/components/RadioButton';
import { ZonesController } from '../../../mobx/controllers';
import { ZonesStore } from '../../../mobx/stores';
import { useMap } from '../../../../../../../../../../../../common/features/experimentsMap/hooks';

import Styled from './EditZoneInfoModal.styles';

type TEditZoneModalProps = {
  isSaveChanges: boolean;
};

const EditZonesModal: FC = () => {
  const {
    editableZoneId,
    getZone,
    fwExpCultureZoneList,
    previousCultureCurrentPage,
    previousCultureTotalPages,
    previousCultureOptionList,
    setPreviousCultureOptionList,
    previousCultureSearchQuery,
    setPreviousCultureSearchQuery,
    setTableHasChanged,
  } = useStore(ZonesStore);

  const {
    culturesSearchQueryHandler,
    onPreviousCultureListScroll,
    changePreviousCulturePageNumber,
    updateZoneInfo,
    changeZoneType,
  } = useStore(ZonesController);

  const { getDefaultValue } = SelectContainerHelpers;

  const { changeCultureZoneName, changeCultureZoneStyle } = useMap();

  const zone = getZone(editableZoneId);

  const [name, setName] = useState(zone?.name || '');
  const [type, setType] = useState<EExperimentCultureZoneType>(
    zone?.type || EExperimentCultureZoneType.Experiment
  );
  const [previousCultureId, setPreviousCultureId] = useState(
    zone?.prevCultureSelectOptionList?.[0]?.value || ''
  );

  const { closeModal, getModalPayload } = useModal();

  const modalPayload = (getModalPayload() || {}) as TEditZoneModalProps;

  const handleCloseClick = () => {
    if (!modalPayload?.isSaveChanges) {
      changeZoneType(
        {
          ...zone,
          isSelected: false,
          styleType: EExpZoneStyle.ReadyToExperiment,
        },
        changeCultureZoneStyle
      );
      setTableHasChanged(false);
    }

    closeModal();
  };

  const handleSaveClick = () => {
    updateZoneInfo(
      {
        name,
        type,
        previousCultureId,
        isSelected: Boolean(name),
        predecessorId: zone?.cultureZone?.predecessorList?.[0]?.culture?.id,
      },
      closeModal
    );
    changeCultureZoneName(zone?.polyId, name);
    changeCultureZoneStyle({
      ...zone,
      styleType: type as any,
    });
    setTableHasChanged(true);
  };

  const handleChangePreviousCulture = useCallback(value => {
    setPreviousCultureId(value);
  }, []);

  const onPreviousCultureScroll = useCallback(async () => {
    const newPreviousCultureOptionList = await onPreviousCultureListScroll(
      previousCultureSearchQuery
    );

    setPreviousCultureOptionList([...previousCultureOptionList, ...newPreviousCultureOptionList]);
  }, [previousCultureSearchQuery, setPreviousCultureOptionList]);

  useEffect(() => {
    (async () => {
      const prevCultureSelectOptionList = await culturesSearchQueryHandler(
        zone?.prevCultureSelectOptionList?.[0]?.label || ''
      );

      setPreviousCultureSearchQuery(zone?.prevCultureSelectOptionList?.[0]?.label || '');

      setPreviousCultureOptionList(prevCultureSelectOptionList);
    })();
  }, []);

  const hasControlType = useMemo(
    () => fwExpCultureZoneList.some(z => z.type === EExperimentCultureZoneType.Control),
    [fwExpCultureZoneList]
  );

  const defaultValue = useMemo(() => {
    return getDefaultValue(previousCultureId, previousCultureOptionList);
  }, [previousCultureId, previousCultureOptionList]);

  const dropdownConfig: TNewDropdownConfig = useMemo(
    () => ({
      field: {
        placeholder: 'Выберите или укажите значение',
        onChange: handleChangePreviousCulture,
        icons: {
          clear: {},
        },
        type: {
          search: {
            options: {
              isHideSearchFieldAutocomplete: true,
              searchQueryHandler: searchQuery => {
                setPreviousCultureSearchQuery(searchQuery);
                culturesSearchQueryHandler(searchQuery);
              },
            },
          },
        },
        defaultValue,
      },
      body: {
        optionList: previousCultureOptionList,
      },
      visual: {
        label: 'Культура-предшественник',
        isRounded: true,
      },
      styles: {
        fieldPadding: '10px 12px',
      },
    }),
    [
      previousCultureOptionList,
      previousCultureSearchQuery,
      defaultValue,
      handleChangePreviousCulture,
      culturesSearchQueryHandler,
    ]
  );

  const dropdownPaginationConfig: TPaginationConfig = useMemo(
    () => ({
      currentPage: previousCultureCurrentPage,
      totalPages: previousCultureTotalPages,
      onScroll: onPreviousCultureScroll,
      onPageChange: changePreviousCulturePageNumber,
    }),
    [
      previousCultureCurrentPage,
      previousCultureTotalPages,
      onPreviousCultureScroll,
      changePreviousCulturePageNumber,
    ]
  );

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title data-test-id={'add-previous-culture-modal-title'}>
          {modalPayload?.isSaveChanges ? 'Изменить данные по участку' : 'Внесите данные по участку'}
        </Styled.Title>
      </Styled.Header>
      <Styled.Body>
        <Styled.Container>
          <Input
            placeholder="Укажите значение"
            value={name}
            onChange={setName}
            maxLength={50}
            required
            label="Название участка"
          />
        </Styled.Container>
        <Styled.Container>
          <Styled.Label>Укажите тип участка</Styled.Label>
          <Styled.RadioButtonWrapper>
            <RadioButton
              name="type"
              color="blue"
              checked={type === EExperimentCultureZoneType.Control}
              onChange={() => setType(EExperimentCultureZoneType.Control)}
              disabled={
                (hasControlType && zone.type !== EExperimentCultureZoneType.Control) ||
                !zone.isSelected
              }
              fullWidth
            >
              Контроль
            </RadioButton>
            <RadioButton
              name="type"
              color="red"
              checked={type === EExperimentCultureZoneType.Experiment}
              onChange={() => setType(EExperimentCultureZoneType.Experiment)}
              disabled={!zone?.isSelected}
              fullWidth
            >
              Опыт
            </RadioButton>
          </Styled.RadioButtonWrapper>
        </Styled.Container>
        <Styled.Container>
          <NewDropdown config={dropdownConfig} paginationConfig={dropdownPaginationConfig} />
        </Styled.Container>
      </Styled.Body>
      <Styled.Footer>
        <Button
          type="button"
          color="default"
          onClick={handleCloseClick}
          dataTestId="add-previous-culture-modal-deny-button"
        >
          Отменить
        </Button>
        <Button
          color="primary"
          onClick={handleSaveClick}
          disabled={!Boolean(name)}
          dataTestId="add-previous-culture-modal-success-button"
        >
          Сохранить
        </Button>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

EditZonesModal.displayName = 'EditZonesModal';

export default observer(EditZonesModal);
