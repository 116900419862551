import { FC } from 'react';

import { IExperimentFactTableAudit } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { ConfigurableSlider } from '../../../../../../../../../common/components/ui';

import Styled from './ExecutionTableSlider.styles';
import { SliderItem } from './components';

interface IProps {
  name: string;
  audits?: IExperimentFactTableAudit[];
  dataTestId: string;
}

export const ExecutionTableSlider: FC<IProps> = ({ name, audits, dataTestId }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId('slider-row')}>
      {audits.length === 0 ? (
        <Styled.Title>
          {'По шагу '}
          <Styled.Name>{`«${name}»`}</Styled.Name>
          {' отсутствуют наблюдения'}
        </Styled.Title>
      ) : (
        <ConfigurableSlider gradientColor="#fff">
          {audits.map(audit => {
            return <SliderItem key={audit.id} audit={audit} dataTestId={dataTestId} />;
          })}
        </ConfigurableSlider>
      )}
    </Styled.Wrapper>
  );
};
