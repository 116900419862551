import { ITab } from '@farmlink/farmik-ui/dist/Tabs/types';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { EChecklistType } from '../../../../../../../../../../../api/models/as-fields/checklists/Checklist/Checklist.model';
import TaskReportStore from '../../stores/TaskReport.store';

@provide.transient()
class TaskReportTabsService {
  @lazyInject(TaskReportStore)
  protected taskReportStore: TaskReportStore;

  addAvailableTabList = (): void => {
    const availableTabList = this.createAvailableTabList();

    this.taskReportStore.setAvailableTabList(availableTabList);

    if (availableTabList.length) {
      this.taskReportStore.setSelectedTabId(availableTabList[0].id as EChecklistType);
    }
  };

  protected createAvailableTabList = () => {
    const completedChecklistTypeList = this.taskReportStore.selectedTask?.completedChecklistTypes;

    if (!completedChecklistTypeList) {
      return [];
    }

    const availableTabList: ITab[] = [];

    // Делаем явный поиск, так согласно дизайну нужно отображать в определенном порядке табы
    const pointTab = completedChecklistTypeList.find(type => type === EChecklistType.Point);
    const machineryTab = completedChecklistTypeList.find(type => type === EChecklistType.Machinery);
    const fieldTab = completedChecklistTypeList.find(type => type === EChecklistType.Field);

    if (pointTab) {
      availableTabList.push({
        label: 'Точка',
        id: EChecklistType.Point,
      });
    }

    if (machineryTab) {
      availableTabList.push({
        label: 'Техника',
        id: EChecklistType.Machinery,
      });
    }

    if (fieldTab) {
      availableTabList.push({
        label: 'Поле',
        id: EChecklistType.Field,
      });
    }

    return availableTabList;
  };
}

export default TaskReportTabsService;
