import { flatten } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { NutritionHistoryService } from '../../../../../../../../../../../common/mobx/services/as-fields';
import NutritionHistoryItemService from '../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { toNumber } from '../../../../../../../../../../../common/utils/helpers/numbers';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IInventoryValuesForm } from '../../config/forms/inventoryValuesForm';
import { CalculationStore } from '../store/Calculation/Calculation.store';
import { InventoryValueStore } from '../store/Calculation/InventoryValue.store';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import { CalculationController } from './Calculation/Calculation.controller';

@provide.singleton()
export class InventoryValueController {
  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(CalculationController)
  calculationController: CalculationController;

  @lazyInject(CalculationStore)
  calculationStore: CalculationStore;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(InventoryValueStore)
  inventoryValueStore: InventoryValueStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchFertilizers = async (searchQuery: string, page?: number) => {
    const fertilizers = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'fertilizers',
        attrs: {
          forAgriculturalUse: true,
        },
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return fertilizers;
  };

  fetchFertilizersPrices = async (experimentId: string) => {
    const fertilizerPrices = await this.nutritionHistoryService.getFertilizerPrices({
      experimentId,
    });

    this.inventoryValueStore.setFertilizerPrices(fertilizerPrices);
  };

  getInventoryValueOptionList = async (searchQuery: string, page?: number) => {
    const { content, totalPages } = await this.fetchFertilizers(searchQuery, page);

    const currentExperimentStep = this.calculationStore.currentTechOperation;
    const currentNutritionHistory = this.calculationStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const fertilizerIdListFromStoredNutritionHistory =
      currentNutritionHistory?.items?.map(item => item.fertilizer.id) ?? [];

    const storedCurrentFertilizerIdList = this.inventoryValueStore.currentFertilizerIdList;

    const formattedCurrentFertilizerIdList =
      storedCurrentFertilizerIdList?.length > 0
        ? storedCurrentFertilizerIdList
        : fertilizerIdListFromStoredNutritionHistory;

    const currentFertilizers = content.filter(
      item => !formattedCurrentFertilizerIdList.includes(item.id)
    );

    return {
      fertilizerList: [createDictionaryEntitySelectOptionList(currentFertilizers)],
      totalPages,
    };
  };

  createInventoryValue = async (
    form: IInventoryValuesForm,
    experimentStep: IExperimentStep,
    cultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    await this.nutritionHistoryItemService.createNutritionItemHistory({
      concentration: toNumber(form.dosage),
      cultureZoneExperimentId: cultureZone.id,
      experimentStepId: experimentStep.id,
      fertilizerId: form.nameValue,
      pricePerUnit: toNumber(form.price),
      isFact: false,
    });

    Promise.allSettled([
      this.calculationController.fetchNutritionHistories(
        {
          experimentId,
          cultureZoneExperimentId: cultureZone.id,
          experimentStepType: EExperimentStepType.Plan,
        },
        cultureZone.type === EExperimentCultureZoneType.Control
      ),
      this.calculationController.fetchCultureZones({ experimentId }, cultureZone.id),
    ]);
  };

  fertilizersSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const {
      setFertilizerCurrentPage,
      setFertilizerTotalPages,
      setFertilizeSearchQuery,
      setFertilizeOptionList,
    } = this.inventoryValueStore;

    const { fertilizerList, totalPages } = await this.getInventoryValueOptionList(searchQuery, 0);

    const [fertilizers] = fertilizerList;

    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setFertilizerCurrentPage(0);

    setFertilizerTotalPages(totalPages);

    setFertilizeOptionList(fertilizers);

    setFertilizeSearchQuery(searchQuery);

    return flatten(fertilizerList);
  };

  onFertilizeListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { fertilizerCurrentPage } = this.inventoryValueStore;

    const { fertilizerList } = await this.getInventoryValueOptionList(
      searchQuery,
      fertilizerCurrentPage
    );

    return flatten(fertilizerList);
  };

  updateInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    await this.nutritionHistoryItemService.updateNutritionItemHistory({
      nutritionHistoryItemId: id,
      concentration: toNumber(form.dosage),
      fertilizerId: form?.nameValue,
      pricePerUnit: toNumber(form.price),
    });
    Promise.allSettled([
      this.calculationController.fetchNutritionHistories({
        experimentId,
        cultureZoneExperimentId: cultureZone.id,
        experimentStepType: EExperimentStepType.Plan,
      }),
      this.calculationController.fetchCultureZones({ experimentId }, cultureZone.id),
    ]);
  };

  changeFertilizerPageNumber = (): void => {
    const { fertilizerCurrentPage, setFertilizerCurrentPage } = this.inventoryValueStore;

    setFertilizerCurrentPage(fertilizerCurrentPage + 1);
  };
}
