import { FC, memo } from 'react';

import { Icon } from '../../Icon';

import pinIconDefaultS32 from './assets/icons/pin-icon-default-32x32.svg';
import pinIconDefaultS32Hover from './assets/icons/pin-icon-default-hover-32x32.svg';
import pinIconSelectedS32 from './assets/icons/pin-icon-selected-32x32.svg';
import pinIconSelectedS32Hover from './assets/icons/pin-icon-selected-hover-32x32.svg';
import pinIconDefaultS24 from './assets/icons/pin-icon-default-24x24.png';
import pinIconDefaultS24Hover from './assets/icons/pin-icon-default-hover-24x24.png';
import pinIconSelectedS24 from './assets/icons/pin-icon-selected-24x24.png';
import pinIconSelectedS24Hover from './assets/icons/pin-icon-selected-hover-24x24.png';
import pinIconDefaultBlockedS32 from './assets/icons/pin-icon-default-blocked-32x32.svg';
import pinIconSelectedBlockedS32 from './assets/icons/pin-icon-selected-blocked-32x32.svg';
import pinIconDefaultBlockedS24 from './assets/icons/pin-icon-default-blocked-24x24.png';
import pinIconSelectedBlockedS24 from './assets/icons/pin-icon-selected-blocked-24x24.png';

const DEFAULT_DATA_TEST_ID = 'pin-button';

interface IProps {
  size: 's32' | 's24';
  isEnableEditing: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

const PinButton: FC<IProps> = ({ isSelected, onClick, size, dataTestId, isEnableEditing }) => {
  return (
    <Icon
      states={{
        s40: null,
        s32: {
          default: isEnableEditing ? pinIconDefaultS32 : pinIconDefaultBlockedS32,
          defaultHover: isEnableEditing ? pinIconDefaultS32Hover : pinIconDefaultBlockedS32,
          selected: isEnableEditing ? pinIconSelectedS32 : pinIconSelectedBlockedS32,
          selectedHover: isEnableEditing ? pinIconSelectedS32Hover : pinIconSelectedBlockedS32,
        },
        s24: {
          default: isEnableEditing ? pinIconDefaultS24 : pinIconDefaultBlockedS24,
          defaultHover: isEnableEditing ? pinIconDefaultS24Hover : pinIconDefaultBlockedS24,
          selected: isEnableEditing ? pinIconSelectedS24 : pinIconSelectedBlockedS24,
          selectedHover: isEnableEditing ? pinIconSelectedS24Hover : pinIconSelectedBlockedS24,
        },
      }}
      size={size}
      isSelected={isSelected}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
      onClick={isEnableEditing ? onClick : () => ''}
    />
  );
};

PinButton.displayName = 'PinButton';

export default memo(PinButton);
