import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CopyFieldForm } from '../../components/CopyFieldForm';
import { InventoryValuesForm } from '../../components/InventoryValuesForm';
import { OperationForm } from '../../components/OperationForm';

import { agrochemicalModalConfig } from './createAgrochemicalModal';

export const addPlanInventoryValuesModalConfig: TModalConfig = {
  id: `addPlanInventoryValues`,
  styles: {
    title: {
      $size: 'large',
      $margin: '0 auto 24px 0',
    },
  },
  styledProps: {
    $height: 'calc(100vh - 32px)',
    $size: '600px',
    $fullHeight: true,
    $modalPaddings: '40px 60px',
  },
  type: EModalType.Custom,
  children: () => <InventoryValuesForm formType={'add'} />,
  name: 'addInventoryValues',
  title: 'Добавить ТМЦ',
  closeOnClickOutside: true,
};

export const addTechoperationModalConfig: TModalConfig = {
  id: `addPlanTechOperation`,
  styles: {
    title: {
      $size: 'large',
      $margin: '0 auto 24px 0',
    },
  },
  styledProps: {
    $height: '100vh',
    $size: '600px',
    $fullHeight: true,
    $modalPaddings: '40px 60px',
  },
  type: EModalType.Custom,
  children: () => <OperationForm formType={'add'} />,
  name: 'addTechOperation',
  title: 'Добавить техоперацию',
  closeOnClickOutside: true,
};

const techOperationModalList: TModalConfig[] = [
  addTechoperationModalConfig,
  addPlanInventoryValuesModalConfig,
  {
    id: `editPlanTechOperation`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 24px 0',
      },
    },
    styledProps: {
      $height: '100vh',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <OperationForm formType={'edit'} />,
    name: 'editTechOperation',
    title: 'Изменить техоперацию',
    closeOnClickOutside: true,
  },
  {
    id: `editPlanInventoryValues`,
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 24px 0',
      },
    },
    styledProps: {
      $height: 'calc(100vh - 32px)',
      $size: '600px',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    type: EModalType.Custom,
    children: () => <InventoryValuesForm formType={'edit'} />,
    name: 'editInventoryValues',
    title: 'Изменить ТМЦ',
    closeOnClickOutside: true,
  },
  {
    name: 'copyFieldPlan',
    type: EModalType.Custom,
    title: '',
    id: 'copyFieldPlan',
    children: CopyFieldForm,
    dataTestId: 'nutrition-history-copy-field-modal',
  },
  ...agrochemicalModalConfig,
];

export default techOperationModalList;
