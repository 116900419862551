import { FC, memo } from 'react';

import { IExperimentStep } from '../../../../../../../../../../api/models/as-fields/experiments';
import { formatDate } from '../../../../../../../../../common/utils/helpers/dates';
import { useDataTestIdV2 } from '../../../../../../../../../common/utils/hooks/locators';
import { generateAuditsPath } from '../../../../../../../observations/utils/helpers';

import Styled from './AuditRemrks.style';

interface IAuditRemarksProps {
  auditInfo: IExperimentStep;
}

const AuditRemarks: FC<IAuditRemarksProps> = ({ auditInfo }) => {
  const getDataTestId = useDataTestIdV2('audit-remarks');

  const { name, specialNotes, technologicalDeviation, generalComment, id, planStartDate } =
    auditInfo;

  const formattedDate = formatDate(new Date(planStartDate));

  const handleGoToAudit = () => {
    window.open(
      generateAuditsPath('auditInfo', {
        auditId: id,
      }),
      '_blank'
    );
  };

  const isNoRemarkText = !specialNotes && !technologicalDeviation && !generalComment;

  return (
    <Styled.Wrapper {...getDataTestId('main-wrapper')}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Styled.Title {...getDataTestId('title')}>
          {name}
          {planStartDate ? ` от ${formattedDate}` : ''}
        </Styled.Title>
        <Styled.GoToAuditButton
          onClick={handleGoToAudit}
          type={'button'}
          {...getDataTestId('go-to-audit-button')}
        >
          Перейти к наблюдению
        </Styled.GoToAuditButton>
      </Styled.TitleWrapper>

      {isNoRemarkText ? (
        <Styled.NoDescription>Комментарий отсутствует</Styled.NoDescription>
      ) : (
        <Styled.Description {...getDataTestId('description')}>
          {specialNotes} {specialNotes ? <br /> : ''}
          {technologicalDeviation} {technologicalDeviation ? <br /> : ''}
          {generalComment}
        </Styled.Description>
      )}
    </Styled.Wrapper>
  );
};

AuditRemarks.displayName = 'AuditRemarks';

export default memo(AuditRemarks);
