import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  position: absolute;

  top: 16px;
  right: 16px;

  background: transparent;

  & > :not(:last-child) {
    margin-right: 12px;
  }
`;

const ArrowButtonWrapper = styled.div<{ $isShow: boolean }>`
  position: absolute;
  top: calc(50% - 20px);

  ${({ $isShow }) =>
    $isShow
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const LeftArrowButtonWrapper = styled(ArrowButtonWrapper)`
  left: -60px;
`;

const RightArrowButtonWrapper = styled(ArrowButtonWrapper)`
  right: -60px;
`;

const Image = styled.div<{ $url: string }>`
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${({ $url }) => $url});
`;

const Wrapper = styled.div`
  position: relative;

  width: 600px;
  height: 600px;

  border-radius: 16px;
  padding: 8px;

  background-color: ${ECommonColor.White};
`;

const StyledImagesCarousel = {
  ActionsWrapper,
  LeftArrowButtonWrapper,
  RightArrowButtonWrapper,
  Image,
  Wrapper,
};

export default StyledImagesCarousel;
