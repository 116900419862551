import { FC, memo } from 'react';

import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ITableBuilderRowsGroup as IRowsGroup } from '../../../models/data/TableBuilderRowsGroup';
import { TableBuilderRowsContainer as RowsContainer } from '../../rows/TableBuilderRowsContainer';

import Styled from './TableBuilderRowsGroupAutoRenderContainer.styles';

interface IProps {
  rowsGroup: IRowsGroup;
  borderType: string;
}

const TableBuilderRowsGroupAutoRenderContainer: FC<IProps> = ({ rowsGroup, borderType }) => {
  const context = useContext();

  const getDataTestId = useDataTestId(context.builderId);

  return (
    <Styled.Wrapper {...getDataTestId('auto-rows-group')}>
      <Styled.Title>{rowsGroup.autoRenderConfig.name}</Styled.Title>
      <RowsContainer
        rowsGroupId={rowsGroup.id}
        rowIdList={rowsGroup.rowIdList}
        borderType={borderType}
      />
    </Styled.Wrapper>
  );
};

TableBuilderRowsGroupAutoRenderContainer.displayName = 'TableBuilderRowsGroupAutoRenderContainer';

export default memo(TableBuilderRowsGroupAutoRenderContainer);
