import styled from 'styled-components';

const SVGWrapper = styled.svg`
  margin: auto 12px auto 0;

  &:hover {
    cursor: pointer;

    path {
      fill: #b70c00;
    }
  }
`;

const StyledDeleteIcon = {
  SVGWrapper,
};

export default StyledDeleteIcon;
