import { makeAutoObservable } from 'mobx';

import { AuditInfoService } from '../../services';
import { AuditInfoStore } from '../../stores';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ObservationController } from '../../../../../../../../../containers/Observations/mobx/controllers';

@provide.singleton()
class AuditInfoController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(AuditInfoStore)
  auditInfoStore: AuditInfoStore;

  @lazyInject(ObservationController)
  observationController: ObservationController;

  @lazyInject(AuditInfoService)
  auditInfoService: AuditInfoService;

  getAuditInfo = async (auditId: string) => {
    const auditInfo = await this.auditInfoService.getAuditInfo(auditId);
    this.auditInfoStore.setAuditInfo(auditInfo);
  };
}

export default AuditInfoController;
