import { observer } from 'mobx-react';
import { FC, memo, useState, useEffect, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { css } from 'styled-components';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { SidePanel, TSidePanelProps } from '../../../../../common/features/UI/SidePanel';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { MenuDropdown, IMenuDropdown } from '../../../../../common/features/UI/MenuDropdown';
import { ECommonColor } from '../../../../../common/assets/styles/colors';
import { ECreateEditContractRoute } from '../../modules/CreateEditContract/routes';
import { formatDate } from '../../../../../common/utils/helpers/dates';
import { useShowTooltip, useWarningBeforeDeleting } from '../../../../../common/utils/hooks';
import { AccessStore } from '../../../../../common/mobx/stores/AccessStore';
import { ContractsListController } from '../ContractsList/mobx/controllers';

import editIconSvg from './assets/icons/edit-icon.svg';
import deleteIconSvg from './assets/icons/delete-icon.svg';
import { ContractSidePanelController } from './mobx/controllers';
import { ContractSidePanelStore } from './mobx/stores';
import { ContractSidePanelService } from './mobx/services';
import { DocumentsTab, ExperimentsTab } from './components';
import { Tabs, ETabs } from './components/Tabs';
import Styled from './ContractSidePanel.styles';

const { AccentPink } = ECommonColor;

type TProps = Omit<TSidePanelProps, 'children'>;

const ContractSidePanel: FC<TProps> = ({ onClose }) => {
  const contractsListController = useStore(ContractsListController);
  const { fetchContractList } = contractsListController;

  const { isAllowToEditContracts } = useStore(AccessStore);

  const { getAccessToEditContract } = useStore(ContractSidePanelController);

  const { selectedContract, hasAccessToEditContracts, clearStore } =
    useStore(ContractSidePanelStore);

  const { deleteContract } = useStore(ContractSidePanelService);

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.Experiments);
  const { name, contractDate, organization } = selectedContract || {};
  const navigate = useNavigate();

  useEffect(
    () => () => {
      clearStore();
    },
    []
  );

  useEffect(() => {
    if (organization?.id) {
      getAccessToEditContract(organization.id);
    }
  }, [organization?.id]);

  const deleteContractAction = async () => {
    await deleteContract(selectedContract?.id);
    await fetchContractList(false);
    onClose();
  };

  const menuDropdownConfig: IMenuDropdown['config'] = useMemo(
    () => ({
      menu: {
        button: Styled.OptionsIcon,
        menuStyle: css`
          width: 155px;
          top: 30px;
          right: -70px;
        `,
      },
      menuItems: [
        {
          icon: editIconSvg,
          label: 'Редактировать',
          onClick: () => {
            navigate(
              generatePath(ECreateEditContractRoute.Edit, {
                contractId: selectedContract.id,
              })
            );
          },
        },
        {
          icon: deleteIconSvg,
          label: 'Удалить',
          labelStyle: css`
            color: ${AccentPink};
          `,
          onClick: () => {
            try {
              showWarningBeforeDeleting('договор', deleteContractAction);
            } catch (e) {
              console.log(e);
            }
          },
        },
      ],
    }),
    [selectedContract?.id]
  );

  return (
    <SidePanel onClose={onClose}>
      <Styled.Wrapper>
        <Styled.Header>
          <AutoTooltip position="bottom" content={`Договор № ${name}`} disabled={!showTooltip}>
            <Styled.ContractNumber ref={ref}>Договор № {name}</Styled.ContractNumber>
          </AutoTooltip>
          <Styled.ActionsWrapper>
            {(isAllowToEditContracts || hasAccessToEditContracts) && (
              <MenuDropdown config={menuDropdownConfig} />
            )}
            <Styled.CloseIcon onClick={onClose} />
          </Styled.ActionsWrapper>
        </Styled.Header>
        <Styled.SubHeaderWrapper>
          <Styled.OrganizationNameWrapper>{organization.name}</Styled.OrganizationNameWrapper>
          {formatDate(new Date(contractDate))}
        </Styled.SubHeaderWrapper>
        <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
        {activeTab === ETabs.Experiments && <ExperimentsTab />}
        {activeTab === ETabs.Documents && (
          <DocumentsTab isAllowToEditContracts={isAllowToEditContracts} />
        )}
      </Styled.Wrapper>
    </SidePanel>
  );
};

ContractSidePanel.displayName = 'ContractSidePanel';

export default memo(observer(ContractSidePanel));
