import { FC, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { Checkbox, ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IInventoryValuesForm,
  createInventoryValuesFormConfig,
  INVENTORY_VALUES_FORM_KEY,
} from '../../config/forms/inventoryValuesForm';
import { CalculationStore } from '../../mobx/store/Calculation/Calculation.store';
import { InventoryValueController } from '../../mobx/controllers/InventoryValue.controller';
import { InventoryValueStore } from '../../mobx/store/Calculation/InventoryValue.store';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

import Styled from './InventoryValuesForm.style';

export interface IInventoryValuesFormModalPayload {
  experimentId: string;
  currentFertilizerIdList?: string[];
  onCreate?: (form: IInventoryValuesForm, selectedItem: ISelectOption) => string;
  isExecutionPage?: boolean;
}

interface IInventoryValuesFormProps {
  formType: 'add' | 'edit';
}

const InventoryValuesForm: FC<IInventoryValuesFormProps> = ({ formType }) => {
  const [checked, setChecked] = useState(false);

  const { selectedExp } = useStore(CreateExperimentStore);

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const form = useForm<IInventoryValuesForm>(INVENTORY_VALUES_FORM_KEY);
  const {
    elements,
    getElement,
    registerForm,
    formData,
    initialFormData,
    changeInitialFormValue,
    submitForm,
    addOptionList,
    changeListOfFormValue,
    blockElement,
    addPaginationConfig,
  } = form;

  const { closeModal, openModalByModalId, getModalPayload } = useModal();
  const modalPayload = (getModalPayload() || {}) as IInventoryValuesFormModalPayload;

  const currentExperimentId = useMemo(
    () => modalPayload?.experimentId ?? selectedExp?.id,
    [selectedExp, modalPayload?.experimentId]
  );

  const isFormChanged = useMemo(
    () => !_.isEqual(formData, initialFormData),
    [formData, initialFormData]
  );

  const isRequiredFieldsNotEmpty = useMemo(
    () => _.values(_.omit(formData, ['typeValue'])).every(item => Boolean(item)),
    [formData]
  );

  const { typeValue: Type, nameValue: Name, dosage: Dosage, price: Price } = elements;

  const { currentInventoryValue, activeCultureZone, currentTechOperation, nutritionHistories } =
    useStore(CalculationStore);

  const {
    getPriceByFertilizerId,
    fertilizerCurrentPage,
    fertilizerTotalPages,
    fertilizeSearchQuery,
    setFertilizeOptionList,
    setFertilizerCurrentPage,
    fertilizeOptionList,
    fertilizerPrices,
    setCurrentFertilizerIdList,
    setCurrentFertilizerId,
    clearCurrentFertilizerIdList,
  } = useStore(InventoryValueStore);

  const {
    getInventoryValueOptionList,
    createInventoryValue,
    updateInventoryValue,
    fetchFertilizersPrices,
    fertilizersSearchQueryHandler,
    changeFertilizerPageNumber,
    onFertilizeListScroll,
  } = useStore(InventoryValueController);

  useEffect(() => {
    if (!modalPayload.currentFertilizerIdList) {
      return;
    }

    setCurrentFertilizerIdList(modalPayload?.currentFertilizerIdList);

    return () => {
      clearCurrentFertilizerIdList();
    };
  }, []);

  const onCreate = () => {
    submitForm(async forms => {
      if (modalPayload?.onCreate) {
        const selectedItemOptionList = getElement('nameValue')?.selectOptions?.optionList;
        const selectedItem = selectedItemOptionList.find(item => item.value === forms.nameValue);

        const createdItemByFertilizerId = modalPayload.onCreate(forms, selectedItem);

        setCurrentFertilizerId(createdItemByFertilizerId);
      } else {
        await createInventoryValue(
          forms,
          currentTechOperation,
          activeCultureZone,
          currentExperimentId
        );
      }

      if (checked) {
        openModalByModalId(`addPlanInventoryValues`);
        changeListOfFormValue({ typeValue: '', nameValue: '', dosage: '', price: '' });
        changeInitialFormValue({ typeValue: '', nameValue: '', dosage: '', price: '' });
        addOptionList('nameValue', []);
        setFertilizeOptionList([]);
        setChecked(false);
      } else {
        closeModal();
      }
    });
  };

  const onEdit = useCallback(() => {
    submitForm(async forms => {
      await updateInventoryValue(
        currentInventoryValue.id,
        forms,
        activeCultureZone,
        currentExperimentId
      );
      closeModal();
    });
  }, [activeCultureZone, currentExperimentId, currentInventoryValue]);

  useEffect(() => {
    registerForm(
      createInventoryValuesFormConfig({
        fertilizersSearchQueryHandler,
        isExecutionPage: modalPayload.isExecutionPage,
      })
    );
  }, []);

  useEffect(() => {
    /**
     * Когда режим редактирования, не выполняем запрос за списком удобрений, так как
     * запрещена смена его названия. Плюс в некоторых случаях название могло не
     * отображаться.
     */
    if (formType === 'edit') {
      return;
    }

    (async () => {
      /**
       * Получаем наименование тмц с пустым параметром query и 0 страницей
       */
      const { fertilizerList } = await getInventoryValueOptionList('', 0);

      const [fertilizer] = fertilizerList;

      /**
       * Запоминаем лист опций на будущее, если его придется копировать
       */
      setFertilizeOptionList(fertilizer);

      /**
       * Меняем начальную страницу данных на 0
       */
      setFertilizerCurrentPage(0);

      addOptionList('nameValue', fertilizer);
    })();
  }, [nutritionHistories]);

  const onFertilizeScroll = async () => {
    const fertilizers = await onFertilizeListScroll(fertilizeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('nameValue', [...fertilizeOptionList, ...fertilizers]);

    setFertilizeOptionList([...fertilizeOptionList, ...fertilizers]);
  };

  useEffect(() => {
    addPaginationConfig('nameValue', {
      currentPage: fertilizerCurrentPage,
      totalPages: fertilizerTotalPages,
      onScroll: onFertilizeScroll,
      onPageChange: changeFertilizerPageNumber,
    });
  }, [fertilizerCurrentPage, fertilizerTotalPages, onFertilizeScroll, changeFertilizerPageNumber]);

  useEffect(() => {
    fetchFertilizersPrices(currentExperimentId);
  }, [currentExperimentId]);

  useEffect(() => {
    if (formData?.nameValue) {
      const fertilizerPrice = getPriceByFertilizerId(formData.nameValue);

      if (fertilizerPrice) {
        changeListOfFormValue({ price: String(fertilizerPrice.pricePerUnit) });
      }
    }
  }, [formData?.nameValue, fertilizerPrices]);

  useEffect(() => {
    if (formType === 'edit') {
      // Блокируем смену названия при редактировании
      blockElement('nameValue', true);

      /**
       * Когда режим редактирования, то опшен для поля "Наименование ТМЦ" явно
       * хардкодим из полученной модели. Это необходимо для того, чтобы правильно
       * отобразить наименование без лишних запросов на бэк.
       */
      if (currentInventoryValue?.fertilizer) {
        addOptionList('nameValue', [
          {
            label: currentInventoryValue?.fertilizer?.name,
            value: currentInventoryValue?.fertilizer?.id,
          },
        ]);
      }

      if (currentInventoryValue) {
        changeListOfFormValue({
          dosage: currentInventoryValue.concentration
            ? String(currentInventoryValue.concentration)
            : '',
          nameValue: currentInventoryValue.fertilizer.id,
          typeValue: '',
          price: currentInventoryValue.pricePerUnit
            ? String(currentInventoryValue.pricePerUnit)
            : '',
        });
        changeInitialFormValue({
          dosage: currentInventoryValue.concentration
            ? String(currentInventoryValue.concentration)
            : '',
          nameValue: currentInventoryValue.fertilizer.id,
          typeValue: '',
          price: currentInventoryValue.pricePerUnit
            ? String(currentInventoryValue.pricePerUnit)
            : '',
        });
      }
    }
  }, [formType]);

  const successButton = useMemo(() => {
    return {
      title: formType === 'add' ? 'Добавить' : 'Сохранить',
      handler: formType === 'add' ? onCreate : onEdit,
      disabled: formType === 'add' ? !isRequiredFieldsNotEmpty : !isFormChanged,
    };
  }, [formType, isFormChanged, isRequiredFieldsNotEmpty, onCreate, onEdit]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref}>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Type && <Type />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Name && <Name />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Dosage && <Dosage />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Price && <Price />}
        </Styled.Attribute>

        {formType === 'add' && (
          <Styled.CheckboxAttribute>
            <Styled.Checkbox value={checked} label="Добавить еще одно ТМЦ" onChange={setChecked} />
          </Styled.CheckboxAttribute>
        )}
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter successButton={successButton} denyButton={denyButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

export default observer(InventoryValuesForm);
