import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

import { IImagesSelectionCardProps } from './ImagesSelectionCard';
import fileIcon from './assets/icons/file-icon-47x47px.svg';

const Name = styled.div`
  flex: 1 1 50%;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-right: 24px;

  color: ${ECommonColor.GeneralBlack};
`;

const PinButtonWrapper = styled.div<{ $isEnableEditing: boolean }>`
  flex: 0 0 24px;

  ${({ $isEnableEditing }) =>
    !$isEnableEditing &&
    css`
      & div {
        box-shadow: none;

        :hover {
          cursor: default;
        }
      }
    `}
`;

const Header = styled.div`
  width: 100%;
  height: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;
`;

const ArrowButtonWrapper = styled.div<{ $isShow: boolean }>`
  position: absolute;
  top: calc(50% - 12px);

  ${({ $isShow }) =>
    $isShow
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const LeftArrowButtonWrapper = styled(ArrowButtonWrapper)`
  left: 10px;
`;

const RightArrowButtonWrapper = styled(ArrowButtonWrapper)`
  right: 10px;
`;

const Image = styled.div<{ $url: string }>`
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${({ $url }) => $url});
`;

const Plug = styled.div`
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${fileIcon});
`;

const Content = styled.div<{ $hasData?: boolean }>`
  position: relative;
  width: 100%;

  margin-bottom: 12px;

  ${({ $hasData }) =>
    $hasData
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: auto;
        `}
`;

const ImagesCount = styled.div`
  font-size: 12px;
  line-height: 16px;

  text-align: end;
  flex: 0 1 auto;
  margin-left: 24px;

  color: ${ECommonColor.GeneralBlack};
`;

const SelectedImageName = styled.div`
  font-size: 12px;
  line-height: 16px;

  flex: 1 1 60%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${ECommonColor.GeneralBlack};
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
`;

interface IWrapperProps {
  $cardWidth?: IImagesSelectionCardProps['cardWidth'];
  $mainHeight?: IImagesSelectionCardProps['mainHeight'];
}

const Wrapper = styled.div<IWrapperProps>`
  width: ${({ $cardWidth }) => $cardWidth || '200px'};

  border-radius: 10px;
  background-color: ${ECommonColor.SecondaryGray};

  padding: 12px 16px;

  & ${Content} {
    width: ${({ $cardWidth }) => `calc(${$cardWidth} - 32px)` || '200px'};
    height: ${({ $mainHeight }) => $mainHeight || '200px'};
  }
`;

const StyledImagesSelectionCard = {
  Name,
  PinButtonWrapper,
  Header,
  RightArrowButtonWrapper,
  LeftArrowButtonWrapper,
  Image,
  Plug,
  Content,
  ImagesCount,
  SelectedImageName,
  Footer,
  Wrapper,
};

export default StyledImagesSelectionCard;
