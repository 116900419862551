import { FC, memo, useMemo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useShowTooltip } from '../../../../../../../../../../../../../common/utils/hooks';

import Styled from './ZoneListPredecessorCell.styles';

interface IZoneListPredecessorCellProps {
  rowData?: ICultureZoneCell;
}

const ZoneListPredecessorCell: FC<IZoneListPredecessorCellProps> = ({
  rowData: { fwExpCultureZone },
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const cultureNameList = useMemo<string>(() => {
    const nameList = fwExpCultureZone.prevCultureSelectOptionList.map(option => option.label);

    return nameList.join(' ,');
  }, [fwExpCultureZone.prevCultureSelectOptionList]);

  return (
    <Styled.Wrapper>
      <AutoTooltip position="top" content={cultureNameList} disabled={!showTooltip}>
        <Styled.CultureNameList ref={ref} $isDisabled={!fwExpCultureZone.isSelected}>
          {cultureNameList || 'предшественник не указан'}
        </Styled.CultureNameList>
      </AutoTooltip>
    </Styled.Wrapper>
  );
};

ZoneListPredecessorCell.displayName = 'ZoneListPredecessorCell';

export default memo(observer(ZoneListPredecessorCell));
