import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { ButtonLink, Tabs, useModal } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { useSelectedExp, useZonesPlugResolver } from '../../../../hooks';
import { TableBuilder } from '../../../../../../../../../common/features/TableBuilder';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { EExperimentsTableBuilderId } from '../../../../../../constants/configs';
import { IActiveSubstancesData } from '../../../../../../../../../common/components/ActiveSubstancesTable/model/IActiveSubstancesData.model';
import { createActiveSubstancesTableBuilderData } from '../../../../../../../../../common/components/ActiveSubstancesTable/helpers/createActiveSubstancesTableBuilderData';
import { createTechOperationsTableBuilderData } from '../../../../../../../../../common/components/TechOperationsTable/helpers/createTechOperationsTableBuilderData';
import { ConfigurableSlider } from '../../../../../../../../../common/components/ui';
import { SpinnerLoader } from '../../../../../../../../../common/components/ui/loaders';
import { useWarningBeforeDeleting } from '../../../../../../../../../common/utils/hooks';
import { EExperimentStepType } from '../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from './constants/titles';
import Styled from './History.styles';
import { HistoryController } from './mobx/controllers/History.contorller';
import { HistoryStore } from './mobx/stores/History.store';
import { createCarouselItems } from './helpers/createCarouselItems';
import { tabContent } from './constants';
import techOperationModalList from './configs/modals/techOperationModalList';
import { CarouselItem } from './components/CarouselIem';
import { HistoryPlug } from './components/TechOperationPlug';
import { NoPreviousCulturePlug } from './components/NoPreviousCulturePlug';
import { useSliderControl } from './hooks';
import { SliderContext } from './context';

const History: FC = () => {
  const {
    cultureZones,
    activeCultureZone,
    activeTab,
    activeTabName,
    nutritionHistories,
    experimentSteps,
    getExperimentStepById,
    getNutritionHistoryItemById,
  } = useStore(HistoryStore);

  const tableBuilderController = useStore(TableBuilderController);

  const {
    fetchCultureZones,
    onChangeActiveCultureZone,
    onChangeActiveTab,
    fetchExperimentSteps,
    fetchNutritionHistories,
    deleteExperimentStep,
    deleteInventoryValue,
    onChangeInventoryValue,
    onChangeTechOperation,
    initiateTable,
  } = useStore(HistoryController);

  const { openModalByModalId, registerModalList } = useModal();

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const sliderControl = useSliderControl();

  const {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExp?.id, NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE);

  const isViewOnly = useMemo(() => !selectedExp?.canEditExperiment, [selectedExp]);
  const carouselItems = useMemo(() => createCarouselItems(cultureZones), [cultureZones]);

  const currentCultureZoneId = useMemo(() => activeCultureZone?.id ?? '', [activeCultureZone]);

  const [builderId, setBuilderId] = useState(EExperimentsTableBuilderId.HistoryTechOperations);

  const onDeleteInventoryValue = (stepId: string, fertilizerId: string) => {
    deleteInventoryValue(stepId, fertilizerId, {
      experimentId: selectedExp?.id,
      cultureZoneExperimentId: currentCultureZoneId,
    });
  };

  const onEditInventoryValue = (id: string) => {
    const nutritionHistory = getNutritionHistoryItemById(id);
    onChangeInventoryValue(nutritionHistory);
    openModalByModalId(`editNutritionHistoryInventoryValues`);
  };

  const tableBuilderData = useMemo(() => {
    if (activeTab === 'activeSubstance') {
      return createActiveSubstancesTableBuilderData(experimentSteps, nutritionHistories);
    } else {
      return createTechOperationsTableBuilderData(experimentSteps, nutritionHistories);
    }
  }, [experimentSteps, nutritionHistories, activeTab]);

  useEffect(() => {
    if (selectedExp) {
      fetchCultureZones({ experimentId: selectedExp.id });
    }
  }, [selectedExp]);

  useEffect(() => {
    if (activeCultureZone && selectedExp) {
      fetchNutritionHistories({
        experimentId: selectedExp.id,
        cultureZoneExperimentId: activeCultureZone.id,
        experimentStepType: EExperimentStepType.NutritionHistory,
      });

      fetchExperimentSteps({
        experimentId: selectedExp.id,
        type: EExperimentStepType.NutritionHistory,
        cultureZoneExperimentId: activeCultureZone.id,
      });
    }
  }, [selectedExp, activeCultureZone]);

  useEffect(() => {
    registerModalList(techOperationModalList, 'nutritionHistory');
  }, []);

  const onClickAdd = () => {
    openModalByModalId('addNutritionHistoryTechOperation');
  };

  const onClickAddTechoperation = (row: Pick<IActiveSubstancesData, 'id'>) => {
    const experimentStep = getExperimentStepById(row.id);
    onChangeTechOperation(experimentStep);
    openModalByModalId(`addNutritionHistoryInventoryValues`);
  };

  const onClickDeleteTechoperation = (row: Pick<IActiveSubstancesData, 'id' | 'name'>) => {
    showWarningBeforeDeleting(`техоперацию : «${row.name}»`, () =>
      deleteExperimentStep(row.id, {
        experimentId: selectedExp.id,
        type: EExperimentStepType.NutritionHistory,
        cultureZoneExperimentId: activeCultureZone.id,
      })
    );
  };

  const onClickEditTechoperation = (row: Pick<IActiveSubstancesData, 'id'>) => {
    const experimentStep = getExperimentStepById(row.id);
    onChangeTechOperation(experimentStep);
    openModalByModalId(`editNutritionHistoryTechOperation`);
  };

  const onClickDeleteInventoryValue = (row, fertilizerId) => {
    const inventoryValueName = row.children.find(
      inventoryValue => inventoryValue.fertilizer.id === fertilizerId
    ).fertilizer.name;

    showWarningBeforeDeleting(`ТМЦ : «${inventoryValueName}»`, () =>
      onDeleteInventoryValue(row.id, fertilizerId)
    );
  };

  const onClickEditInventoryValue = (fertilizerId: string) => {
    onEditInventoryValue(fertilizerId);
  };

  const onClickCopy = () => {
    openModalByModalId('copyFieldNutritionHistory');
  };

  useEffect(() => {
    let newBuilderId = EExperimentsTableBuilderId.HistoryTechOperations;

    if (activeTab === 'activeSubstance') {
      newBuilderId = EExperimentsTableBuilderId.HistoryActiveSubstances;
    }

    setBuilderId(newBuilderId);

    initiateTable(
      onClickAddTechoperation,
      onClickDeleteTechoperation,
      onClickEditTechoperation,
      onClickDeleteInventoryValue,
      onClickEditInventoryValue,
      newBuilderId,
      isViewOnly
    );
  }, [activeTab, nutritionHistories, experimentSteps]);

  useEffect(() => {
    tableBuilderController.showLoader(builderId);

    tableBuilderController.addRowsToRowsGroupListById(builderId, builderId, tableBuilderData, {
      isClearPreviousList: true,
    });

    tableBuilderController.hideLoader(builderId);
  }, [tableBuilderData, builderId]);

  const existPreviousCulture = useMemo(() => {
    /**
     * Если хотя бы у одного участка нет культуры предшественника, то выводим заглушку
     */
    const isAllFieldsHavePreviousCulture = !cultureZones.find(
      zone => zone.previousCultures.length === 0
    );

    return isAllFieldsHavePreviousCulture;
  }, [cultureZones]);

  const isShowUnderTableButtons = useMemo(() => {
    return existPreviousCulture && !isViewOnly && tableBuilderData.length > 0;
  }, [existPreviousCulture, isViewOnly, tableBuilderData]);

  if (loading) return <SpinnerLoader needToHideContent={false} />;

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper data-test-id={`history-main-wrapper`}>
      <ConfigurableSlider dataTestId="history-culture-zones">
        {carouselItems.map(item => {
          return (
            <CarouselItem
              id={item.id}
              key={item.id}
              name={item.name}
              description={item.description}
              selected={item.id === currentCultureZoneId}
              img={item.img}
              handleSelectItem={onChangeActiveCultureZone}
              sections={cultureZones}
            />
          );
        })}
      </ConfigurableSlider>
      <Styled.Container data-test-id={`history-main-container`}>
        <Styled.TitleWrapper data-test-id={`history-title-wrapper`}>
          <Styled.Title data-test-id={`history-title`}>
            История внесения удобрений
            <Styled.InfoIcon data-test-id={`history-info-icon`} />
          </Styled.Title>
          {isShowUnderTableButtons && (
            <ButtonLink color="accent" onClick={onClickCopy} dataTestId={`history-copy-button`}>
              <Styled.CopyIcon data-test-id={`history-copy-icon`} />
              Скопировать данные с другого участка
            </ButtonLink>
          )}
        </Styled.TitleWrapper>
        {!existPreviousCulture ? (
          <NoPreviousCulturePlug />
        ) : tableBuilderData.length > 0 ? (
          <>
            <Styled.TabsWrapper>
              <Tabs
                selectedTabId={activeTab}
                onChangeTab={onChangeActiveTab}
                content={tabContent}
                stylePreset="secondary"
                size="medium"
                dataTestId="history-tabs"
              />
            </Styled.TabsWrapper>
            <Styled.SubTitleWrapper data-test-id={`history-subtitle-wrapper`}>
              <Styled.SubTitle data-test-id={`history-title-${activeTabName}`}>
                {activeTabName}
              </Styled.SubTitle>
            </Styled.SubTitleWrapper>
            <SliderContext.Provider value={sliderControl}>
              <TableBuilder builderId={builderId} stylePreset="cleared" borderType="dashed" />
            </SliderContext.Provider>
          </>
        ) : (
          <HistoryPlug onClickAdd={onClickAdd} />
        )}
        {isShowUnderTableButtons && (
          <Styled.AddTechOperationButton
            color="primary"
            styleType="outlined"
            type="button"
            onClick={onClickAdd}
            data-test-id={`history-tech-operation-button`}
          >
            Добавить техоперацию
          </Styled.AddTechOperationButton>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default observer(History);
