import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  ICreateExperimentStepDto,
  IExperimentStep,
} from '../../../../../../api/models/as-fields/experiments';
import {
  TChangeExperimentStepApprovalStatusRes,
  TDeleteExperimentStepReq,
  TGetExperimentStepEventListRes,
  TGetExperimentStepListReq,
  TGetExperimentStepReq,
  TPostExperimentStepCommentsRes,
  TGetMonitoringReportDataReq,
  TGetMonitoringReportDataRes,
  TUpdateExperimentStepReq,
  TUpdateExperimentStepFilesReq,
  TGetDictionaryEntityListReq,
} from '../../../../../../api';
import {
  EExperimentStepApprovalStatus,
  EExperimentStepEventType,
} from '../../../../../../api/models/as-fields/experiments/ExperimentComment';
import { TApiRequest, TApiResponse } from '../../axios/AxiosService/Axios.service.types';
import { ISelectOption } from '../../../../components/form/Dropdown/Dropdown.types';
import { createDictionaryEntitySelectOptionList } from '../../../../utils/helpers/selectOptions';
import { IDictionaryEntity } from '../../../../../../api/models/da-dictionary/dictionary';
import { DictionaryService } from '../../da-dictionary';

@provide.singleton()
class ExperimentStepsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  getExperimentStep = async (payload: TGetExperimentStepReq): Promise<IExperimentStep> => {
    const { api } = this.axiosService;

    try {
      const fetchedStep = await api.getExperimentStep(payload, { omit: ['id'] });

      return fetchedStep;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getFactStep = async (
    payload: TApiRequest<'getExperimentStep'>
  ): Promise<TApiResponse<'getFactExperimentStep'>> => {
    const { api } = this.axiosService;

    try {
      const fetchedStep = await api.getFactExperimentStep(payload, { omit: ['id'] });

      return fetchedStep;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getExperimentStepList = async (
    payload: TGetExperimentStepListReq,
    query?: { [key: string]: any }
  ): Promise<IExperimentStep[]> => {
    try {
      const { content } = await this.axiosService.api.getExperimentStepList(payload, { query });

      return content;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  deleteExperimentStep = async (payload: TDeleteExperimentStepReq): Promise<boolean> => {
    const { deleteExperimentStep } = this.axiosService.api;

    try {
      await deleteExperimentStep(payload, { omit: ['experimentStepId'] });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createExperimentStep = async (payload: ICreateExperimentStepDto) => {
    const { createExperimentStep } = this.axiosService.api;

    try {
      const { id } = await createExperimentStep(payload);

      return id;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  updateExperimentStep = async (payload: TUpdateExperimentStepReq, returnFullData?: boolean) => {
    const { updateExperimentStep } = this.axiosService.api;

    try {
      const stepData = await updateExperimentStep(payload, { omit: ['experimentStepId'] });

      if (returnFullData) {
        return stepData;
      }

      return stepData.id;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateExperimentStepFiles = async (payload: TUpdateExperimentStepFilesReq) => {
    const { updateExperimentStepFiles } = this.axiosService.api;

    try {
      const stepData = await updateExperimentStepFiles(payload, { omit: ['experimentStepId'] });

      return stepData;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchChecklistCommentList = async (
    stepId: string,
    type: EExperimentStepEventType
  ): Promise<TGetExperimentStepEventListRes> => {
    try {
      const commentList = await this.axiosService.api.getExperimentStepEventList(
        { stepId, typeEvent: type },
        { omit: ['stepId'] }
      );

      return commentList;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  postComment = (stepId: string, comment: string): Promise<TPostExperimentStepCommentsRes> => {
    try {
      return this.axiosService.api.postExperimentStepComment(
        { id: stepId, comment },
        { omit: ['id'] }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  changeStepStatus = (
    stepId: string,
    status: EExperimentStepApprovalStatus
  ): Promise<TChangeExperimentStepApprovalStatusRes> => {
    try {
      return this.axiosService.api.changeExperimentStepApprovalStatus(
        { id: stepId, status },
        { omit: ['id'] }
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  generateMonitoringReport = async (stepId: string): Promise<Blob> => {
    const { generateMonitoringReport } = this.axiosService.api;

    const response = await generateMonitoringReport({ stepId }, { responseType: 'blob' });

    return response;
  };

  getMonitoringReportData = async (
    payload: TGetMonitoringReportDataReq
  ): Promise<TGetMonitoringReportDataRes> => {
    const { getMonitoringReportData } = this.axiosService.api;

    try {
      const response = await getMonitoringReportData(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  getExperimentStepCompleteList = async (
    payload: TApiRequest<'getExperimentStepCompleteList'>,
    config?: {
      query?: Record<string, any>;
      actions?: {
        showLoader?: () => void;
        hideLoader?: () => void;
        success?: (response: TApiResponse<'getExperimentStepCompleteList'>) => void;
      };
    }
  ): Promise<TApiResponse<'getExperimentStepCompleteList'>> => {
    try {
      config?.actions?.showLoader?.();

      const response = await this.axiosService.api.getExperimentStepCompleteList(payload, {
        query: config?.query,
      });

      if (config?.actions?.success) {
        config?.actions?.success?.(response);
      }

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      config?.actions?.hideLoader?.();
    }
  };

  getUsageMethodTypeDictionary = async (
    payload?: TGetDictionaryEntityListReq
  ): Promise<IDictionaryEntity[]> => {
    const { content } = await this.dictionaryService.getDictionaryEntityList(
      {
        ...payload,
        remoteName: 'usageMethodType',
      },
      { sort: 'code,desc' }
    );

    return content;
  };

  getUsageMethodTypeOptions = async (): Promise<ISelectOption[]> => {
    const res = await this.getUsageMethodTypeDictionary();

    const list = createDictionaryEntitySelectOptionList(res);

    return list;
  };
}

export default ExperimentStepsService;
