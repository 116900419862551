import { provide } from '../../../../../../../../common/utils/helpers/mobx';
import { IExperimentsFilters } from '../../../models';
import { EExperimentsFiltersBuilderId } from '../../../../../constants/configs';
import { TExperimentsFiltersFilterConfig as TFilterConfig } from '../../../types';
import {
  ITableFiltersBuilderConfig,
  ITableFiltersBuilderHeaderConfig as IHeaderConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/models/configs';
import {
  TFiltersFilterPaginationQueryItem as TPageQueryItem,
  TFiltersFilterPaginationQuery as TPageQueryConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';

type TOmittedAuditFilterQuery = TPageQueryConfig<
  IExperimentsFilters,
  'seasonYearIn' | 'statusIn' | 'typeIn'
>;
@provide.transient()
class ExperimentsFiltersConfigsService {
  createConfig = (
    onActionButtonClick: () => void,
    paginationQueryConfig: TOmittedAuditFilterQuery
  ): ITableFiltersBuilderConfig<IExperimentsFilters> => {
    const headerConfig = this.createHeaderConfig(onActionButtonClick);
    const filterList = this.createFilterList(paginationQueryConfig);

    return {
      id: EExperimentsFiltersBuilderId.Experiments,
      headerConfig,
      filterConfigList: filterList,
    };
  };

  protected createHeaderConfig = (onActionButtonClick: () => void): IHeaderConfig => {
    return {
      title: 'Опыты',
      iconsConfig: {
        isShowToggleFilters: true,
      },
      actionButtonConfig: {
        autoRenderConfig: {
          title: 'Создать опыт',
          onClick: onActionButtonClick,
        },
      },
    };
  };

  protected createFilterList = (
    paginationQueryConfig: TOmittedAuditFilterQuery
  ): TFilterConfig[] => {
    return [
      this.createSeasonFilter(),
      this.createOrganizationFilter({ paginationConfig: paginationQueryConfig.organizationIdIn }),
      this.createTypeFilter(),
      this.createCultureFilter({ paginationConfig: paginationQueryConfig.cultureIdIn }),
      this.createDistrictFilter({ paginationConfig: paginationQueryConfig.districtIdIn }),
      this.createRegionFilter({ paginationConfig: paginationQueryConfig.regionIdIn }),
      this.createAssigneeFilter({ paginationConfig: paginationQueryConfig.assigneeIdIn }),
      this.createStatusFilter(),
    ];
  };

  protected createSeasonFilter = (): TFilterConfig => {
    return {
      id: 'seasonYearIn',
      name: 'Год опыта',
      type: 'select',
    };
  };

  protected createOrganizationFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'organizationIdIn',
      name: 'Организация',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createTypeFilter = (): TFilterConfig => {
    return { id: 'typeIn', name: 'Тип опыта', type: 'select' };
  };

  protected createCultureFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'cultureIdIn',
      name: 'Культура',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createRegionFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'regionIdIn',
      name: 'Регион',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createStatusFilter = (): TFilterConfig => {
    return { id: 'statusIn', name: 'Статус опыта', type: 'select' };
  };

  protected createDistrictFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'districtIdIn',
      name: 'Федеральный округ',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  protected createAssigneeFilter = ({
    paginationConfig,
  }: {
    paginationConfig: TPageQueryItem;
  }): TFilterConfig => {
    return {
      id: 'assigneeIdIn',
      name: 'Ответственный УХ',
      type: 'select',
      selectOptions: {
        pagination: this.getOptionPaginationConfig(paginationConfig),
      },
    };
  };

  private getOptionPaginationConfig = (paginationConfig: TPageQueryItem) => ({
    updateDataHandler: data => paginationConfig?.updateHandler?.(data),
    requestQuery: (page, searchQuery) => paginationConfig.requestHandler(page, searchQuery),
  });
}

export default ExperimentsFiltersConfigsService;
