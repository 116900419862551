import {
  ITableBuilderCellConfig,
  ITableBuilderColumnConfig,
  ITableBuilderConfig,
} from '../../../../../../../../../../../../common/features/TableBuilder/models/configs';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';
import {
  ZoneListPredecessorCell,
  ZoneListRadioButtonCell,
  ZoneListSwitcherCell,
} from '../../../components/ZonesList/components';
import { ZoneListActionsCell } from '../../../components/ZonesList/components/ZoneListActionsCell/ZoneListActionsCell';
import ZoneListCellWithDisabled from '../../../components/ZonesList/components/ZoneListCellWithDisabled/ZoneListCellWithDisabled';

type TBuilderConfig = ITableBuilderConfig;
type TColumnConfig = ITableBuilderColumnConfig;
type TCellConfig = ITableBuilderCellConfig;

@provide.transient()
class ZonesListConfigService {
  createConfig = (): TBuilderConfig => {
    const columnList = this.createColumnList();
    const cellList = this.createCellList();

    return {
      id: EExperimentsTableBuilderId.Zones,
      columnConfigList: columnList,
      cellConfigList: cellList,
    };
  };

  createColumnList = (): TColumnConfig[] => {
    const switcher = this.createSwitcherColumn();
    const name = this.createNameColumn();
    const predecessor = this.createPredecessorColumn();
    const area = this.createAreaColumn();
    const type = this.createTypeColumn();
    const actions = this.createActionsColumn();

    return [switcher, name, predecessor, area, type, actions];
  };

  createSwitcherColumn = (): TColumnConfig => {
    return {
      id: 'radioButton',
      name: '',
      width: {
        default: '56px',
      },
    };
  };

  createNameColumn = (): TColumnConfig => {
    return {
      id: 'name',
      name: '',
      width: {
        default: '1fr',
      },
    };
  };

  createPredecessorColumn = (): TColumnConfig => {
    return {
      id: 'predecessor',
      name: '',
      width: {
        default: '1fr',
      },
    };
  };

  createAreaColumn = (): TColumnConfig => {
    return {
      id: 'area',
      name: '',
      width: {
        default: '80px',
      },
    };
  };

  createTypeColumn = (): TColumnConfig => {
    return {
      id: 'type',
      name: '',
      width: {
        default: '172px',
      },
    };
  };

  createActionsColumn = (): TColumnConfig => {
    return {
      id: 'actions',
      name: '',
      width: {
        default: '24px',
      },
    };
  };

  createCellList = (): TCellConfig[] => {
    const switcher = this.createSwitcherCell();
    const name = this.createNameCell();
    const predecessor = this.createPredecessorCell();
    const area = this.createAreaCell();
    const type = this.createTypeCell();
    const actions = this.createActionsCell();

    return [switcher, name, predecessor, area, type, actions];
  };

  createSwitcherCell = (): TCellConfig => {
    return {
      id: 'id',
      customRenderConfig: {
        renderCell: row => <ZoneListSwitcherCell rowData={row} />,
      },
    };
  };

  createNameCell = (): TCellConfig => {
    return {
      id: 'name',
      customRenderConfig: {
        renderCell: row => <ZoneListCellWithDisabled rowData={row} text={row.name} />,
      },
    };
  };

  createPredecessorCell = (): TCellConfig => {
    return {
      id: 'predecessor',
      customRenderConfig: {
        renderCell: row => <ZoneListPredecessorCell rowData={row} />,
      },
    };
  };

  createAreaCell = (): TCellConfig => {
    return {
      id: 'area',
      customRenderConfig: {
        renderCell: row => <ZoneListCellWithDisabled rowData={row} text={row.area} />,
      },
    };
  };

  createTypeCell = (): TCellConfig => {
    return {
      id: 'type',
      customRenderConfig: {
        renderCell: row => <ZoneListRadioButtonCell rowData={row} />,
      },
    };
  };

  createActionsCell = (): TCellConfig => {
    return {
      id: 'actions',
      customRenderConfig: {
        renderCell: row => <ZoneListActionsCell rowData={row} />,
      },
    };
  };
}

export default ZonesListConfigService;
