import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { priceType } from '../../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/constants';
import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './ConsumptionCell.style';

interface IConsumptionCellProps {
  consumption: number;
  dataTestId?: string;
}

const ConsumptionCell: FC<IConsumptionCellProps> = ({ consumption, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  const valueToDisplay = `${formatNumValue(consumption)} ${priceType.kgOnGa}`;

  return (
    <AutoTooltip
      content={`${consumption} ${priceType.kgOnGa}`}
      position="top"
      disabled={!showTooltip}
    >
      <Styled.Consumption data-test-id={dataTestId} ref={ref}>
        {valueToDisplay}
      </Styled.Consumption>
    </AutoTooltip>
  );
};

ConsumptionCell.displayName = 'ConsumptionCell';

export default memo(ConsumptionCell);
