import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Table } from '../../../../../../../common/components/table';
import { InfinityScroll, Plug } from '../../../../../../../common/components/ui';
import { getObservationColumns } from '../../utils/helpers';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { ObservationStore } from '../../mobx/stores';
import { generateAuditsPath } from '../../../../utils/helpers';
import { AccessStore } from '../../../../../../../common/mobx/stores/AccessStore';
import { EAuditsFiltersBuilderId } from '../../../../constants/configs';
import { TableFiltersBuilderController } from '../../../../../../../common/features/TableFiltersBuilder/mobx/controllers';

import Styled from './ObservationsContentBody.styles';
import { useGetPlugInfo } from './hooks';

const ObservationsContentBody: FC = observer(() => {
  const observationStore = useStore(ObservationStore);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { auditList, totalPageNumber, currentPageNumber, clearStore, isLoading } = observationStore;

  const { isAllowToWatchAudits } = useStore(AccessStore);

  const tableFiltersBuilderController = useStore(TableFiltersBuilderController);

  const navigate = useNavigate();

  const appliedFilters = tableFiltersBuilderController.getAppliedFilters(
    EAuditsFiltersBuilderId.Audits
  );

  const { plugDescription, plugIcon, plugTitle, buttonTitle, onButtonClick } = useGetPlugInfo(
    isAllowToWatchAudits,
    isAllowToWatchAudits
  );

  const noDataFindWithFilters = isEmpty(appliedFilters) && auditList?.length === 0;

  const showPlugButton = !noDataFindWithFilters;

  const columns = useMemo(() => getObservationColumns(), []);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, [isAllowToWatchAudits]);

  const onRowClick = rowDataInfo => {
    if (rowDataInfo?.id) {
      navigate(
        generateAuditsPath('auditInfo', {
          auditId: rowDataInfo?.id,
        })
      );
    }
  };

  const isNeedToShowPlug = !auditList?.length || !isAllowToWatchAudits;

  return (
    <Styled.Wrapper>
      <Styled.Content ref={scrollRef} $isNeedToShowPlug={isNeedToShowPlug}>
        <InfinityScroll
          page={currentPageNumber}
          totalPages={totalPageNumber}
          onScroll={async () => {
            await console.log('');
          }}
          onPageChange={() => {
            console.log('');
          }}
          scrollRef={scrollRef}
          data-test-id="experiment-content-body-infinity-scroll"
        >
          <Table
            tableBodyData={auditList}
            tableHeadData={columns}
            data-test-id="observation-content-body-table"
            onRowClick={onRowClick}
            isAllowToWatchTableData={isAllowToWatchAudits}
            isLoading={isLoading}
            plugComponent={() => (
              <Plug
                icon={plugIcon}
                title={plugTitle}
                description={plugDescription}
                primaryButtonTitle={buttonTitle}
                onPrimaryButtonClick={onButtonClick}
                buttonExist={showPlugButton}
              />
            )}
          />
        </InfinityScroll>
      </Styled.Content>
    </Styled.Wrapper>
  );
});

export default memo(ObservationsContentBody);
