import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo, PropsWithChildren } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './AttributeName.styles';

interface IProps {
  isTitle?: boolean;
  showFullText?: boolean;
  cellPadding: string;
}

const AttributeName: FC<PropsWithChildren<IProps>> = ({
  children,
  isTitle,
  showFullText,
  cellPadding,
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip position="top" content={children} disabled={!showTooltip}>
      <Styled.Wrapper
        ref={ref}
        $isTitle={isTitle}
        $showFullText={showFullText}
        $cellPadding={cellPadding}
      >
        {children}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

AttributeName.displayName = 'AttributeName';

export default memo(AttributeName);
