import { TApiRoute } from '../../../models/system/ApiRoute.model';
import { TResponseList } from '../../../models/common/response';
import { IExaminationAttributeMethod } from '../../../models/aho/examination-attribute-method/ExaminationAttributeMethod/ExaminationAttributeMethod.model';

type TAddExaminationAttributeValuesReq = {
  experimentZoneId: string;
  sampleDate: string;
  values: {
    intValue?: number;
    doubleValue?: number;
    dictionaryValue?: string;
    examinationAttributeMethodId: string;
  }[];
};

type TAddExaminationAttributeValuesRes = {} & TResponseList<IExaminationAttributeMethod>;

const addExaminationAttributeValues: TApiRoute & {
  request: TAddExaminationAttributeValuesReq;

  response: TAddExaminationAttributeValuesRes;
} = {
  url: ({ experimentZoneId }) =>
    `/api/as-fields/aho/examination-attribute-values/byCultureZoneExperiment/${experimentZoneId}`,
  method: 'PUT',
  request: {} as TAddExaminationAttributeValuesReq,
  response: {} as TAddExaminationAttributeValuesRes,
  headers: {},
};

export type { TAddExaminationAttributeValuesReq, TAddExaminationAttributeValuesRes };

export { addExaminationAttributeValues };
