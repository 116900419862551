import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';
import { ReactComponent as MenuIconSvg } from '../../../../../../assets/icons/menu-icon.svg';

const { AccentGreen } = ECommonColor;

const MenuButton = styled(MenuIconSvg)`
  cursor: pointer;
  &:hover {
    rect {
      fill: ${AccentGreen} !important;
    }
  }
`;

const StyledDocumentOptionsCell = {
  MenuButton,
};

export default StyledDocumentOptionsCell;
