import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo, ReactNode } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './HeadTitle.styles';

interface IDynamicTableHeadTitleProps {
  headColumnPadding: string;
  children: ReactNode;
}

const HeadTitle: FC<IDynamicTableHeadTitleProps> = ({ children, headColumnPadding }) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip position="top" content={children} disabled={!showTooltip}>
      <Styled.Wrapper ref={ref} $headColumnPadding={headColumnPadding}>
        {children}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

HeadTitle.displayName = 'HeadTitle';

export default memo(HeadTitle);
