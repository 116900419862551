import { ETaskStatus } from '../../../../../../../../../../../../../../common/utils/constants/task';

const getStatusCellComponentColors = status => {
  let bgColor = '#FAF9FD';
  let color = '#151F32';
  let statusName = status;

  switch (status) {
    case ETaskStatus.New:
      bgColor = '#E7F1FD';
      color = '#0770E3';
      statusName = 'Новая';
      break;
    case ETaskStatus.InWork:
      bgColor = '#ECF8EF';
      color = '#3FB65F';
      statusName = 'В работе';
      break;
    case ETaskStatus.Canceled:
      color = '#151F32';
      bgColor = '#FEF5F5';
      statusName = 'Отменена';
      break;
    case ETaskStatus.Completed:
      statusName = 'Завершена';
      break;
    default:
      break;
  }

  return { bgColor, color, statusName };
};

export default getStatusCellComponentColors;
