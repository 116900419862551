import { FC, memo, useCallback } from 'react';
import { saveAs } from 'file-saver';
import axios from 'axios';

import { IFile } from '../../../../../api/models/common';
import Overlay, { IOverlayProps } from '../Overlay/Overlay';
import { useDataTestId } from '../../../utils/hooks/locators';
import {
  CloseButton,
  DownloadButton,
  LeftArrowButton,
  PinButton,
  RightArrowButton,
} from '../buttons';

import Styled from './ImagesCarousel.styles';
import { useImagesCarousel } from './utils/hooks';
import { IUseImagesCarouselConfig } from './utils/hooks/useImagesCarousel/useImagesCarousel';

const DEFAULT_DATA_TEST_ID = 'images-carousel';

interface IImagesCarouselProps {
  imageList: IFile[];
  selectedImage: IFile;
  onClose: IOverlayProps['onClose'];
  pinnedImage?: IFile;
  isShowPinButton?: boolean;
  onPinImage?: IUseImagesCarouselConfig['onPinImage'];
  dataTestId?: string;
}

const ImagesCarousel: FC<IImagesCarouselProps> = ({
  imageList,
  selectedImage,
  onClose,
  pinnedImage,
  isShowPinButton,
  onPinImage,
  dataTestId,
}) => {
  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  const {
    _selectedImage,
    _pinnedImage,
    visibilityOfArrowButtons,
    handlePinButtonClick,
    handleLeftArrowClick,
    handleRightArrowClick,
  } = useImagesCarousel({ imageList, selectedImage, pinnedImage, onPinImage });

  const downloadImage = useCallback(async () => {
    try {
      const { data } = await axios.get(_selectedImage.downloadUrl, { responseType: 'blob' });

      const blob = new Blob([data], {
        type: _selectedImage.fileContentType,
      });

      saveAs(blob, _selectedImage.fileName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [_selectedImage]);

  return (
    <Overlay onClose={onClose}>
      <Styled.Wrapper {...getDataTestId('wrapper')}>
        <Styled.ActionsWrapper {...getDataTestId('actions-wrapper')}>
          {isShowPinButton ? (
            <PinButton
              size={'s32'}
              isEnableEditing
              isSelected={_pinnedImage?.id === _selectedImage?.id}
              onClick={handlePinButtonClick}
              dataTestId={getDataTestId('pin-button')['data-test-id']}
            />
          ) : null}

          <DownloadButton
            size={'s32'}
            onClick={downloadImage}
            dataTestId={getDataTestId('download-button')['data-test-id']}
          />

          <CloseButton
            size={'s32'}
            onClick={onClose}
            dataTestId={getDataTestId('close-button')['data-test-id']}
          />
        </Styled.ActionsWrapper>

        <Styled.LeftArrowButtonWrapper
          {...getDataTestId('left-arrow-button-wrapper')}
          $isShow={visibilityOfArrowButtons.isLeftArrowButton}
        >
          <LeftArrowButton
            size={'s40'}
            onClick={handleLeftArrowClick}
            dataTestId={getDataTestId('left-arrow-button')['data-test-id']}
          />
        </Styled.LeftArrowButtonWrapper>

        <Styled.RightArrowButtonWrapper
          {...getDataTestId('right-arrow-button-wrapper')}
          $isShow={visibilityOfArrowButtons.isRightArrowButton}
        >
          <RightArrowButton
            size={'s40'}
            onClick={handleRightArrowClick}
            dataTestId={getDataTestId('right-arrow-button')['data-test-id']}
          />
        </Styled.RightArrowButtonWrapper>

        <Styled.Image {...getDataTestId('image')} $url={_selectedImage?.downloadUrl} />
      </Styled.Wrapper>
    </Overlay>
  );
};

ImagesCarousel.displayName = 'ImagesCarousel';

export default memo(ImagesCarousel);
