import { Button } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { Container } from '../../../../../../../../../common/features/UI';

const StyledContainer = styled(Container)`
  width: 912px;
  margin: 24px auto 0;
`;

const ButtonWrapper = styled(Button)`
  margin: 40px 0 100px;
  width: 300px;
  display: flex;
  justify-content: center;
`;

const StyledZones = {
  ButtonWrapper,
  Container: StyledContainer,
};

export default StyledZones;
