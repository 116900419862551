import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { priceType } from '../../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/constants';
import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './PriceCell.style';

interface IPriceCellProps {
  price: number;
  dataTestId?: string;
}

const PriceCell: FC<IPriceCellProps> = ({ price, dataTestId }) => {
  const { ref, showTooltip } = useShowTooltip();

  const valueToDisplay = `${formatNumValue(price)} ${priceType.rublesOnKg}`;

  return (
    <AutoTooltip
      content={`${price} ${priceType.rublesOnGa}`}
      position="top"
      disabled={!showTooltip}
    >
      <Styled.Price data-test-id={dataTestId} ref={ref}>
        {valueToDisplay}
      </Styled.Price>
    </AutoTooltip>
  );
};

PriceCell.displayName = 'PriceCell';

export default memo(PriceCell);
