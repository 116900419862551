import { useMemo } from 'react';

import {
  EExperimentCultureZoneType,
  IFWExperimentCultureZone,
} from '../../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { formatDoubleNum } from '../../../../../../../../../../../../../../common/utils/helpers/numbers';
import { ITableBuilderRowsGroupConfig } from '../../../../../../../../../../../../../../common/features/TableBuilder/models/configs';

interface ICultureZoneCell {
  id: number;
  name: string;
  type: EExperimentCultureZoneType;
  area: string;
  culture: string;
  predecessor: string;
  fwExpCultureZone: IFWExperimentCultureZone;
  isDisabledControlTypeSelection: boolean;
}

const useFormatData = (
  fwExpCultureZoneList: IFWExperimentCultureZone[]
): ITableBuilderRowsGroupConfig[] => {
  return useMemo<ITableBuilderRowsGroupConfig[]>(() => {
    const hasControlType = fwExpCultureZoneList.some(
      zone => zone.type === EExperimentCultureZoneType.Control
    );

    return fwExpCultureZoneList.reduce<ITableBuilderRowsGroupConfig[]>((zoneCellList, fwZone) => {
      const isControlType = fwZone.type === EExperimentCultureZoneType.Control;

      const zoneCellIndex = zoneCellList.findIndex(
        zoneCell => zoneCell.id === fwZone.cultureZone.fieldId
      );

      if (fwZone?.type) {
        const zoneCell = {
          id: String(fwZone?.polyId),
          name: fwZone.name,
          type: fwZone.type,
          area: `${formatDoubleNum(fwZone.cultureZone.area)} га`,
          culture: fwZone.cultureZone.culture.name,
          predecessor: '',
          fwExpCultureZone: fwZone,
          isDisabledControlTypeSelection: hasControlType ? !isControlType : false,
          autoRenderConfig: {
            preset: 'big-margins' as 'big-margins',
          },
        };

        if (zoneCellIndex !== -1) {
          zoneCellList[zoneCellIndex].rowConfigList = [
            ...zoneCellList[zoneCellIndex].rowConfigList,
            zoneCell,
          ];
        } else {
          zoneCellList.push({
            id: fwZone.cultureZone.fieldId,
            autoRenderConfig: {
              preset: 'cleared',
              name: fwZone.cultureZone.fieldName,
            },
            rowConfigList: [zoneCell],
          });
        }
      }

      return zoneCellList;
    }, []);
  }, [fwExpCultureZoneList]);
};

const ZonesListTableHooks = {
  useFormatData,
};

export default ZonesListTableHooks;

export type { ICultureZoneCell };
