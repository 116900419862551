import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

const { GeneralDark, GeneralWhite, BorderGray } = ECommonColor;

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.div<{ $url: string; $iconStyle?: FlattenSimpleInterpolation }>`
  width: 14px;
  height: 14px;

  ${({ $url }) =>
    $url &&
    css`
      background: no-repeat center url(${$url});
    `}

  ${({ $iconStyle }) => $iconStyle}
`;

const Menu = styled.ul<{ $menuStyle?: FlattenSimpleInterpolation }>`
  position: absolute;
  top: 0;
  right: 0;
  list-style-type: none;
  width: 150px;
  border-radius: 16px;
  background-color: ${GeneralWhite};
  padding: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 1;
  ${({ $menuStyle }) => $menuStyle}
`;

const MenuItem = styled.li<{ $itemStyle?: FlattenSimpleInterpolation }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  position: relative;
  padding: 19px 17px;
  &:not(:last-child) {
    &:after {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      background-color: ${BorderGray};
    }
  }
  ${({ $itemStyle }) => $itemStyle}
`;

const MenuItemLabel = styled.span<{ $labelStyle?: FlattenSimpleInterpolation }>`
  color: ${GeneralDark};
  letter-spacing: -0.1px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 13px;
  ${({ $labelStyle }) => $labelStyle}
`;

const StyledMenuDropdown = {
  Wrapper,
  Menu,
  Icon,
  MenuItem,
  MenuItemLabel,
};

export default StyledMenuDropdown;
