import moment from 'moment';

import {
  ITableFiltersBuilderConfig as IBuilderConfig,
  ITableFiltersBuilderPresetConfig as IPresetConfig,
  ITableFiltersBuilderFilterConfig as IFilterConfig,
} from '../../../models/configs';
import {
  ITableFiltersBuilderValue as IValue,
  ITableFiltersBuilderTag as ITag,
} from '../../../models/data';
import { TableFiltersBuilderStore as Store } from '../../stores';
import {
  TableFiltersBuilderFiltersService as FiltersService,
  TableFiltersBuilderGridService as GridService,
  TableFiltersBuilderPresetsService as PresetsService,
  TableFiltersBuilderSessionStorageService as SessionStorageService,
  TableFiltersBuilderValuesService as ValuesService,
} from '../../services';
import { TTableFiltersBuilderFilterType as TFilterType } from '../../../types/filters';
import { lazyInject, provide } from '../../../../../utils/helpers/mobx';
import { ISelectOption } from '../../../../../components/form/Dropdown/Dropdown.types';

@provide.singleton()
class TableFiltersBuilderController<F = any> {
  @lazyInject(Store)
  protected store: Store<F>;

  @lazyInject(PresetsService)
  protected presetsService: PresetsService<F>;

  @lazyInject(FiltersService)
  protected filtersService: FiltersService<F>;

  @lazyInject(SessionStorageService)
  protected sessionStorageService: SessionStorageService<F>;

  @lazyInject(ValuesService)
  protected valuesService: ValuesService<F>;

  @lazyInject(GridService)
  protected gridService: GridService<F>;

  initiateConfig = (config: IBuilderConfig): void => {
    this.store.setHeaderConfig(config.id, config.headerConfig);

    if (config?.rowConfig) {
      this.store.setRowConfig(config.id, config.rowConfig);
    }

    if (config?.presetConfigList) {
      this.addPresetList(config.id, config.presetConfigList);
    }

    if (config?.filterConfigList) {
      this.addFilterList(config.id, config.filterConfigList);
    }

    this.addDefaultAppliedValueList(config.id, config.presetConfigList, config.filterConfigList);
  };

  togglePreset = (
    builderId: string,
    presetId: string,
    options?: {
      isClearValueList?: boolean;
    }
  ): void => {
    const previouslySelected = this.store.getSelectedPreset(builderId);

    if (previouslySelected) {
      if (previouslySelected.id === presetId) {
        this.removeSelectedPreset(builderId, options);

        this.valuesService.applyDefaultValues(builderId);
      } else {
        this.selectPreset(builderId, presetId);
      }
    } else {
      this.selectPreset(builderId, presetId);
    }
  };

  toggleFiltersVisibility = (builderId: string): void => {
    const prevValue = Boolean(this.store.getIsShowFilters(builderId));

    this.store.setIsShowFilters(builderId, !prevValue);
  };

  removeTag = (builderId: string, filterId: keyof F, tagId: string, type: TFilterType): void => {
    const filter = this.store.getFilter(builderId, filterId);
    const appliedValueList = this.store.getAppliedValueList(builderId);

    if (type === 'select') {
      if (filter?.selectOptions?.isDisplayAllTagsAsOne) {
        const appliedValue = this.store.getAppliedValue(builderId, filterId);

        this.store.deleteAppliedValueOptionList(
          builderId,
          filterId,
          appliedValue.selectOptionList.map(({ value }) => value as string)
        );
      } else {
        this.store.deleteAppliedValueOption(builderId, filterId, tagId);
      }
    }

    if (type === 'date-range') {
      this.store.deleteAppliedValueList(builderId, [
        filterId,
        filter.dateRangeOptions.relatedEndDateFilterId,
      ]);

      /**
       * В случае, если выбранное значение является дефолтным и есть примененные другие значения -
       * сетаем дефолтное значение в стор. В противном случае будет применен дефолтный пресет.
       */
      if (appliedValueList.length) {
        const defaultValueList = this.store.getDefaultValueList(builderId);
        const dateRangeValueList = defaultValueList.filter(value => value.type === 'date-range');

        this.store.setAppliedValueList(builderId, dateRangeValueList);
      }
    }

    if (type === 'boolean') {
      this.store.deleteAppliedValue(builderId, filterId);
    }

    this.removeSelectedPreset(builderId);

    // Если мы удалили все теги, т.е. фильтры очищены, применяем пресет по умолчанию.
    if (!appliedValueList.length) {
      const presetList = this.store.getPresetList(builderId);
      const defaultPreset = presetList.find(({ isDefault }) => isDefault);

      if (defaultPreset) {
        this.selectPreset(builderId, defaultPreset.id);
      }
    }

    this.setInSessionStorageAppliedValues(builderId);
  };

  removeAllValues = (builderId: string): void => {
    const presetList = this.store.getPresetList(builderId);

    const defaultPreset = presetList.find(({ isDefault }) => isDefault);

    if (defaultPreset) {
      this.selectPreset(builderId, defaultPreset.id);
    } else {
      this.removeSelectedPreset(builderId, { isClearValueList: true });

      this.valuesService.applyDefaultValues(builderId);
      this.store.setIsShowFilters(builderId, false);
    }

    this.setInSessionStorageAppliedValues(builderId);
  };

  applySelectedValues = (builderId: string): void => {
    const selectedValueList = this.store.getSelectedValueList(builderId);

    this.store.setAppliedValueList(builderId, selectedValueList);
    this.store.setIsShowFilters(builderId, false);

    this.setInSessionStorageAppliedValues(builderId);
  };

  selectSelectOptionList = (
    builderId: string,
    filterId: keyof F,
    selectOptionList: ISelectOption[]
  ) => {
    const value: IValue = {
      filterId,
      type: 'select',
      selectOptionList,
    };

    this.store.setSelectedValue(builderId, value);

    this.removeSelectedPreset(builderId);
  };

  selectDataRange = (builderId: string, filterId: keyof F, dateRange: [Date, Date]): void => {
    const [startDate, endDate] = dateRange;

    const dateFilter = this.store.getFilter(builderId, filterId);

    if (!dateFilter?.dateRangeOptions) {
      return;
    }

    const startDateValue: IValue<F> = {
      filterId,
      type: 'date-range',
      dateValue: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
    };

    const endDateValue: IValue<F> = {
      filterId: dateFilter.dateRangeOptions.relatedEndDateFilterId,
      type: 'date-range',
      dateValue: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
      isHidden: true,
    };

    this.store.setSelectedValueList(builderId, [startDateValue, endDateValue]);

    this.removeSelectedPreset(builderId);
  };

  selectBooleanFilter = (builderId: string, filterId: keyof F, booleanValue: boolean): void => {
    const value: IValue = {
      filterId,
      type: 'boolean',
      booleanValue,
    };

    this.store.setSelectedValue(builderId, value);

    this.removeSelectedPreset(builderId);
  };

  addSelectOptionList = (
    builderId: string,
    fieldId: keyof F,
    optionList: ISelectOption[]
  ): void => {
    this.store.setFilterOptionList(builderId, fieldId, optionList);
  };

  getAppliedFilters = (builderId: string): F => {
    return this.valuesService.getAppliedFilters(builderId);
  };

  getTagList = (builderId: string): ITag<F>[] => {
    return this.valuesService.createTagList(builderId);
  };

  /**
   * Очищает session storage, от данных фильтров, согласно идентификатору билдера.
   */
  clearAppliedValuesInSessionStorage = (builderId: string): void => {
    this.sessionStorageService.deleteAppliedValueList(builderId);
    this.sessionStorageService.deleteSelectedPresetId(builderId);
  };

  /**
   * Пересчитывает верстку фильтров.
   * @param builderId — идентификатор фильтров.
   */
  resizeFilters = (builderId: string): void => {
    this.store.setIsNeedToResizeFilters(builderId, true);
  };

  /**
   * Удаляет флаг перерасчета вертки.
   * @param builderId — идентификатор фильтров.
   */
  removeResizeFlag = (builderId: string): void => {
    this.store.deleteIsNeedToResizeFilters(builderId);
  };

  /**
   * Говорит системе о том, что начата анимация ресайза.
   * @param builderId — идентификатор фильтров.
   */
  activateResizeAnimation = (builderId: string): void => {
    this.store.setIsResizeAnimationsActivated(builderId, true);
  };

  /**
   * Удаляет флаг анимации ресайза.
   * @param builderId — идентификатор фильтров.
   */
  removeResizeAnimationFlag = (builderId: string): void => {
    this.store.deleteIsResizeAnimationsActivated(builderId);
  };

  updateHeaderConfig: Store['updateHeaderConfig'] = (builderId, data) => {
    this.store.updateHeaderConfig(builderId, data);
  };

  clearStore = (builderId: string): void => {
    this.store.deleteIsNeedToResizeFilters(builderId);
    this.store.deleteIsResizeAnimationsActivated(builderId);
    this.store.deleteHeaderConfig(builderId);
    this.store.deleteRowConfig(builderId);
    this.store.deleteSelectedPreset(builderId);
    this.store.setIsShowFilters(builderId, false);
    this.store.deleteAppliedValueList(builderId, [], { isClearAll: true });
    this.store.deleteSelectedValueList(builderId, [], { isClearAll: true });
    this.store.deleteDefaultValueList(builderId, [], { isClearAll: true });
    this.store.deletePresets(builderId);
    this.store.deleteFilters(builderId);
    this.store.deleteSelectOptionList(builderId);
    this.store.deleteSearchQueryConfigs(builderId);
  };

  protected removeSelectedPreset = (
    builderId: string,
    options?: {
      isClearValueList?: boolean;
    }
  ): void => {
    this.store.deleteSelectedPreset(builderId, options);
    this.sessionStorageService.deleteSelectedPresetId(builderId);
  };

  protected selectPreset = (builderId: string, presetId: string): void => {
    this.store.setSelectedPreset(builderId, presetId);
    this.store.setIsShowFilters(builderId, false);

    this.sessionStorageService.setSelectedPresetId(builderId, presetId);

    this.setInSessionStorageAppliedValues(builderId);
  };

  protected setInSessionStorageAppliedValues = (builderId: string): void => {
    const appliedValueList = this.store.getAppliedValueList(builderId);
    this.sessionStorageService.setAppliedValueList(builderId, appliedValueList);
  };

  protected addPresetList = (builderId: string, configList: IPresetConfig<F>[]): void => {
    const presetList = this.presetsService.createPresetList(builderId, configList);

    this.store.setPresetList(builderId, presetList);
  };

  protected addFilterList = (builderId: string, configList: IFilterConfig<F>[]): void => {
    const filterList = this.filtersService.createFilterList(builderId, configList);

    this.store.setFilterList(builderId, filterList);
  };

  /**
   * Метод автоматического применения значений фильтров исходя из условий.
   */
  protected addDefaultAppliedValueList = (
    builderId: string,
    presetConfigList: IPresetConfig<F>[],
    filterConfigList: IFilterConfig<F>[]
  ): void => {
    this.valuesService.addToStoreDefaultValueList(builderId, filterConfigList);

    const storedSelectedPresetId = this.sessionStorageService.getSelectedPresetId(builderId);

    /**
     * Проверяем, есть ли примененный пресет в sessionStorage.
     * Нужна явная проверка и явный ключ, так как в противном случае мы обязаны делать
     * глубокую проверку сохраненных значений и сравнивать их с пресетом,
     * но и это окажется не совсем точным, так как в пресете есть даты.
     */
    if (storedSelectedPresetId) {
      this.store.setSelectedPreset(builderId, storedSelectedPresetId);

      return;
    }

    const storedAppliedValueList = this.sessionStorageService.getAppliedValueList(builderId);

    /**
     * Если нет примененного пресета, то проверяем наличие примененных фильтров в sessionStorage.
     */
    if (storedAppliedValueList.length) {
      this.store.setAppliedValueList(builderId, storedAppliedValueList, {
        isClearPreviousList: true,
      });

      return;
    }

    const defaultPreset = presetConfigList?.find?.(({ isDefault }) => isDefault);

    /**
     * Если мы не нашли в sessionStorage никаких данных,
     * то проверяем наличие дефолтного пресета в списке и, если он есть, применяем его.
     */
    if (defaultPreset) {
      this.selectPreset(builderId, defaultPreset.id);

      return;
    }

    /**
     * Если ни одно из условий выше не сработало, то применяем дефолтные значения фильтров.
     */
    if (filterConfigList.length) {
      this.valuesService.applyDefaultValues(builderId);
    }
  };

  getTechSizesByScreen: GridService<F>['getTechSizesByScreen'] = (builderId, contentKey) => {
    return this.gridService.getTechSizesByScreen(builderId, contentKey);
  };
}

export default TableFiltersBuilderController;
