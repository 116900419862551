import { FC, memo } from 'react';

import { IActiveSubstancesData } from '../../../model/IActiveSubstancesData.model';
import { ActionCell as Cell } from '../content';

import Styled from './ActionCell.styles';
type IRow = Pick<IActiveSubstancesData, 'id' | 'children' | 'name'>;

interface IActionCellComponent {
  row: IRow;
  onClickDeleteTechoperation: (row: IRow) => void;
  onClickEditTechoperation: (row: IRow) => void;
  onClickDeleteInventoryValue: (row: IRow, fertilizeId: string) => void;
  onClickEditInventoryValue: (fertilizeId: string) => void;
}

const ActionCellComponent: FC<IActionCellComponent> = ({
  row,
  onClickDeleteTechoperation,
  onClickEditTechoperation,
  onClickDeleteInventoryValue,
  onClickEditInventoryValue,
}) => {
  const deleteTechoperationHandler = () => {
    onClickDeleteTechoperation(row);
  };

  const editTechoperationHandler = () => {
    onClickEditTechoperation(row);
  };

  const deleteInventoryValueHandler = (fertilizerId: string) => {
    onClickDeleteInventoryValue(row, fertilizerId);
  };

  const editInventoryValueHandler = (fertilizerId: string) => {
    onClickEditInventoryValue(fertilizerId);
  };

  return (
    <Styled.Wrapper>
      <Cell deleteHandler={deleteTechoperationHandler} editHandler={editTechoperationHandler} />
      {row.children.map(item => (
        <Cell
          key={item.id}
          deleteHandler={() => deleteInventoryValueHandler(item.fertilizer.id)}
          editHandler={() => editInventoryValueHandler(item.id)}
        />
      ))}
    </Styled.Wrapper>
  );
};

ActionCellComponent.displayName = 'ActionCellComponent';

export default memo(ActionCellComponent);
