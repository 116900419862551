import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

const { White, BorderGray } = ECommonColor;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Body = styled.div<{ $contentHeight: string }>`
  margin-top: 16px;
  flex: 1;
  height: ${({ $contentHeight }) => ($contentHeight ? $contentHeight : 'auto')};
`;

const Header = styled.div<{ $isHideHeaderBorder?: boolean }>`
  ${({ $isHideHeaderBorder }) =>
    !$isHideHeaderBorder &&
    css`
      border-bottom: 1px solid ${BorderGray};
      padding-bottom: 10px;
    `}
`;

const Wrapper = styled.section<{ $wrapperPadding: string }>`
  background-color: ${White};
  border-radius: 16px;
  padding: ${({ $wrapperPadding }) => ($wrapperPadding ? $wrapperPadding : '24px 24px 32px')};
`;

const StyledContainer = {
  Title,
  TitleWrapper,
  Body,
  Header,
  Wrapper,
};

export default StyledContainer;
