import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { DictionaryService } from '../../../../../../../../common/mobx/services/da-dictionary';
import { ISelectOption } from '../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import {
  createDictionaryEntitySelectOptionList,
  createExperimentShortInfoSelectOptionList,
  createOrganizationSelectOptionList,
  createUserSelectOptionList,
} from '../../../../../../../../common/utils/helpers/selectOptions';
import {
  OrganizationsService,
  UsersService,
} from '../../../../../../../../common/mobx/services/da-profile';
import { ExperimentService } from '../../../../../../../../common/mobx/services/as-fields';
import EObservationStatus from '../../../../../../../../common/utils/constants/observation/statusList/observationStatusList.enum';
import {
  TFilterPaginationQuery,
  TFiltersFilterPaginationQuery as TPageQueryConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';
import { IAuditsFilters } from '../../../models';
import { EPageSize } from '../../../../../../../../common/utils/constants/sizes';

@provide.transient()
class AuditsFiltersOptionsService {
  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;

  @lazyInject(UsersService)
  protected usersService: UsersService;

  @lazyInject(ExperimentService)
  protected experimentsService: ExperimentService;

  get optionsQueryScheme(): TPageQueryConfig<IAuditsFilters, 'statusIn'> {
    return {
      techOperationTypeIdIn: {
        requestHandler: (page, searchQuery) =>
          this.techOperationOptionListSearchQueryHandler(page, searchQuery),
      },
      organizationIdIn: {
        requestHandler: (page, searchQuery) =>
          this.organizationOptionListSearchQueryHandler(page, searchQuery),
      },
      assigneeIdIn: {
        requestHandler: (page, searchQuery) =>
          this.assigneeOptionListSearchQueryHandler(page, searchQuery),
      },
      experimentIdIn: {
        requestHandler: (page, searchQuery) =>
          this.experimentOptionListSearchQueryHandler(page, searchQuery),
      },
    };
  }

  techOperationOptionListSearchQueryHandler = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        nameFilter: searchQuery,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
        size: EPageSize.TwentyPages,
        page: page ?? 0,
      }
    );

    if (response?.content) {
      return {
        optionList: createDictionaryEntitySelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages ?? 0,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };
  organizationOptionListSearchQueryHandler = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.organizationsService.getExperimentOrganizationList({
      size: EPageSize.TwentyPages,
      page: page ?? 0,
      nameOrInn: searchQuery,
    });

    if (response?.content) {
      return {
        optionList: createOrganizationSelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages ?? 0,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  assigneeOptionListSearchQueryHandler = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.usersService.getExperimentUserList({
      size: EPageSize.TwentyPages,
      page: page ?? 0,
      fullName: searchQuery,
    });

    if (response?.content) {
      return {
        optionList: createUserSelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages ?? 0,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  experimentOptionListSearchQueryHandler = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.experimentsService.getExperimentShortInfoList({
      size: EPageSize.TwentyPages,
      code: searchQuery,
      page: page ?? 0,
    });

    if (response?.content) {
      return {
        optionList: createExperimentShortInfoSelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages ?? 0,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  createStatusOptionList = (): ISelectOption[] => {
    return [
      { label: 'Черновик', value: EObservationStatus.Draft },
      { label: 'На согласовании', value: EObservationStatus.InApproval },
      { label: 'Согласован', value: EObservationStatus.Approved },
    ];
  };
}

export default AuditsFiltersOptionsService;
