import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown, TDropdownConfig } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../../common/utils/helpers/mobx';
import ExecutionStepDictionaryService from '../../../containers/ExecutionSteps/mobx/services/ExecutionStepDictionaryService/ExecutionStepDictionary.service';
import {
  createExecutionPhenophaseName,
  getExecutionTableAttributeIdByRowId as getAttributeIdByRowId,
} from '../../../helpers';
import { ExecutionController } from '../../../mobx/controllers';
import { ExecutionStore } from '../../../mobx/stores';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { useDataTestIdV2 } from '../../../../../../../../../../common/utils/hooks/locators';
import { IComparisonTableBuilderCell } from '../../../../../../../../../../common/features/ComparisonTableBuilder/models/data';
import { IExperimentFactTableValue } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { IExecutionStepsValueAdditionalData } from '../../../containers/ExecutionSteps/types';

import Styled from './ExecutionPhenophaseBbchCell.styles';

interface IProps {
  cell: IComparisonTableBuilderCell<IExperimentFactTableValue & IExecutionStepsValueAdditionalData>;
  dataTestId: string;
}

export const ExecutionPhenophaseBbchCell: FC<IProps> = observer(({ cell, dataTestId }) => {
  const { fetchPhenophaseBbchDictionaryOptionList } = useStore(ExecutionStepDictionaryService);

  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const [isLoading, setIsLoading] = useState(false);
  const [optionList, setOptionList] = useState([]);

  const attributeId = useMemo(() => {
    return `${getAttributeIdByRowId(cell.rowId)}Id`;
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const cultureId = executionStore.getExperimentZone(executionStore.selectedZoneId)?.cultureZone
      ?.culture.id;

    fetchPhenophaseBbchDictionaryOptionList(cultureId)
      .then(list => {
        setOptionList(list);
        executionStore.setBbchList(list);
      })
      .finally(() => setIsLoading(false));
  }, [executionStore.selectedZoneId]);

  const initialOption = useMemo<ISelectOption<IDictionaryEntity>>(() => {
    if (cell?.initialModel?.dictionaryValues?.length) {
      const [firstValue] = cell?.initialModel?.dictionaryValues;

      return {
        value: firstValue.id,
        label: createExecutionPhenophaseName(cell?.initialModel),
        initialModel: { code: firstValue.code } as IDictionaryEntity,
      };
    }
  }, [cell?.initialModel?.dictionaryValues]);

  const updatedOption = executionStore.getSelectedDictionaryOption(
    executionStore.selectedZoneId,
    cell.rowId
  );

  const defaultOptionValue = useMemo(() => {
    return updatedOption ?? initialOption;
  }, [initialOption, updatedOption]);

  const errorList = executionController.getStepsPhenophaseEndErrorList(cell.builderId, cell.rowId);

  useEffect(() => {
    if (defaultOptionValue) {
      executionController.addSelectedOption(cell.rowId, defaultOptionValue);
    } else {
      executionController.removeSelectedOption(cell.rowId);
    }
  }, [JSON.stringify(defaultOptionValue)]);

  useEffect(() => {
    if (errorList.length) {
      executionController.addInvalidRowId(cell.rowId);
    } else {
      executionController.removeInvalidRowId(cell.rowId);
    }
  }, [errorList?.[0]]);

  const handleDropdownChange = useCallback<TDropdownConfig['field']['onChange']>(
    (value, otherData): void => {
      executionController.changeStep(cell.initialModel.stepId, { [attributeId]: value });

      if (otherData?.option) {
        executionController.addSelectedOption(cell.rowId, otherData.option);
      } else {
        executionController.removeSelectedOption(cell.rowId);
        executionController.updateCell(cell.builderId, cell.rowId, cell.columnId, {
          initialModel: { ...cell.initialModel, dictionaryValues: [] },
        });
      }
    },
    [attributeId, cell.initialModel]
  );

  const getDataTestId = useDataTestIdV2(`${dataTestId}-dictionary-cell`);

  return (
    <Styled.Wrapper {...getDataTestId()} id={cell.rowId}>
      <NewDropdown
        config={{
          field: {
            onChange: handleDropdownChange,
            placeholder: 'Внесите данные',
            defaultValue: defaultOptionValue,
            defaultValueList: [defaultOptionValue],
            emptyOption: { label: 'Внесите данные', value: null },
            icons: {
              clear: {},
            },
            type: {
              search: {},
            },
          },
          body: {
            optionList,
          },
          visual: {
            isInline: true,
          },
          validation: {
            error: {
              errorList,
            },
          },
        }}
        isLoading={isLoading}
      />
    </Styled.Wrapper>
  );
});
