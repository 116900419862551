import { Button } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

import EditIcon from './utils/assets/Edit.svg';

const { White } = ECommonColor;

const Wrapper = styled.div`
  max-width: 912px;
  margin: 0 auto;
  border-radius: 16px;
  padding: 24px;
  background: ${({ theme }) => theme.Colors.generalLight ?? '#ffffff'};
`;

const Container = styled.div`
  background-color: ${White};
  border-radius: 16px;
  padding: 24px;
`;

const ButtonWrapper = styled(Button)`
  font-weight: 600;
  height: 56px;
  width: 300px;
  margin-top: 16px;
  margin-left: auto;
  justify-content: center;
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
`;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e9e6f0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
`;

const Attribute = styled.div<{ marginBottom?: string; width?: string; marginRight?: string }>`
  flex: 1 1 ${({ width }) => width || '40%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
  display: block;
  &:last-child {
    flex: none;
    width: 49.3%;
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${EditIcon});
  cursor: pointer;
`;

const Body = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TableBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TableContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const StyledAuditInfo = {
  Wrapper,
  ButtonWrapper,
  Container,
  Header,
  Title,
  Attribute,
  Body,
  TableBody,
  TableContent,
  Icon,
};

export default StyledAuditInfo;
