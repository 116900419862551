import { FC, memo } from 'react';

import { Icon } from '../../Icon';

import downloadIconSelectedS32 from './assets/icons/download-button-icon-selected-32x32.svg';

const DEFAULT_DATA_TEST_ID = 'download-button';

interface IProps {
  size: 's32';
  onClick?: () => void;
  dataTestId?: string;
}

const DownloadButton: FC<IProps> = ({ size, onClick, dataTestId }) => {
  return (
    <Icon
      states={{
        s40: null,
        s32: {
          default: downloadIconSelectedS32,
          defaultHover: downloadIconSelectedS32,
          selected: null,
          selectedHover: null,
        },
        s24: null,
      }}
      size={size}
      onClick={onClick}
      dataTestId={dataTestId || DEFAULT_DATA_TEST_ID}
    />
  );
};

DownloadButton.displayName = 'DownloadButton';

export default memo(DownloadButton);
