import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { Container } from '../../../../../../../common/features/UI';
import { Plug } from '../../../../../../../common/components/ui';
import { Table } from '../../../../../../../common/components/table';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
// @ts-ignore
import noDataIcon from '../../../../../../../common/components/table/assets/icons/noDataIcon.svg';
import { AuditStore } from '../../mobx/store';
import { useFileUpload } from '../../hooks/file/useFileUpload';
import AuditController from '../../mobx/controller/Audit.controller';
import { IFile } from '../../../../../../../../api/models/common';
import { useWarningBeforeDeleting } from '../../../../../../../common/utils/hooks';
import { EExperimentStatus } from '../../../../../../../../api/models/as-fields/experiments';

import Styled from './AuditDocuments.styles';
import AuditDocumentsStore from './mobx/store/AuditDocuments.store';
import { getAuditDocumentsConfig } from './config/AuditDocuments.config';
import { AuditDocumentsUploadFile } from './components';

const AuditDocuments: FC = () => {
  const { docList, setDocList } = useStore(AuditDocumentsStore);
  const { selectedAudit, setSelectedAudit } = useStore(AuditStore);
  const { updateExperimentStepFiles } = useStore(AuditController);
  const inputRef = useRef(null);
  const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });
  const { setNotification } = useNotificator();
  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const noDocs = docList?.length === 0;

  useEffect(() => {
    if (selectedAudit) {
      setDocList(selectedAudit.attachedFiles, { isClearPreviousList: true });
    }
  }, [selectedAudit]);

  const addDocToFileStep = useCallback(
    (newDocId: string) => {
      const updatedDocList = [...docList.flatMap(doc => doc.id), newDocId];

      updateExperimentStepFiles({
        experimentStepId: selectedAudit?.id,
        attachedFiles: updatedDocList,
      }).then(data => {
        if (typeof data === 'object') {
          setSelectedAudit(data);
        }
      });
    },
    [docList]
  );

  const uploadNewDocument = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFileChange(e)
        .then(doc => {
          addDocToFileStep(doc.id);
          setNotification({
            message: 'Документ успешно загружен',
            style: {
              placement: 'top-center',
              type: ENotificationType.Success,
              theme: ENotificatorTheme.Dark,
            },
          });
        })
        .catch((err: Error) => {
          setNotification({
            message: err.message,
            style: {
              placement: 'top-center',
              type: ENotificationType.Warning,
              theme: ENotificatorTheme.Dark,
            },
          });
        });
    },
    [selectedAudit?.id, addDocToFileStep]
  );

  const deleteDocument = useCallback(
    (docId: string) => {
      const updatedDocList = docList.filter(doc => doc.id !== docId);

      updateExperimentStepFiles({
        experimentStepId: selectedAudit?.id,
        attachedFiles: updatedDocList.flatMap(doc => doc.id),
      }).then(data => {
        if (typeof data === 'object') {
          setSelectedAudit(data);
        }
        setDocList(updatedDocList);
      });
    },
    [docList, selectedAudit?.id]
  );

  const getUserNameHandler = useCallback((file: IFile) => {
    return file?.fileUploadUser?.fullName;
  }, []);

  const isShowUploadButton = selectedAudit?.experiment?.status !== EExperimentStatus.FullCompleted;
  const isShowDeleteButton = isShowUploadButton;

  const columns = useMemo(() => {
    return getAuditDocumentsConfig({
      onDeleteHandler: (fileId, fileName) =>
        showWarningBeforeDeleting(fileName, () => deleteDocument(fileId)),
      getUserNameHandler,
      isShowDeleteButton,
    });
  }, [deleteDocument, getUserNameHandler, selectedAudit?.experiment?.status]);

  const handleUploadFile = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = null;
    inputRef.current?.click();
  };

  return (
    <Container
      headerChildren={
        !noDocs && (
          <Styled.HeaderWrapper>
            <Styled.Title>Документы</Styled.Title>
            {isShowUploadButton ? (
              <Styled.AddDocumentButtonWrapper>
                <AuditDocumentsUploadFile onClick={handleUploadFile} />
              </Styled.AddDocumentButtonWrapper>
            ) : null}
          </Styled.HeaderWrapper>
        )
      }
      isHideHeaderBorder
      title={!noDocs && 'Документы'}
    >
      <input ref={inputRef} type="file" style={{ display: 'none' }} onChange={uploadNewDocument} />
      <Styled.TableBody>
        <Styled.TableContent>
          <Table
            tableBodyData={docList}
            tableHeadData={columns}
            data-test-id="experiment-content-body-table"
            existHeaderBorder
            plugComponent={() => (
              <Plug
                icon={noDataIcon}
                title="У вас еще нет документов"
                description="Добавьте хотя бы один документ, чтобы начать работу"
                primaryButtonTitle="Загрузить"
                onPrimaryButtonClick={() => inputRef?.current?.click()}
                buttonExist={isShowUploadButton}
              />
            )}
          />
        </Styled.TableContent>
      </Styled.TableBody>
    </Container>
  );
};

export default observer(AuditDocuments);
