import { makeAutoObservable } from 'mobx';

import {
  TGetCultureZoneExperimentListReq,
  TGetExperimentStepListReq,
  TGetNutritionHistoryListReg,
} from '../../../../../../../../../../../../../api';
import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../../api/models/as-fields/experiments';
import { INutritionHistoryItem } from '../../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';
import {
  ExperimentStepsService,
  NutritionHistoryService,
} from '../../../../../../../../../../../../common/mobx/services/as-fields';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ICopyFieldForm } from '../../../config/forms/copyFieldForm';
import { CalculationService } from '../../services/Calculation/Calculation.service';
import { CalculationStore } from '../../store/Calculation/Calculation.store';
import { ICalculationZone } from '../../../components/InfoForCurrentSection/InfoForCurrentSection';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import NutritionHistoryItemService from '../../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { IExpStepWithOperation } from '../../../../../../../models';
import { TableBuilderController } from '../../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { EExperimentsTableBuilderId } from '../../../../../../../../../constants/configs';
import CalculationActiveSubstancesConfigsService from '../../services/Calculation/CalculationActiveSubstancesConfig.service';
import CalculationTechOperationConfigsService from '../../services/Calculation/CalculationTechOperationConfig.service';
import CalculationEconomyConfigsService from '../../services/Calculation/CalculationEconomyConfig.service';
import CalculationAgrochemicalConfigsService from '../../services/Calculation/CalculationAgrochemicalConfig.service';
import { tabContent } from '../../../constants';
import { EExperimentStepType } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.singleton()
export class CalculationController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(CalculationService)
  historyService: CalculationService;

  @lazyInject(CalculationStore)
  calculationStore: CalculationStore;

  @lazyInject(ExperimentStepsService)
  experimentStepService: ExperimentStepsService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(CalculationActiveSubstancesConfigsService)
  protected configsActiveSubstancesService: CalculationActiveSubstancesConfigsService;

  @lazyInject(CalculationTechOperationConfigsService)
  protected configsTechOperationService: CalculationTechOperationConfigsService;

  @lazyInject(CalculationEconomyConfigsService)
  protected configsEconomyService: CalculationEconomyConfigsService;

  @lazyInject(CalculationAgrochemicalConfigsService)
  protected configsAgrochemicalService: CalculationAgrochemicalConfigsService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  initiateTable = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    builderId,
    isViewOnly
  ): void => {
    if (builderId === EExperimentsTableBuilderId.CalculationActiveSubstances) {
      const config = this.configsActiveSubstancesService.createConfig(
        onClickAddTechoperation,
        onClickDeleteTechoperation,
        onClickEditTechoperation,
        onClickDeleteInventoryValue,
        onClickEditInventoryValue,
        isViewOnly
      );

      this.tableBuilderController.initiateTable(config);
    } else if (builderId === EExperimentsTableBuilderId.CalculationTechOperations) {
      const config = this.configsTechOperationService.createConfig(
        onClickAddTechoperation,
        onClickDeleteTechoperation,
        onClickEditTechoperation,
        onClickDeleteInventoryValue,
        onClickEditInventoryValue,
        isViewOnly
      );

      this.tableBuilderController.initiateTable(config);
    } else {
      const config = this.configsEconomyService.createConfig(
        onClickAddTechoperation,
        onClickDeleteTechoperation,
        onClickEditTechoperation,
        onClickDeleteInventoryValue,
        onClickEditInventoryValue,
        isViewOnly
      );

      this.tableBuilderController.initiateTable(config);
    }
  };

  initiateAgrochemicalTable = (
    onClickEditAho: () => void,
    onClickDeleteAho: () => void,
    isViewOnly: boolean
  ): void => {
    const config = this.configsAgrochemicalService.createConfig(
      onClickEditAho,
      onClickDeleteAho,
      isViewOnly
    );

    this.tableBuilderController.initiateTable(config);
  };

  fetchCultureZones = async (payload: TGetCultureZoneExperimentListReq, activeZoneId?: string) => {
    const cultureZones = await this.historyService.getCultureZoneExperimentList(payload);

    const sortedCultureZones = cultureZones.sort(prev =>
      prev.type === EExperimentCultureZoneType.Control ? -1 : 1
    );

    this.calculationStore.setCultureZones(sortedCultureZones);

    if (activeZoneId) {
      this.calculationStore.setActiveCultureZone(
        sortedCultureZones.find(({ id }) => id === activeZoneId)
      );
    } else {
      this.calculationStore.setActiveCultureZone(sortedCultureZones[0]);
    }
  };

  onChangeActiveCultureZone = (id: string) => {
    const currentCultureZone = this.calculationStore.getCultureZoneById(id);
    this.calculationStore.setActiveCultureZone(currentCultureZone);
  };

  onChangeActiveTab = (activeTab: 'techOperation' | 'activeSubstance') => {
    this.calculationStore.setActiveTab(activeTab);
    this.calculationStore.setActiveTabName(tabContent.find(tab => tab.id === activeTab).label);
  };

  onChangeTechOperation = (experimentStep: IExperimentStep) => {
    this.calculationStore.setCurrentTechOperation(experimentStep);
  };

  onChangeInventoryValue = (inventoryValue: INutritionHistoryItem) => {
    this.calculationStore.setCurrentInventoryValue(inventoryValue);
  };

  fetchExperimentSteps = async (
    payload: TGetExperimentStepListReq,
    cultureZone: IExperimentCultureZone,
    isControlZone?: boolean
  ) => {
    const experimentSteps = await this.experimentStepService.getExperimentStepList(payload);
    const stepListWithOperation: IExpStepWithOperation[] = [];

    for await (const experimentStep of experimentSteps) {
      const [operation] = await this.historyService.getOperations({
        technologyId: cultureZone.cultureZone?.technology?.id,
        seasonYear: cultureZone.cultureZone.seasonYear,
        organizationId: experimentStep.organization.id,
        experimentStepId: experimentStep.id,
      });

      stepListWithOperation.push({ ...experimentStep, operation });
    }

    /**
     * Если это контрольная зона, то её данные храним в отдельной коллекции, чтобы можно
     * было сопоставлять её данные, с данными опытных зон.
     */
    if (isControlZone) {
      this.calculationStore.setExperimentStepListOfControlZOne(stepListWithOperation);
    }

    this.calculationStore.setExperimentSteps(stepListWithOperation);
  };

  fetchNutritionHistories = async (
    payload: TGetNutritionHistoryListReg,
    isControlZone?: boolean
  ) => {
    const nutritionHistories = await this.nutritionHistoryService.getNutritionHistoryList(payload, {
      query: {
        isFact: false,
      },
    });
    const inventoryValues = nutritionHistories
      .filter(item => item.items.length)
      .flatMap(nutritionHistory => nutritionHistory.items);

    /**
     * Если это контрольная зона, то её данные храним в отдельной коллекции, чтобы можно
     * было сопоставлять её данные, с данными опытных зон.
     */
    if (isControlZone) {
      this.calculationStore.setNutritionHistoryListOfControlZone(nutritionHistories);
    }

    this.calculationStore.setNutritionHistory(nutritionHistories);
    this.calculationStore.setInventoryValues(inventoryValues);
  };

  deleteInventoryValue = async (
    stepId: string,
    fertilizerId: string,
    payload: TGetNutritionHistoryListReg
  ) => {
    await this.nutritionHistoryItemService.deleteNutritionItemHistory(stepId, fertilizerId);
    await this.fetchNutritionHistories({
      experimentId: payload.experimentId,
      experimentStepType: EExperimentStepType.Plan,
      cultureZoneExperimentId: payload.cultureZoneExperimentId,
    });
  };

  deleteExperimentStep = async (
    id: string,
    payload: TGetExperimentStepListReq,
    cultureZone: IExperimentCultureZone
  ) => {
    await this.experimentStepService.deleteExperimentStep({ experimentStepId: id });
    Promise.allSettled([
      this.fetchExperimentSteps(payload, cultureZone),
      this.fetchCultureZones({ experimentId: payload.experimentId }, cultureZone.id),
    ]);
  };

  copyNutritionHistory = async (
    forms: ICopyFieldForm,
    activeCultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    const isControlZOne = activeCultureZone.type === EExperimentCultureZoneType.Control;

    await this.nutritionHistoryService.copyNutritionHistory(
      forms.fieldId,
      activeCultureZone.id,
      EExperimentStepType.Plan
    );
    await this.fetchNutritionHistories({
      experimentId,
      experimentStepType: EExperimentStepType.Plan,
      cultureZoneExperimentId: activeCultureZone.id,
    });
    await this.fetchExperimentSteps(
      {
        experimentId,
        type: EExperimentStepType.Plan,
        cultureZoneExperimentId: activeCultureZone.id,
      },
      activeCultureZone,
      isControlZOne
    );
    await this.fetchCultureZones({ experimentId }, activeCultureZone.id);
  };

  copyAho = async (forms: ICopyFieldForm, activeCultureZone: IExperimentCultureZone) => {
    await this.nutritionHistoryService.copyAho(forms.fieldId, activeCultureZone.id);
    await this.fetchExaminationAttributeValues(activeCultureZone.id);
  };

  deleteAho = async (activeCultureZone: IExperimentCultureZone) => {
    await this.nutritionHistoryService.deleteAho(activeCultureZone.id);
    await this.fetchExaminationAttributeValues(activeCultureZone.id);
  };

  fetchExaminationAttributeValues = async (experimentZoneId: string) => {
    const examinationAttributeValues = await this.historyService.getExaminationAttributeValues({
      experimentZoneId,
    });
    const examination = await this.historyService.getExamination({
      cultureZoneExperimentId: experimentZoneId,
    });

    this.calculationStore.setExaminationAttributeValue(examinationAttributeValues);
    this.calculationStore.setCurrentExamination(examination);
  };

  updateCultureZoneExperiment = async (
    activeCultureZone: IExperimentCultureZone,
    currentZone: ICalculationZone
  ) => {
    await this.historyService.updateCultureZoneExperiment({
      id: activeCultureZone.id,
      plannedCropPrice: Number(currentZone.plannedCropPrice?.replace(',', '.') ?? 0),
      plannedYield: Number(currentZone.plannedYield?.replace(',', '.') ?? 0),
    });
  };
}
