import { FC, memo } from 'react';
import moment from 'moment';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { generatePath, useNavigate } from 'react-router';

import { IExperimentFactTableAudit } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../common/utils/hooks/locators';
import { EDashboardRoute } from '../../../../../../../../../../routes/DashboardRoute';
import { EAuditsRoute } from '../../../../../../../../../observations/routes';
import { EAuditRoute } from '../../../../../../../../../observations/modules/Audit/routes';
import { SliderAuditStatus } from '../SliderAuditStatus';
import { SliderTaskStatus } from '../SliderTaskStatus';

import Styled from './SliderItem.styles';

interface IProps {
  audit: IExperimentFactTableAudit;
  dataTestId: string;
}

export const SliderItem: FC<IProps> = memo(({ audit, dataTestId }) => {
  const { id, date, name, status, taskStatus } = audit;

  const { ref: titleRef, isShowTooltip: isShowTitleTooltip } = useShowTooltip<HTMLDivElement>();

  const navigate = useNavigate();

  const navigateToAudit = () => {
    navigate(
      generatePath(`/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${EAuditRoute.Info}`, {
        auditId: id,
      })
    );
  };

  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper onClick={navigateToAudit} {...getDataTestId('slider-item')}>
      <Styled.FlexContainer>
        <Styled.Date>{moment(date).format('DD.MM.YY')}</Styled.Date>
        <Styled.Icon />
      </Styled.FlexContainer>
      <Styled.FlexContainer>
        <AutoTooltip content={name} position={'bottom'} disabled={!isShowTitleTooltip}>
          <Styled.Title ref={titleRef}>{name}</Styled.Title>
        </AutoTooltip>
        <SliderAuditStatus status={status} />
      </Styled.FlexContainer>
      <Styled.FlexContainer>
        <Styled.Title>Задача</Styled.Title>
        <SliderTaskStatus status={taskStatus} />
      </Styled.FlexContainer>
    </Styled.Wrapper>
  );
});
