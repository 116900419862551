import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo, useMemo } from 'react';

import { formatNumValue } from '../../../../../../utils/helpers/numbers';
import { useShowTooltip } from '../../../../../../utils/hooks';

import Styled from './DosageValueCell.styles';

interface IDosageValueCell {
  dosageValue: number | null;
  dataTestId?: string;
}

const DosageValueCell: FC<IDosageValueCell> = ({ dosageValue, dataTestId = 'dosage-cell' }) => {
  const { ref, showTooltip } = useShowTooltip();

  const formattedValue = useMemo(() => {
    if (!dosageValue) {
      return '—';
    }

    return `${formatNumValue(dosageValue)} кг/га`;
  }, [dosageValue]);

  return formattedValue !== '—' ? (
    <AutoTooltip content={formattedValue} position="top" disabled={!showTooltip}>
      <Styled.Header ref={ref} data-test-id={`dosage-cell-header-${dataTestId}`}>
        {formattedValue}
      </Styled.Header>
    </AutoTooltip>
  ) : (
    <Styled.Item data-test-id={`dosage-cell-item-${dataTestId}`}>—</Styled.Item>
  );
};

DosageValueCell.displayName = 'DosageValueCell';

export default memo(DosageValueCell);
