import { useCallback } from 'react';
import L from 'leaflet';

import { useStore } from '../../../../utils/helpers/mobx';
import { MapController } from '../../mobx/controllers';
import '../../assets/styles/map.styles.css';
import { MapStore } from '../../mobx/stores';
import { IFWExperimentCultureZone } from '../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';

import { useMapHelpers } from './utils/helpers';

interface IRegisterMap {
  (mapKey: string): void;
}

interface IDrawCultureZoneList {
  (fwExpCultureZoneList: IFWExperimentCultureZone[]): IFWExperimentCultureZone[];
}

interface IChangeCultureZoneStyle {
  (fwExpCultureZone: IFWExperimentCultureZone): void;
}

interface IChangeCultureZoneName {
  (polyId: number, name: string);
}

interface ISelectPolygon {
  (polyId: number): void;
}

interface IClearStore {
  (): void;
}

interface IUseMap {
  (): {
    selectedPolygonId: number | null;
    mapInstance: L.Map | null;
    registerMap: IRegisterMap;
    drawCultureZoneList: IDrawCultureZoneList;
    changeCultureZoneStyle: IChangeCultureZoneStyle;
    changeCultureZoneName: IChangeCultureZoneName;
    selectPolygon: ISelectPolygon;
    clearStore: IClearStore;
  };
}

const useMap: IUseMap = () => {
  const { prevSelectedPolygonData, instance } = useStore(MapStore);

  const {
    initInstance,
    createPolygon,
    changePolygonStyle,
    changePolygonTooltip,
    setPolygonList,
    selectPolygon,
    clearStore,
  } = useStore(MapController);

  const handleRegisterMap = useCallback<IRegisterMap>(mapKey => {
    initInstance(mapKey);
  }, []);

  const handleDrawCultureZoneList = useCallback<IDrawCultureZoneList>(fwExpCultureZoneList => {
    const { createCultureZoneTooltip, createCultureZoneStyle } = useMapHelpers;

    const zoneListWithPolyId: IFWExperimentCultureZone[] = [];
    const polygonList: L.Polygon[] = [];

    fwExpCultureZoneList.forEach(zone => {
      if (!zone?.cultureZone?.geometry?.coordinates) {
        return;
      }

      const tooltip = createCultureZoneTooltip(zone.name);
      const style = createCultureZoneStyle(zone.styleType);

      const polygon = createPolygon(zone.cultureZone?.geometry?.coordinates, style, tooltip, {
        isAllowToSelectPolygon: Boolean(zone.type),
      }) as any;

      if (!polygon) {
        return;
      }

      polygonList.push(polygon);

      const fwZoneWithPolyId: IFWExperimentCultureZone = {
        ...zone,
        polyId: polygon._leaflet_id,
      };

      zoneListWithPolyId.push(fwZoneWithPolyId);
    });

    setPolygonList(polygonList);

    return zoneListWithPolyId;
  }, []);

  const handleChangeCultureZoneStyle = useCallback<IChangeCultureZoneStyle>(
    ({ polyId, styleType }) => {
      const { createCultureZoneStyle } = useMapHelpers;

      const style = createCultureZoneStyle(styleType);

      changePolygonStyle(polyId, style);
    },
    []
  );

  const handleChangeCultureZoneName = useCallback<IChangeCultureZoneName>((polyId, name) => {
    const { createCultureZoneTooltip } = useMapHelpers;

    const tooltip = createCultureZoneTooltip(name);

    changePolygonTooltip(polyId, tooltip);
  }, []);

  const handleSelectPolygon = useCallback<ISelectPolygon>(polyId => {
    selectPolygon(polyId);
  }, []);

  const handleClearStore = useCallback<IClearStore>(() => {
    clearStore();
  }, []);

  return {
    selectedPolygonId: prevSelectedPolygonData?.id,
    mapInstance: instance,
    registerMap: handleRegisterMap,
    drawCultureZoneList: handleDrawCultureZoneList,
    changeCultureZoneStyle: handleChangeCultureZoneStyle,
    changeCultureZoneName: handleChangeCultureZoneName,
    selectPolygon: handleSelectPolygon,
    clearStore: handleClearStore,
  };
};

export type { IDrawCultureZoneList, IChangeCultureZoneStyle, IChangeCultureZoneName };

export default useMap;
