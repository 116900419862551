import { FC, memo, useMemo } from 'react';

import { INutritionHistoryItem } from '../../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { SliderContainer } from '../../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/containers';
import { TActiveSubstances } from '../../../../model/IActiveSubstances.model';
import { ActiveSubstancesCell } from '../ActiveSunstanceCell';

import Styled from './ActiveSubstancesRow.styles';

interface IActiveSubstancesRow {
  activeSubstancesNames: TActiveSubstances;
  nutritionHistoryItem?: INutritionHistoryItem;
  isTitleRow?: boolean;
  paddingBottom?: number;
}

const ActiveSubstancesRow: FC<IActiveSubstancesRow> = ({
  activeSubstancesNames,
  nutritionHistoryItem,
  isTitleRow,
  paddingBottom,
}) => {
  const activeSubstancesNamesValues = useMemo(() => {
    return [...activeSubstancesNames.entries()];
  }, [activeSubstancesNames]);

  return (
    <Styled.Wrapper
      data-test-id={`dosage-cell-wrapper-${nutritionHistoryItem?.id}`}
      $paddingBottom={paddingBottom}
    >
      <SliderContainer>
        {isTitleRow
          ? activeSubstancesNamesValues.map(item => {
              const [activeSubstanceKey, activeSubstanceValues] = item;

              return (
                <ActiveSubstancesCell
                  key={activeSubstanceKey}
                  activeSubstanceValue={activeSubstanceValues.totalSubstanceValue ?? '—'}
                  dosage={1}
                  dataTestId={activeSubstanceKey}
                  isTitleRow={isTitleRow}
                />
              );
            })
          : activeSubstancesNamesValues.map(item => {
              const [activeSubstanceKey, activeSubstanceValues] = item;

              return (
                <ActiveSubstancesCell
                  key={activeSubstanceKey}
                  activeSubstanceValue={
                    nutritionHistoryItem.fertilizer.attrs[
                      activeSubstanceValues.substanceFullName
                    ] ?? '—'
                  }
                  dosage={nutritionHistoryItem.concentration}
                  dataTestId={activeSubstanceKey}
                />
              );
            })}
      </SliderContainer>
    </Styled.Wrapper>
  );
};

ActiveSubstancesRow.displayName = 'ActiveSubstancesRow';

export default memo(ActiveSubstancesRow);
