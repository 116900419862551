import { makeAutoObservable } from 'mobx';

import { IFertilizerPrice } from '../../../../../../../../../../../../../api/models/as-fields/plan/FertilizerPrice';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
export class InventoryValueStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _mapIdToFertilizerPrice: Map<string, IFertilizerPrice> = new Map();
  private _fertilizerCurrentPage = 0;
  private _fertilizerTotalPages = 0;
  private _fertilizeSearchQuery = '';
  private _fertilizeOptionList: ISelectOption[] = [];

  private _currentFertilizerIdList: Set<string> = new Set();

  get currentFertilizerIdList(): string[] {
    return [...this._currentFertilizerIdList.values()];
  }

  setCurrentFertilizerIdList = (fertilizerIdList: string[]): void => {
    this._currentFertilizerIdList = new Set(fertilizerIdList);
  };

  setCurrentFertilizerId = (fertilizerId: string): void => {
    this._currentFertilizerIdList.add(fertilizerId);
  };

  clearCurrentFertilizerIdList = (): void => {
    this._currentFertilizerIdList.clear();
  };

  setFertilizerPrices = (fertilizerPrices: IFertilizerPrice[]) => {
    const newMap = new Map();
    for (const fertilizerPrice of fertilizerPrices) {
      newMap.set(fertilizerPrice.fertilizerId, fertilizerPrice);
    }

    this._mapIdToFertilizerPrice = newMap;
  };

  setFertilizerCurrentPage = (pageNumber: number) => {
    this._fertilizerCurrentPage = pageNumber;
  };

  setFertilizerTotalPages = (pageNumber: number) => {
    this._fertilizerTotalPages = pageNumber;
  };

  setFertilizeSearchQuery = (query: string) => {
    this._fertilizeSearchQuery = query;
  };

  setFertilizeOptionList = (newOptionList: ISelectOption[]) => {
    this._fertilizeOptionList = newOptionList;
  };

  getPriceByFertilizerId = (id: string) => this._mapIdToFertilizerPrice.get(id);

  get fertilizerPrices() {
    return [...this._mapIdToFertilizerPrice.values()];
  }

  get fertilizerCurrentPage() {
    return this._fertilizerCurrentPage;
  }

  get fertilizerTotalPages() {
    return this._fertilizerTotalPages;
  }

  get fertilizeSearchQuery() {
    return this._fertilizeSearchQuery;
  }

  get fertilizeOptionList() {
    return this._fertilizeOptionList;
  }
}
