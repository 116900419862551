import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const StyledLabel = styled.label`
  display: block;
  cursor: pointer;

  & > span {
    color: ${ECommonColor.AccentGreen};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15px;
  }
`;

export default StyledLabel;
