import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

import { ECommonColor } from '../../../../../../../common/assets/styles/colors';

const ButtonPlugUpload = styled(Button)`
  font-weight: 600;
  height: 40px;
  width: 240px;
  margin-top: 40px;
  border-radius: 12px;
  border: 1px solid ${ECommonColor.AccentGreen};
  color: ${ECommonColor.AccentGreen};
  background-color: transparent;
  justify-content: center;
  padding: 10px;
`;

const TableContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const TableBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const AddDocumentButtonWrapper = styled.div`
  width: max-content;
  align-self: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: ${ECommonColor.GeneralBlack};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Wrapper = styled.div``;

const Styled = {
  ButtonPlugUpload,
  TableContent,
  TableBody,
  Wrapper,
  AddDocumentButtonWrapper,
  HeaderWrapper,
  Title,
};

export default Styled;
