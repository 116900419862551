import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

import { CreateObservationService } from '../../services';
import { CreateObservationStore } from '../../stores';
import { createExpirimentOptionList, createOptionListFromUsers } from '../../../utils/functions';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { ISelectOption } from '../../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import {
  createDictionaryEntitySelectOptionList,
  createExpStepSelectOptionList,
  createOrganizationSelectOptionList,
} from '../../../../../../../../../../../../common/utils/helpers/selectOptions';
import { ExperimentStepsService } from '../../../../../../../../../../../../common/mobx/services/as-fields';
import { AuditStore } from '../../../../../../../mobx/store';
import { DictionaryService } from '../../../../../../../../../../../../common/mobx/services/da-dictionary';
import { IExperimentStep } from '../../../../../../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class CreateObservationController {
  @lazyInject(AuditStore)
  protected auditStore: AuditStore;

  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  @lazyInject(CreateObservationStore)
  protected createObservationStore: CreateObservationStore;

  @lazyInject(CreateObservationService)
  protected createObservationService: CreateObservationService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAudit = async (id: string): Promise<IExperimentStep> => {
    const { setSelectedAudit } = this.auditStore;
    const { getExperimentStep } = this.experimentStepsService;

    const fetchedAudit = await getExperimentStep({ id });

    if (fetchedAudit) {
      setSelectedAudit(fetchedAudit);

      return fetchedAudit;
    }
  };

  saveExperimentStep = async data => {
    const experimentId = await this.createObservationService.saveExperimentStep(data);

    return experimentId;
  };

  updateExperimentStep = async (data, auditId) => {
    const experimentId = await this.createObservationService.updateExperimentStep({
      ...data,
      experimentStepId: auditId,
    });

    return experimentId;
  };

  getOrganizationOptionList = (): ISelectOption[] => {
    const { organizationList } = this.createObservationStore;

    const organizationOptionList = createOrganizationSelectOptionList(organizationList);

    return organizationOptionList;
  };

  organizationsSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { content, totalPages } = await this.createObservationService.getOrganizations(
      searchQuery,
      0
    );

    if (_.isArray(content)) {
      const {
        setOrganizationById,
        setDropdownPageNumbers,
        setDropdownSearchQuery,
        clearOrganizationById,
      } = this.createObservationStore;

      clearOrganizationById();

      setDropdownPageNumbers('organizationCurrentPageNumber', 0);
      setDropdownPageNumbers('organizationTotalPageNumber', totalPages);
      setDropdownSearchQuery('organizationSearchQuery', searchQuery);
      setOrganizationById(content);

      const organizationOptionList = this.getOrganizationOptionList();

      return organizationOptionList;
    } else {
      return [];
    }
  };

  onOrganizationListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, setOrganizationById } = this.createObservationStore;

    const { content } = await this.createObservationService.getOrganizations(
      querySearch,
      dropdownPageNumbers.organizationCurrentPageNumber
    );

    setOrganizationById(content);

    const organizationOptionList = this.getOrganizationOptionList();

    return organizationOptionList;
  };

  getAssigneeOptionList = (): ISelectOption[] => {
    const { allUserList } = this.createObservationStore;

    const assigneeOptionList = createOptionListFromUsers(allUserList);

    return assigneeOptionList;
  };

  usersSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { organizationId } = this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getAllUsers(
      searchQuery,
      organizationId,
      0
    );

    if (_.isArray(content)) {
      const { setAssigneeById, setDropdownPageNumbers, setDropdownSearchQuery, clearAssigneeById } =
        this.createObservationStore;

      clearAssigneeById();

      setDropdownPageNumbers('assigneeCurrentPageNumber', 0);
      setDropdownPageNumbers('assigneeTotalPageNumber', totalPages);
      setDropdownSearchQuery('assigneeSearchQuery', searchQuery);
      setAssigneeById(content);

      const assigneeOptionList = this.getAssigneeOptionList();

      return assigneeOptionList;
    } else {
      return [];
    }
  };

  onAssigneeListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, organizationId, setAssigneeById } = this.createObservationStore;

    const { content } = await this.createObservationService.getAllUsers(
      querySearch,
      organizationId,
      dropdownPageNumbers.assigneeCurrentPageNumber
    );

    setAssigneeById(content);

    const assigneeOptionList = this.getAssigneeOptionList();

    return assigneeOptionList;
  };

  getExperimentStepOptionList = (): ISelectOption[] => {
    const { expirimentStepList } = this.createObservationStore;

    const expirimentStepOptionList = createExpStepSelectOptionList(expirimentStepList, {
      isAddInitialModel: true,
    });

    return expirimentStepOptionList;
  };

  experimentStepsSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { experimentId } = this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getExperimentStepFullList(
      experimentId,
      searchQuery,
      0
    );

    if (_.isArray(content)) {
      const {
        setExperimentStepById,
        setDropdownPageNumbers,
        setDropdownSearchQuery,
        clearExperimentStepsById,
      } = this.createObservationStore;

      clearExperimentStepsById();

      setDropdownPageNumbers('experimentStepCurrentPageNumber', 0);
      setDropdownPageNumbers('experimentStepTotalPageNumber', totalPages);
      setDropdownSearchQuery('experimentStepSearchQuery', searchQuery);
      setExperimentStepById(content);

      const expirimentStepOptionList = this.getExperimentStepOptionList();

      return expirimentStepOptionList;
    } else {
      return [];
    }
  };

  onExperimentStepListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, experimentId, setExperimentStepById } =
      this.createObservationStore;

    const { content } = await this.createObservationService.getExperimentStepFullList(
      experimentId,
      querySearch,
      dropdownPageNumbers.experimentStepCurrentPageNumber
    );

    setExperimentStepById(content);

    const expirimentStepOptionList = this.getExperimentStepOptionList();

    return expirimentStepOptionList;
  };

  getOperationOptionList = (): ISelectOption[] => {
    const { operationList } = this.createObservationStore;

    const operationOptionList = createDictionaryEntitySelectOptionList(operationList);

    return operationOptionList;
  };

  operationTypesSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { content, totalPages } = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        nameFilter: searchQuery,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
        size: 20,
      }
    );

    if (_.isArray(content)) {
      const {
        setOperationById,
        setDropdownPageNumbers,
        setDropdownSearchQuery,
        clearOperationById,
      } = this.createObservationStore;

      clearOperationById();

      setDropdownPageNumbers('techOperationCurrentPageNumber', 0);
      setDropdownPageNumbers('techOperationTotalPageNumber', totalPages);
      setDropdownSearchQuery('techOperationSearchQuery', searchQuery);
      setOperationById(content);

      const operationOptionList = this.getOperationOptionList();

      return operationOptionList;
    } else {
      return [];
    }
  };

  onTechOperationListScroll = async (querySearch: string) => {
    const { dropdownPageNumbers, setOperationById } = this.createObservationStore;

    const { content } = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'techOperationType',
        latestVersion: true,
        fetchAttributes: true,
        nameFilter: querySearch,
        attrs: {
          forUH: true,
        },
      },
      {
        sort: 'name,asc',
        size: 20,
        page: dropdownPageNumbers.techOperationCurrentPageNumber,
      }
    );

    setOperationById(content);

    const operationOptionList = this.getOperationOptionList();

    return operationOptionList;
  };

  getExperimentOptionList = (): ISelectOption[] => {
    const { expirimentList } = this.createObservationStore;

    const experimentOptionList = createExpirimentOptionList(expirimentList);

    return experimentOptionList;
  };

  experimentSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const {
      setExperimentById,
      setDropdownPageNumbers,
      setDropdownSearchQuery,
      clearExperimentById,
    } = this.createObservationStore;

    const { content, totalPages } = await this.createObservationService.getExpiriments(searchQuery);

    if (_.isArray(content)) {
      clearExperimentById();

      setDropdownPageNumbers('experimentCurrentPageNumber', 0);
      setDropdownPageNumbers('experimentTotalPageNumber', totalPages);
      setDropdownSearchQuery('experimentSearchQuery', searchQuery);
      setExperimentById(content);

      const experimentOptionList = this.getExperimentOptionList();

      return experimentOptionList;
    } else {
      return [];
    }
  };

  onExperimentListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { dropdownPageNumbers, setExperimentById } = this.createObservationStore;

    const { content } = await this.createObservationService.getExpiriments(
      searchQuery,
      dropdownPageNumbers.experimentCurrentPageNumber
    );

    setExperimentById(content);

    const experimentOptionList = this.getExperimentOptionList();

    return experimentOptionList;
  };

  changeDropdownPageNumber = (dropdownName: string): void => {
    const { setDropdownPageNumbers, dropdownPageNumbers } = this.createObservationStore;

    setDropdownPageNumbers(dropdownName, dropdownPageNumbers[dropdownName] + 1);
  };
}

export default CreateObservationController;
