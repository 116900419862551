import styled from 'styled-components';
import { Button } from '@farmlink/farmik-ui';

const ButtonReady = styled(Button)`
  font-weight: 600;
  height: 56px;
  width: 300px;
  margin-top: 40px;
  margin-left: auto;
  justify-content: center;
`;

const TableContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const TableBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Wrapper = styled.div``;

const StyledAuditTasks = {
  ButtonReady,
  TableContent,
  TableBody,
  Wrapper,
};

export default StyledAuditTasks;
