import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import {
  EExperimentStatus,
  IAttachedFile,
} from '../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { useWarningBeforeDeleting } from '../../../../../../../../../common/utils/hooks';
import { EExperimentsTableBuilderId } from '../../../../../../constants/configs';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { TableBuilder } from '../../../../../../../../../common/features/TableBuilder';
import { DocumentsStore } from '../../mobx/stores';
import { DocumentsController } from '../../mobx/controllers';
import { DocumentsNoDataPlug } from '../DocumentsNoDataPlug';

const builderId = EExperimentsTableBuilderId.Documents;

export const DocumentsList = observer(() => {
  const { attachedFiles, experimentStatus } = useStore(DocumentsStore);

  const tableBuilderController = useStore(TableBuilderController);

  const { initiateTable, deleteExperimentFile, downloadFile } = useStore(DocumentsController);

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const downloadFileAction = (file: IAttachedFile) => {
    downloadFile(file);
  };

  const deleteFileAction = (fileId: string, fileName: string) => {
    showWarningBeforeDeleting(fileName, () => deleteExperimentFile(fileId));
  };

  const isShowDeleteButton = useMemo(
    () => experimentStatus !== EExperimentStatus.FullCompleted,
    [experimentStatus]
  );

  useEffect(() => {
    initiateTable(downloadFileAction, deleteFileAction, isShowDeleteButton);
  }, [isShowDeleteButton]);

  useEffect(() => {
    tableBuilderController.showLoader(builderId);

    tableBuilderController.addRowsToRowsGroupListById(builderId, builderId, attachedFiles, {
      isClearPreviousList: true,
    });

    tableBuilderController.hideLoader(builderId);
  }, [attachedFiles]);

  return (
    <TableBuilder
      builderId={builderId}
      renderNoDataPlug={() => <DocumentsNoDataPlug isShowButton={isShowDeleteButton} />}
      renderDefaultPlug={() => <DocumentsNoDataPlug isShowButton={isShowDeleteButton} />}
    />
  );
});
