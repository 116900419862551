import { makeAutoObservable } from 'mobx';

import {
  TGetCultureZoneExperimentListReq,
  TGetExperimentStepListReq,
  TGetNutritionHistoryListReg,
} from '../../../../../../../../../../../../api';
import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { INutritionHistoryItem } from '../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { TableBuilderController } from '../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import {
  ExperimentStepsService,
  NutritionHistoryService,
} from '../../../../../../../../../../../common/mobx/services/as-fields';
import NutritionHistoryItemService from '../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { EExperimentsTableBuilderId } from '../../../../../../../../constants/configs';
import { ICopyFieldForm } from '../../configs/forms/copyFieldForm';
import { tabContent } from '../../constants';
import { HistoryService } from '../services/History.service';
import HistoryActiveSubstancesConfigsService from '../services/HistoryActiveSubstancesConfig.service';
import HistoryTechOperationConfigsService from '../services/HistoryTechOperationConfig.service';
import { HistoryStore } from '../stores/History.store';

@provide.singleton()
export class HistoryController {
  @lazyInject(HistoryService)
  historyService: HistoryService;

  @lazyInject(HistoryStore)
  historyStore: HistoryStore;

  @lazyInject(ExperimentStepsService)
  experimentStepService: ExperimentStepsService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(HistoryActiveSubstancesConfigsService)
  protected configsActiveSubstancesService: HistoryActiveSubstancesConfigsService;

  @lazyInject(HistoryTechOperationConfigsService)
  protected configsTechOperationService: HistoryTechOperationConfigsService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  constructor() {
    makeAutoObservable(this);
  }

  initiateTable = (
    onClickAddTechoperation,
    onClickDeleteTechoperation,
    onClickEditTechoperation,
    onClickDeleteInventoryValue,
    onClickEditInventoryValue,
    builderId,
    isViewOnly
  ): void => {
    if (builderId === EExperimentsTableBuilderId.HistoryActiveSubstances) {
      const config = this.configsActiveSubstancesService.createConfig(
        onClickAddTechoperation,
        onClickDeleteTechoperation,
        onClickEditTechoperation,
        onClickDeleteInventoryValue,
        onClickEditInventoryValue,
        isViewOnly
      );

      this.tableBuilderController.initiateTable(config);
    } else {
      const config = this.configsTechOperationService.createConfig(
        onClickAddTechoperation,
        onClickDeleteTechoperation,
        onClickEditTechoperation,
        onClickDeleteInventoryValue,
        onClickEditInventoryValue,
        isViewOnly
      );

      this.tableBuilderController.initiateTable(config);
    }
  };

  fetchCultureZones = async (payload: TGetCultureZoneExperimentListReq) => {
    const cultureZones = await this.historyService.getCultureZoneExperimentList(payload);
    const sortedCultureZones = cultureZones.sort(prev =>
      prev.type === EExperimentCultureZoneType.Control ? -1 : 1
    );
    this.historyStore.setCultureZones(sortedCultureZones);
    this.historyStore.setActiveCultureZone(sortedCultureZones[0]);
  };

  onChangeActiveCultureZone = (id: string) => {
    const currentCultureZone = this.historyStore.getCultureZoneById(id);
    this.historyStore.setActiveCultureZone(currentCultureZone);
  };

  onChangeActiveTab = (activeTab: 'techOperation' | 'activeSubstance') => {
    this.historyStore.setActiveTab(activeTab);
    this.historyStore.setActiveTabName(tabContent.find(tab => tab.id === activeTab).label);
  };

  onChangeTechOperation = (experimentStep: IExperimentStep) => {
    this.historyStore.setCurrentTechOperation(experimentStep);
  };

  onChangeInventoryValue = (inventoryValue: INutritionHistoryItem) => {
    this.historyStore.setCurrentInventoryValue(inventoryValue);
  };

  fetchExperimentSteps = async (payload: TGetExperimentStepListReq) => {
    const experimentSteps = await this.experimentStepService.getExperimentStepList(payload);
    this.historyStore.setExperimentSteps(experimentSteps);
  };

  fetchNutritionHistories = async (payload: TGetNutritionHistoryListReg) => {
    const nutritionHistories = await this.nutritionHistoryService.getNutritionHistoryList(payload, {
      query: {
        isFact: true,
      },
    });
    const inventoryValues = nutritionHistories
      .filter(item => item.items.length)
      .flatMap(nutritionHistory => nutritionHistory.items);
    this.historyStore.setNutritionHistory(nutritionHistories);
    this.historyStore.setInventoryValues(inventoryValues);
  };

  deleteInventoryValue = async (
    stepId: string,
    fertilizerId: string,
    payload: TGetNutritionHistoryListReg
  ) => {
    await this.nutritionHistoryItemService.deleteNutritionItemHistory(stepId, fertilizerId);
    await this.fetchNutritionHistories({
      experimentId: payload.experimentId,
      experimentStepType: EExperimentStepType.NutritionHistory,
      cultureZoneExperimentId: payload.cultureZoneExperimentId,
    });
  };

  deleteExperimentStep = async (id: string, payload: TGetExperimentStepListReq) => {
    await this.experimentStepService.deleteExperimentStep({ experimentStepId: id });
    await this.fetchExperimentSteps(payload);
  };

  copyNutritionHistory = async (
    forms: ICopyFieldForm,
    activeCultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    await this.nutritionHistoryService.copyNutritionHistory(
      forms.fieldId,
      activeCultureZone.id,
      EExperimentStepType.NutritionHistory
    );
    await this.fetchNutritionHistories({
      experimentId,
      experimentStepType: EExperimentStepType.NutritionHistory,
      cultureZoneExperimentId: activeCultureZone.id,
    });
    await this.fetchExperimentSteps({
      experimentId,
      type: EExperimentStepType.NutritionHistory,
      cultureZoneExperimentId: activeCultureZone.id,
    });
  };
}
