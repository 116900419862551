import { FC, memo, useMemo } from 'react';

import { CostCellTitle } from '../CostCellTitle';
import { CostCell as CellContent } from '../../content';
import { IEconomyData } from '../../../../model/IEconomyData.model';

import Styled from './CostCell.styles';

interface ICostCellComponentProps {
  row: Pick<IEconomyData, 'id' | 'children' | 'jobCost'>;
}

const CostCellComponent: FC<ICostCellComponentProps> = ({ row }) => {
  const totalCost = useMemo(() => {
    const totalFertilizerCost = row.children.reduce<number>((acc, nutritionItem) => {
      if (!nutritionItem.concentration || !nutritionItem.pricePerUnit) {
        return acc;
      }

      return acc + nutritionItem.concentration * nutritionItem.pricePerUnit;
    }, 0);

    if (!row.jobCost) {
      return totalFertilizerCost;
    }

    const totalCostValue = totalFertilizerCost + row.jobCost;

    return totalCostValue;
  }, [row.children, row.jobCost]);

  return (
    <Styled.Wrapper data-test-id={`cost-cell-wrapper-${row?.id}`}>
      <CostCellTitle dataTestId={row.id} totalCost={totalCost} />

      {row.children.map(value => (
        <CellContent key={value.fertilizer.id} cost={value.concentration * value.pricePerUnit} />
      ))}

      <CellContent cost={row.jobCost} />
    </Styled.Wrapper>
  );
};

CostCellComponent.displayName = 'CostCellComponent';

export default memo(CostCellComponent);
