export enum EContextualParentPath {
  ExperimentsExperiment = 'exp/experiments/experiment',
  ExperimentsExperimentZones = 'exp/experiments/experiment/zones',
  ExperimentsExperimentCalculation = 'exp/experiments/experiment/calculation',
  ExperimentsExperimentExecution = 'exp/experiments/experiment/execution',
  AuditsAuditReportAudit = 'exp/audits/audit/report/audit',
  AuditsAuditPhotos = 'exp/audits/audit/photos',
  Audits = 'exp/audits',
}

export const ContextualPaths = {
  ExperimentsExperimentHeaderTitle: `${EContextualParentPath.ExperimentsExperiment}/header__title`,
  ExperimentsExperimentZonesMapTitle: `${EContextualParentPath.ExperimentsExperimentZones}/map__title`,
  ExperimentsExperimentCalculationNutritionSystemTitle: `${EContextualParentPath.ExperimentsExperimentCalculation}/nutrition-system__title`,
  ExperimentsExperimentExecutionStepsTableTitle: `${EContextualParentPath.ExperimentsExperimentExecution}/steps-table__title`,
  AuditsHeaderTitle: `${EContextualParentPath.Audits}/header__title`,
  AuditsAuditReportAuditComparisonTableTitle: `${EContextualParentPath.AuditsAuditReportAudit}/comparison-table__title`,
  AuditsAuditPhotosPhotosTableTitle: `${EContextualParentPath.AuditsAuditPhotos}/photos-table__title`,
};
