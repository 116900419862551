import { Typography } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled.div``;

const MainInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
`;

const StatusComponentWrapper = styled.div`
  margin: auto;
  margin-left: 12px;
`;

const ContextualIconWrapper = styled.div`
  margin-left: 4px;
`;

const AdditionalInfo = styled(Typography)`
  color: #7e8b9d;
`;

const StyledBackButtonContent = {
  Wrapper,
  MainInfoWrapper,
  StatusComponentWrapper,
  ContextualIconWrapper,
  AdditionalInfo,
};

export default StyledBackButtonContent;
