import { ReactElement } from 'react';
import styled from 'styled-components';
import { Button, Colors } from '@farmlink/farmik-ui';

import { Breakpoints } from '../../../constants/sizes';

// @ts-ignore
import daLogoSvg from './assets/images/da-logo-208x42.svg';

const { green, black } = Colors;

interface IPageErrorProps {
  $icon: ReactElement;
  $numberWidth?: string;
  $smallNumberWidth?: string;
  $extraSmallNumberWidth?: string;
}

const Logo = styled.div`
  width: 208px;
  height: 42px;

  background: url(${daLogoSvg});
  background-size: contain;

  @media (max-width: ${Breakpoints.extraSmall}) {
    width: 178px;
    height: 36px;
  }
`;

const Header = styled.header`
  width: 100%;
  margin-top: 40px;
  display: flex;

  @media (max-width: ${Breakpoints.small}) {
    justify-content: center;
    text-align: center;
  }

  @media (max-width: ${Breakpoints.extraSmall}) {
    min-width: 178px;
    margin-top: 24px;
  }
`;

const ButtonLink = styled(Button)`
  width: 300px !important;

  justify-content: center;

  @media (max-width: ${Breakpoints.small}) {
    width: calc(100vw - 64px) !important;
  }
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 34px;
  line-height: 41px;

  margin-bottom: 145px;

  color: ${black};

  @media (max-width: ${Breakpoints.small}) {
    justify-content: center;
    text-align: center;
  }

  @media (max-width: ${Breakpoints.extraSmall}) {
    font-size: 21px;
    line-height: 26px;
  }
`;

const Title = styled.span`
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;

  margin-bottom: 12px;

  color: ${green};

  @media (max-width: ${Breakpoints.small}) {
    text-align: center;
  }
`;

const Number = styled.div<IPageErrorProps>`
  width: ${({ $numberWidth }) => $numberWidth};
  height: 86px;

  margin-bottom: 40px;

  background: ${({ $icon }) => `url(${$icon})`};
  background-size: contain;

  @media (max-width: ${Breakpoints.small}) {
    width: ${({ $smallNumberWidth }) => $smallNumberWidth};
    height: 72px;
  }

  @media (max-width: ${Breakpoints.extraSmall}) {
    width: ${({ $extraSmallNumberWidth }) => $extraSmallNumberWidth};
    height: 42px;
  }
`;

const Icon = styled.div<IPageErrorProps>`
  height: 300px;
  width: 300px;

  background: ${({ $icon }) => `url(${$icon})`};
`;

const IconWrapper = styled.div`
  flex: 0 0 300px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (max-width: ${Breakpoints.small}) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (max-width: ${Breakpoints.small}) {
    align-items: center;
  }
`;

const Body = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.small}) {
    display: block;
  }
`;

const BodyWrapper = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PageWrapper = styled.div`
  max-width: 1160px;

  height: 100vh;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.large}) {
    max-width: 100%;
    margin: 0 140px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    max-width: 100%;
    margin: 0 80px;
  }

  @media (max-width: ${Breakpoints.small}) {
    margin: 0 32px;
  }
`;

const StyledPageNotFound = {
  Logo,
  Header,
  ButtonLink,
  Description,
  Title,
  Number,
  Icon,
  IconWrapper,
  Content,
  Body,
  BodyWrapper,
  PageWrapper,
};

export default StyledPageNotFound;
