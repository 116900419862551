import { observer } from 'mobx-react';
import { FC, memo, MouseEvent, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { ExperimentStepsService } from '../../../../../../../common/mobx/services/as-fields';
import { AuditStore } from '../../mobx/store';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { DeleteIconComponent } from '../DeleteIconComponent';
import { ObservationController } from '../../../../containers/Observations/mobx/controllers';
import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { generateAuditsPath } from '../../../../utils/helpers';
import { useWarningBeforeDeleting } from '../../../../../../../common/utils/hooks';
import { EClearedPaths } from '../../utils/constants';
import useClearedCurrentPath from '../../hooks/path/useClearedCurrentPath/useClearedCurrentPath';

import Styled from './HeaderContent.style';

const HeaderContent: FC = observer(() => {
  const { selectedAudit } = useStore(AuditStore);

  const { deleteAudit } = useStore(ObservationController);

  const experimentStepsService = useStore(ExperimentStepsService);
  const { generateMonitoringReport } = experimentStepsService;

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const { clearedCurrentPath } = useClearedCurrentPath();

  const navigate = useNavigate();

  const params = useParams();

  const isPermissionToDelete = useMemo(() => {
    return (
      selectedAudit?.id &&
      selectedAudit?.canDelete &&
      clearedCurrentPath !== EClearedPaths.editAudit
    );
  }, [selectedAudit?.id, clearedCurrentPath]);

  const generateMonitoring = async () => {
    try {
      const response = await generateMonitoringReport(params.auditId);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, selectedAudit.name);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteAuditAction = async () => {
    await deleteAudit(selectedAudit?.id);

    navigate(generateAuditsPath(EDashboardRoute.Audits));
  };

  const deleteAuditWarning = (e: MouseEvent<SVGSVGElement>) => {
    e?.stopPropagation();
    showWarningBeforeDeleting('наблюдение', deleteAuditAction);
  };

  const isNotShowMonitoringButton =
    params?.auditId === 'create' || clearedCurrentPath === EClearedPaths.editAudit;

  return (
    <Styled.StyledWrapper>
      {isPermissionToDelete ? <DeleteIconComponent onClick={deleteAuditWarning} /> : null}

      {isNotShowMonitoringButton ? null : (
        <Styled.StyledButton
          color="primary"
          onClick={generateMonitoring}
          data-test-id="fullscreen-action-button"
        >
          Сформировать отчет
        </Styled.StyledButton>
      )}
    </Styled.StyledWrapper>
  );
});

export default memo(HeaderContent);
