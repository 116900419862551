import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import Styled from './TableBuilderCell.styles';

interface IProps {
  value: string | number;
  dataTestId?: string;
  alignItems?: 'top' | 'right' | 'bottom' | 'left' | 'center';
  isTitle?: boolean;
  height?: number;
  isWrap?: boolean;
}

const TableBuilderCell: FC<IProps> = ({
  value,
  dataTestId,
  alignItems,
  isTitle,
  height,
  isWrap,
}) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip content={value ?? '—'} disabled={!isShowTooltip} position={'bottom'}>
      <Styled.Wrapper
        ref={ref}
        data-test-id={dataTestId}
        $alignItems={alignItems}
        $height={height}
        $isWrap={isWrap}
        $isTitle={isTitle}
      >
        {value ?? '—'}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

TableBuilderCell.displayName = 'TableBuilderCell';

export default memo(TableBuilderCell);
