import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import { createOrganizationSelectOptionList } from '../../../../../../../../common/utils/helpers/selectOptions';
import { OrganizationsService } from '../../../../../../../../common/mobx/services/da-profile';
import { TFilterPaginationQuery } from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';
import { TContractsFiltersPageQueryConfig as TPageQueryConfig } from '../../../types';

@provide.transient()
class ContractsFiltersOptionsService {
  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;

  get optionsQueryScheme(): TPageQueryConfig {
    return {
      organizationIdIn: {
        requestHandler: page => this.organizationOptionListSearchQueryHandler(page),
      },
    };
  }

  organizationOptionListSearchQueryHandler = async (
    page?: number
  ): Promise<TFilterPaginationQuery> => {
    const SIZE = 20;

    const response = await this.organizationsService.getExperimentOrganizationList({
      size: SIZE,
      page: page ?? 0,
    });

    if (response?.content) {
      return {
        optionList: createOrganizationSelectOptionList(response.content),
        size: SIZE,
        totalPages: response.totalPages ?? 0,
      };
    }

    return { optionList: [], size: SIZE, totalPages: response.totalPages ?? 0 };
  };
}

export default ContractsFiltersOptionsService;
