import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TCreateAggregateInstanceReq, TGetChecklistInstanceReq } from '../../../../../../api';
import { IChecklistInstance } from '../../../../../../api/models/as-fields/checklists/Instance/ChecklistInstance.model';

@provide.singleton()
class ChecklistsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  getInstance = async (payload: TGetChecklistInstanceReq): Promise<IChecklistInstance> => {
    const { api } = this.axiosService;

    try {
      const fetchedInstance = await api.getChecklistInstance(payload, {
        omit: ['id'],
      });

      return fetchedInstance;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createAggregateInstance = async (payload: TCreateAggregateInstanceReq): Promise<string> => {
    const { api } = this.axiosService;

    try {
      const { id } = await api.createAggregateInstance(payload);

      return id;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default ChecklistsService;
