import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';

import { IExperimentChangeStatusAvailability } from '../../../../../../../../../../api/models/as-fields/experiments/Experiment';
import { Approval } from '../Approval';
import { ApprovalController } from '../../mobx';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { parseEventRecordToMessage } from '../../utils/parseEventRecordToMessage';
import { ExperimentSubsectionsStore } from '../../../../../experimentSubsections/mobx/stores';

type TRequestMessageListApiName = 'getExperimentComments';
type TSendMessageApiName = 'postExperimentComment';

const ExperimentApproval: FC = () => {
  const { fetchExperimentCommentList, sendMessage, changeStatus } = useStore(ApprovalController);
  const { setExperiment, experiment } = useStore(ExperimentSubsectionsStore);

  const availabilityScheme = useMemo<IExperimentChangeStatusAvailability>(() => {
    if (experiment) {
      return {
        canApprovedToCanceled: experiment?.canApprovedToCanceled,
        canApprovedToInApproval: experiment?.canApprovedToInApproval,
        canApprovedToInProgress: experiment?.canApprovedToInProgress,
        canCanceledToApproved: experiment?.canCanceledToApproved,
        canCanceledToInProgress: experiment?.canCanceledToInProgress,
        canCompletedToInProgress: experiment?.canCompletedToInProgress,
        canCompletedToFactApproved: experiment?.canCompletedToFactApproved,
        canDraftToInApproval: experiment?.canDraftToInApproval,
        canDraftToInProgress: experiment?.canDraftToInProgress,
        canInApprovalToApproved: experiment?.canInApprovalToApproved,
        canInApprovalToDraft: experiment?.canInApprovalToDraft,
        canInProgressToCanceled: experiment?.canInProgressToCanceled,
        canInProgressToCompleted: experiment?.canInProgressToCompleted,
        canInProgressToDraft: experiment?.canInProgressToDraft,
        canFactApprovedToCompleted: experiment?.canFactApprovedToCompleted,
        canFactApprovedToFullCompleted: experiment?.canFactApprovedToFullCompleted,
        canFullCompletedToFactApproved: experiment?.canFullCompletedToFactApproved,
      };
    }
  }, [experiment]);

  return (
    <Approval<TRequestMessageListApiName, TSendMessageApiName>
      entityApproveId={experiment?.id}
      entityApproveStatus={experiment?.status}
      entityAvailabilityScheme={availabilityScheme}
      fetchMessageListHandler={fetchExperimentCommentList}
      sendMessageHandler={sendMessage}
      parseEntityToMessageHandler={parseEventRecordToMessage}
      changeStatusHandler={changeStatus}
      setOnChangeEntityData={setExperiment}
      statusScheme="experiment"
    />
  );
};

ExperimentApproval.displayName = 'ExperimentApproval';

export default observer(ExperimentApproval);
