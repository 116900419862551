import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const { White } = ECommonColor;

const Wrapper = styled.div`
  max-width: 912px;
  margin: 0 auto;
  border-radius: 16px;
  padding: 24px;
  background: ${({ theme }) => theme.Colors.generalLight ?? '#ffffff'};
`;

const Container = styled.div`
  background-color: ${White};
  border-radius: 16px;
  padding: 24px;
  margin-top: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e6f0;
  margin-bottom: 16px;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
  display: block;
`;

const StyledRemarksForm = {
  Wrapper,
  Container,
  TitleWrapper,
  Title,
};

export default StyledRemarksForm;
