import { FC, memo, useCallback, useMemo } from 'react';
import { css } from 'styled-components';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react';
import { ENotificationType, ENotificatorTheme, useNotificator } from '@farmlink/farmik-ui';

import { IAttachedFile } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import {
  MenuDropdown,
  IMenuDropdown,
} from '../../../../../../../../../../../common/features/UI/MenuDropdown';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ECommonColor } from '../../../../../../../../../../../common/assets/styles/colors';
import { useWarningBeforeDeleting } from '../../../../../../../../../../../common/utils/hooks';
import { AccessStore } from '../../../../../../../../../../../common/mobx/stores/AccessStore';
import deleteIconSvg from '../../../../../../assets/icons/delete-icon.svg';
import downloadIconSvg from '../../../../../../assets/icons/download-icon.svg';
import { ContractSidePanelStore } from '../../../../../../mobx/stores';
import { DocumentsTableService } from '../../mobx/services';
import { DocumentsTableController } from '../../mobx/controllers';
import { DocumentsTableStore } from '../../mobx/stores';

import Styled from './DocumentOptionsCell.styles';

const { AccentPink } = ECommonColor;

interface IProps {
  rowData: IAttachedFile;
}

const DocumentOptionsCell: FC<IProps> = ({ rowData }) => {
  const { downloadFile } = useStore(DocumentsTableService);

  const { updateAttachedFiles } = useStore(DocumentsTableController);

  const { attachedFiles } = useStore(DocumentsTableStore);

  const { selectedContract } = useStore(ContractSidePanelStore);

  const { isAllowToEditContracts } = useStore(AccessStore);

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();
  const { setNotification } = useNotificator();

  const downloadFileHandler = async () => {
    try {
      const response = await downloadFile(rowData.id);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, rowData.fileName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteFile = useCallback(() => {
    const attachedFilesIds = attachedFiles.map(file => file.id);
    const updatedAttachedFilesIds = attachedFilesIds.filter(id => id !== rowData.id);
    const payload = {
      id: selectedContract.id,
      attachedFiles: [...updatedAttachedFilesIds],
    };

    updateAttachedFiles(payload)
      .then(() => {
        setNotification({
          message: 'Документ успешно удален',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .catch((e: Error) => {
        setNotification({
          message: e.message,
          style: {
            placement: 'top-center',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      });
  }, [selectedContract?.id, attachedFiles]);

  const menuDropdownConfig: IMenuDropdown['config'] = useMemo(() => {
    const config: IMenuDropdown['config'] = {
      menuItems: [
        {
          icon: downloadIconSvg,
          label: 'Скачать',
          onClick: () => {
            downloadFileHandler();
          },
        },
      ],
      menu: {
        button: Styled.MenuButton,
        menuStyle: css`
          top: 8px;
          right: -10px;
        `,
      },
    };

    if (isAllowToEditContracts) {
      config.menuItems.push({
        icon: deleteIconSvg,
        label: 'Удалить',
        labelStyle: css`
          color: ${AccentPink};
        `,
        onClick: () => {
          showWarningBeforeDeleting(rowData.fileName, deleteFile);
        },
      });
    }

    return config;
  }, [isAllowToEditContracts, rowData.fileName]);

  return <MenuDropdown config={menuDropdownConfig} />;
};

DocumentOptionsCell.displayName = 'DocumentOptionsCell';

export default memo(observer(DocumentOptionsCell));
