import styled from 'styled-components';

import { ITablePlugBuilderAutoRenderConfig as IAutoRenderConfig } from '../../models/configs';
import searchIcon from '../../assets/icons/search.svg';
import fileIcon from '../../assets/icons/file.svg';
import mapDefaultIcon from '../../assets/icons/map-default.svg';

type TIconName = IAutoRenderConfig['header']['icon'];

const getIconByName = (name: TIconName) => {
  switch (name) {
    case 'search':
      return searchIcon;
    case 'file':
      return fileIcon;
    case 'map-default':
      return mapDefaultIcon;
    default:
      return '';
  }
};

const Footer = styled.div`
  margin-top: 40px;
`;

const Icon = styled.div<{ $name: TIconName }>`
  width: 80px;
  height: 80px;

  background: url(${({ $name }) => getIconByName($name)}); ;
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #7e8b9d;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #151f32;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTablePlugBuilderAutoRender = {
  Footer,
  Icon,
  Header,
  Description,
  Title,
  Content,
  Wrapper,
};

export default StyledTablePlugBuilderAutoRender;
