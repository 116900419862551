import { FC, memo, MouseEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useContextualHelpActions } from '@farmlink/farmik-ui';

import { Container } from '../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { AuditStore } from '../../mobx/store';
import { IDynamicTableConfig } from '../../../../../../../common/features/DynamicTable/models';
import { DynamicTable } from '../../../../../../../common/features/DynamicTable';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../common/features/form/utils/hooks';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';
import { ContentLoader } from '../../../../../../../common/features/UI/loaders/ContentLoader';
import { useWarningBeforeLeavingThePage } from '../../../../../../../common/utils/hooks';

import Styled from './AuditReport.styles';
import AuditReportController from './mobx/controller/AuditReport.controller';
import { AuditReportCommentSection, AuditReportStepFooterContainer } from './containers';
import {
  AUDIT_REPORT_FORM_KEY,
  createAuditReportFormConfig,
  IAuditReportForm,
} from './containers/AuditReportCommentSection/forms/config/auditReportFormConfig';
import { AuditReportPlug } from './components';

const AuditReport: FC = () => {
  const { selectedAudit, selectedExp } = useStore(AuditStore);
  const { getDynamicTableConfig, updateExperimentStep, fetchExperimentStepData } =
    useStore(AuditReportController);

  const [isLoading, setIsLoading] = useState(true);
  const [dynamicTableConfig, setDynamicTableConfig] = useState<IDynamicTableConfig | null>(null);

  usePageContextualHelp(EContextualParentPath.AuditsAuditReportAudit);
  const helpActions = useContextualHelpActions();

  const { isFormChanged, focusChangedElement, setIsFormChanged } = useCheckChangedForm();
  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditReportAudit,
    ContextualPaths.AuditsAuditReportAuditComparisonTableTitle
  );

  useEffect(() => {
    (async () => {
      if (!selectedExp || !selectedAudit) {
        return;
      }
      const config = await getDynamicTableConfig().finally(() => setIsLoading(false));

      setDynamicTableConfig(config);
    })();
  }, [selectedAudit, selectedExp]);

  const { elements, registerForm, changeListOfFormValue, submitForm } =
    useForm<IAuditReportForm>(AUDIT_REPORT_FORM_KEY);

  useEffect(() => {
    registerForm(createAuditReportFormConfig());
  }, []);

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      await updateExperimentStep(selectedAudit.id, validatedData);

      setIsFormChanged(false);
    });
  };

  useEffect(() => {
    if (selectedAudit?.id) {
      (async () => {
        const stepData = await fetchExperimentStepData(selectedAudit.id);

        changeListOfFormValue({
          specialNotes: stepData.specialNotes || '',
          technologicalDeviation: stepData.technologicalDeviation || '',
          generalComment: stepData.generalComment || '',
        });
      })();
    }
  }, [selectedAudit?.id]);

  return (
    <Styled.FormWrapper>
      <Container title="Сравнение по участкам" ContextualHelpIcon={ContextualHelpIcon}>
        {isLoading ? (
          <ContentLoader
            overlayStyles={{
              position: 'relative',
              height: '184px',
              width: '100%',
            }}
          />
        ) : dynamicTableConfig?.instances.length > 0 ? (
          <DynamicTable config={dynamicTableConfig} />
        ) : (
          <AuditReportPlug />
        )}
      </Container>

      <Container title="Комментарии">
        <AuditReportCommentSection elements={elements} />
      </Container>

      <AuditReportStepFooterContainer onSubmit={handleSubmit} />
    </Styled.FormWrapper>
  );
};

AuditReport.displayName = 'AuditReport';

export default memo(observer(AuditReport));
