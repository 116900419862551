import { makeAutoObservable } from 'mobx';

import { provide, lazyInject } from '../../../../../../../../../../common/utils/helpers/mobx';
import {
  EExperimentStatus,
  IAttachedFile,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import { ExperimentSubsectionsStore } from '../../../../../mobx/stores';

@provide.singleton()
export class DocumentsStore {
  @lazyInject(ExperimentSubsectionsStore)
  protected experimentsSubsectionsStore: ExperimentSubsectionsStore;

  constructor() {
    makeAutoObservable(this);
    this._attachedFiles = this.experimentsSubsectionsStore.experiment?.attachedFiles || [];
    this._experimentStatus = this.experimentsSubsectionsStore.experiment?.status;
  }

  private _attachedFiles: IAttachedFile[];
  private _experimentStatus: EExperimentStatus;

  get attachedFiles(): IAttachedFile[] {
    return this._attachedFiles;
  }

  get experimentStatus(): EExperimentStatus {
    return this._experimentStatus;
  }

  setAttachedFiles = (attachedFiles: IAttachedFile[]) => {
    this._attachedFiles = attachedFiles;
  };

  setExperimentStatus = (status: EExperimentStatus) => {
    this._experimentStatus = status;
  };

  clearAttachedFiles = () => {
    this._attachedFiles = [];
  };

  clearExperimentStatus = () => {
    this._experimentStatus = null;
  };

  clearStore = () => {
    this.clearAttachedFiles();
    this.clearExperimentStatus();
  };
}
