import styled from 'styled-components';

const Wrapper = styled.div`
  & > :not(:first-child) {
    margin-top: 20px;
  }
`;

const StyledTableBuilderContent = {
  Wrapper,
};

export default StyledTableBuilderContent;
