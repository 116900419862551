import { FC } from 'react';
import { observer } from 'mobx-react';

import {
  ExecutionContainerHeader as ContainerHeader,
  ExecutionContentLoader,
} from '../../components';
import ComparisonTableBuilder from '../../../../../../../../../common/features/ComparisonTableBuilder/ComparisonTableBuilder';
import { createExecutionTableBuilderId as createTableBuilderId } from '../../helpers';
import { Container } from '../../../../../../../../../common/features/UI';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionStore } from '../../mobx/stores';
import { EComparisonTableName as ETableName } from '../../../../../../../../constants/features';

import Styled from './ExecutionCalculations.styles';

const ExecutionCalculations: FC = () => {
  const executionStore = useStore(ExecutionStore);

  return (
    <Styled.Wrapper>
      <Container headerChildren={<ContainerHeader title={'Вводные расчёты по текущему участку'} />}>
        {executionStore.isPageLoading ? (
          <ExecutionContentLoader />
        ) : (
          <ComparisonTableBuilder
            builderId={createTableBuilderId(
              executionStore.selectedZoneId,
              ETableName.ExecutionCalculations
            )}
            tableName={ETableName.ExecutionCalculations}
          />
        )}
      </Container>
    </Styled.Wrapper>
  );
};

ExecutionCalculations.displayName = 'ExecutionCalculations';

export default observer(ExecutionCalculations);
