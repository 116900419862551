import moment from 'moment';

import { provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { IExecutionDeviationsCellFormattedValue as IDeviationsFormattedValue } from '../../../../../components/cells/ExecutionDeviationsCell/ExecutionDeviationsCell.types';
import { ExecutionCellsService } from '../../../../../mobx/services';
import {
  createExecutionTableRowId as createRowId,
  getExecutionTableRowsGroupIdByRowId as getRowsGroupIdByRowId,
} from '../../../../../helpers';
import { EExperimentFactTableStepAttributeId as EStepAttributeId } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentFactTable/ExperimentFactTable.model';

@provide.transient()
class ExecutionStepsCellsService extends ExecutionCellsService {
  getDeviationsConditionalValue = (builderId: string, rowId: string): IDeviationsFormattedValue => {
    const deviationsCell = this.getDeviationsCell(builderId, rowId);

    switch (deviationsCell.initialModel.type) {
      case 'date':
        return this.getDeviationsDateValue(builderId, rowId);

      case 'dictionary':
        return this.getDeviationsDictionaryValue(builderId, rowId);

      default:
    }
  };

  getDeviationsCalculatingValue = (
    builderId: string,
    rowId: string,
    options?: {
      isFertilizerCell?: boolean;
    }
  ): IDeviationsFormattedValue => {
    if (options?.isFertilizerCell) {
      return this.getDeviationsFertilizerDoubleValue(builderId, rowId);
    }

    return this.getDeviationsStepDoubleValue(builderId, rowId);
  };

  getPlanEndDateError = (builderId: string, rowId: string): string => {
    const rowsGroupId = getRowsGroupIdByRowId(rowId);
    const startDateRowId = createRowId(rowsGroupId, EStepAttributeId.StartDate);

    const initialStartDateValue = this.getFactCellDateValue(builderId, startDateRowId);
    const initialEndDateValue = this.getFactCellDateValue(builderId, rowId);

    const updatedStartDateValue = this.getStepFactUpdatedValue(builderId, startDateRowId);
    const updatedEndDateValue = this.getStepFactUpdatedValue(builderId, rowId);

    const startDateValue = updatedStartDateValue ?? initialStartDateValue;
    const endDateValue = updatedEndDateValue ?? initialEndDateValue;

    if (!startDateValue || !endDateValue) {
      return '';
    }

    const startDateMoment = moment(startDateValue, 'YYY-MM-DD');
    const endDataMoment = moment(endDateValue, 'YYY-MM-DD');

    const isEndDateBeforeStart = endDataMoment.isBefore(startDateMoment);

    if (isEndDateBeforeStart) {
      return 'Дата окончания должна быть позднее даты начала';
    }
  };

  getPhenophaseEndErrorList = (builderId: string, rowId: string): string[] => {
    const rowsGroupId = getRowsGroupIdByRowId(rowId);
    const startRowId = createRowId(rowsGroupId, EStepAttributeId.Phenophase);

    const startOption = this.executionStore.getSelectedDictionaryOption(
      this.executionStore.selectedZoneId,
      startRowId
    );

    const endOption = this.executionStore.getSelectedDictionaryOption(
      this.executionStore.selectedZoneId,
      rowId
    );

    if (!startOption || !endOption) {
      return [];
    }

    const isEndBeforeStart =
      Number(endOption.initialModel.code) < Number(startOption.initialModel.code);

    if (isEndBeforeStart) {
      return ['Фенофаза окончания должна быть позднее фенофазы начала'];
    }

    return [];
  };

  protected getDeviationsDateValue = (
    builderId: string,
    rowId: string
  ): IDeviationsFormattedValue => {
    const planInitialValue = this.getPlanCellDateValue(builderId, rowId);
    const factInitialValue = this.getFactCellDateValue(builderId, rowId);

    const factUpdatedValue = this.getStepFactUpdatedValue(builderId, rowId);

    return this.getFormattedDeviationsConditionalValue(
      planInitialValue,
      factUpdatedValue ?? factInitialValue
    );
  };

  protected getDeviationsDictionaryValue = (
    builderId: string,
    rowId: string
  ): IDeviationsFormattedValue => {
    const planInitialValue = this.getPlanCellDictionaryValue(builderId, rowId);
    const factInitialValue = this.getFactCellDictionaryValue(builderId, rowId);

    const factUpdatedValue = this.getStepFactUpdatedValue(builderId, rowId, { isPhenophase: true });

    return this.getFormattedDeviationsConditionalValue(
      planInitialValue,
      factUpdatedValue ?? factInitialValue
    );
  };

  protected getDeviationsFertilizerDoubleValue = (
    builderId: string,
    rowId: string
  ): IDeviationsFormattedValue => {
    const planInitialValue = this.getPlanCellDoubleValue(builderId, rowId);
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);

    const factUpdatedValue = this.getItemFactUpdatedValue(builderId, rowId);

    return this.getFormattedDeviationsDoubleValue(
      planInitialValue,
      factUpdatedValue ?? factInitialValue
    );
  };

  protected getDeviationsStepDoubleValue = (
    builderId: string,
    rowId: string
  ): IDeviationsFormattedValue => {
    const planInitialValue = this.getPlanCellDoubleValue(builderId, rowId);
    const factInitialValue = this.getFactCellDoubleValue(builderId, rowId);

    const factUpdatedValue = this.getStepFactUpdatedValue(builderId, rowId);

    return this.getFormattedDeviationsDoubleValue(
      planInitialValue,
      factUpdatedValue ?? factInitialValue
    );
  };
}

export default ExecutionStepsCellsService;
