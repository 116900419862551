import { FC, memo } from 'react';

import { StatusPill as StyledStatusPill } from '../StatusPill/StatusPill.styles';
import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

const StatusPillOnApprove: FC<{ children; isSelected?: boolean }> = ({ children, isSelected }) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Warning} $isSelected={isSelected}>
      На согласовании {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPillOnApprove);
