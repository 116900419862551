import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

import arrowLeft from './assets/images/arrow-left-green-12x12.svg';

const { AccentGreen } = ECommonColor;

const ArrowLeft = styled.div`
  width: 12px;
  height: 12px;

  background: url(${arrowLeft});
`;

const Action = styled.div<{ $isDisabled?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: ${AccentGreen};

  cursor: pointer;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: auto;
    `}
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & ${Action} {
    flex: 1 1 auto;
  }

  & ${ArrowLeft} {
    flex: 0 0 12px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 8px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  width: 100%;

  margin-top: 2px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
`;

const Wrapper = styled.div``;

const StyledTaskReportHeaderActions = {
  ArrowLeft,
  Action,
  ActionWrapper,
  ActionsWrapper,
  Title,
  TitleWrapper,
  Wrapper,
};

export default StyledTaskReportHeaderActions;
