import { ICalculationZone } from '../../InfoForCurrentSection';
import { getValue } from '../getValue';

const createCalculationCostData = (currentSection: ICalculationZone) => [
  {
    title: 'Стоимость удобрений, ₽/га',
    value: getValue(currentSection, 'fertilizerCost'),
  },
  { title: 'Стоимость работ, ₽/га', value: getValue(currentSection, 'jobCost') },
  {
    title: 'Суммарные затраты, ₽/га',
    value: getValue(currentSection, 'summaryCost'),
  },
];

export default createCalculationCostData;
