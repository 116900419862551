import { FC, memo } from 'react';

import { IActiveSubstancesData } from '../../../../model/IActiveSubstancesData.model';
import { DosageValueCell as CellContent } from '../../content';

import Styled from './DosageValueCell.styles';

interface IDosageValueCellComponent {
  row: Pick<IActiveSubstancesData, 'id' | 'children'>;
}

const DosageValueCellComponent: FC<IDosageValueCellComponent> = ({ row }) => {
  return (
    <Styled.Wrapper data-test-id={`dosage-cell-wrapper-${row?.id}`}>
      {row.children.length > 0 ? (
        row.children.map(item => <CellContent dosageValue={item.concentration} key={item.id} />)
      ) : (
        <Styled.Item data-test-id={`dosage-cell-item-${row?.id}`}>—</Styled.Item>
      )}
    </Styled.Wrapper>
  );
};

DosageValueCellComponent.displayName = 'DosageValueCellComponent';

export default memo(DosageValueCellComponent);
