import { FC, memo } from 'react';

import { Plug } from '../../../../../../../../../../../common/components/ui';
import applicationTimeIcon from '../../../../../../assets/icons/application_time.svg';

export interface ITablePlugProps {
  onClickAdd(): void;
  isViewMode?: boolean;
}

const AgrochemicalPlug: FC<ITablePlugProps> = ({ onClickAdd, isViewMode }) => {
  return (
    <Plug
      icon={applicationTimeIcon}
      title="Внесите результаты агрохимического обследования почвы"
      primaryButtonTitle="Добавить АХО"
      onPrimaryButtonClick={onClickAdd}
      buttonExist={!isViewMode}
    />
  );
};

export default memo(AgrochemicalPlug);
