import { FC, memo } from 'react';
import { AutoTooltip, useShowTooltip } from '@farmlink/farmik-ui';

import Styled from './TableBuilderColumn.styles';

interface IProps {
  name: string;
  dataTestId?: string;
}

const TableBuilderColumn: FC<IProps> = ({ name, dataTestId }) => {
  const { ref, isShowTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip content={name ?? '—'} disabled={!isShowTooltip} position={'bottom'}>
      <Styled.Wrapper ref={ref} data-test-id={dataTestId}>
        {name}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

export default memo(TableBuilderColumn);
