import { FC, memo } from 'react';

import icons from '../../../assets/icons';

import Styled from './ActionCell.styles';

interface IActionCell {
  deleteHandler: () => void;
  editHandler: () => void;
}

const ActionCell: FC<IActionCell> = ({ deleteHandler, editHandler }) => {
  return (
    <Styled.Wrapper>
      <Styled.ActionButton onClick={editHandler}>
        <Styled.Icon src={icons.edit} />
      </Styled.ActionButton>
      <Styled.ActionButton onClick={deleteHandler}>
        <Styled.Icon src={icons.delete} />
      </Styled.ActionButton>
    </Styled.Wrapper>
  );
};

ActionCell.displayName = 'ActionCell';

export default memo(ActionCell);
