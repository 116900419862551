import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const OperationInfo = styled.div`
  margin: auto;
  margin-left: 0;
`;

const TaskTypeSvg = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 16px;
`;

const StyledOperationTypeCell = {
  TaskTypeSvg,
  Wrapper,
  OperationInfo,
};

export default StyledOperationTypeCell;
