import { IExamination } from '../../../models/aho/examination/Examination.model';
import { TApiRoute } from '../../../models/system/ApiRoute.model';

type TGetExaminationsReq = {
  cultureZoneExperimentId: string;
};

type TGetExaminationsRes = IExamination;

const getExaminations: TApiRoute & {
  request: TGetExaminationsReq;
  response: TGetExaminationsRes;
} = {
  url: () => `/api/as-fields/aho/agrochemical-examinations/latestByCultureZoneExperiment`,
  method: 'GET',
  request: {} as TGetExaminationsReq,
  response: {} as TGetExaminationsRes,
  headers: {},
};

export type { TGetExaminationsReq, TGetExaminationsRes };

export { getExaminations };
