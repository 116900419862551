import { FC, useCallback, memo, useMemo, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useWarningBeforeLeavingThePage } from '../../../../../../../common/utils/hooks';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { FullScreen } from '../../../../../../../common/components/ui';
import SpinnerLoader from '../../../../../../../common/components/ui/loaders/SpinnerLoader/SpinnerLoader';
import {
  CONTRACT_FORM_KEY,
  IContractForm,
  createContractFormConfig,
} from '../../forms/config/contractFormConfig';
import { CreateEditContractHelpers } from '../../utils/helpers';
import { CreateEditContractStore } from '../../mobx/stores';
import { CreateEditContractController } from '../../mobx/controllers';
import { formatDateToISO } from '../../../../../../../common/utils/helpers/dates';
import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { IServiceConfig } from '../../../../../../../common/models/service';
import { AccessStore } from '../../../../../../../common/mobx/stores/AccessStore';

import Styled from './CreateEditContract.styles';

const CreateEditContract: FC = () => {
  const {
    contract,
    isLoading,
    clearCreateEditContractStore,
    searchQueries,
    organizationOptionList,
    dropdownPageNumbers,
    setOrganizationOptionList,
    organizationList,
    hasAccessToEditContract,
  } = useStore(CreateEditContractStore);
  const {
    fetchContract,
    createContract,
    changeContract,
    organizationNameSearchQueryHandler,
    onOrganizationListScroll,
    changeDropdownPageNumber,
    createOrganizationOptionList,
    getAccessToEditContract,
  } = useStore(CreateEditContractController);

  const { isAllowToEditContracts } = useStore(AccessStore);

  const { formatContractInfo, createOrganizationLabel } = CreateEditContractHelpers;

  const navigate = useNavigate();
  const { contractId } = useParams<'contractId'>();

  const isEditContract = Boolean(contractId);

  const {
    elements,
    isSubmitDisabled,
    registerForm,
    addOptionList,
    submitForm,
    changeListOfFormValue,
    blockElement,
    addPaginationConfig,
  } = useForm<IContractForm>(CONTRACT_FORM_KEY);

  const { isFormChanged, focusChangedElement } = useCheckChangedForm();
  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  const onSuccessHandler = useCallback(() => {
    navigate(`/${EDashboardRoute.Contracts}`);
  }, []);

  const { contractDate: ContractDate, name: Name, organizationId: OrganizationId } = elements;

  useEffect(() => {
    registerForm(
      createContractFormConfig({
        organizationNameSearchQueryHandler,
        isAllowToEditContracts,
      })
    );
  }, []);

  useEffect(() => {
    if (contract?.organization?.id) {
      getAccessToEditContract(contract.organization.id);
    }
  }, [contract?.organization?.id]);

  const backButtonHandler = useCallback(() => {
    navigate(-1);
  }, []);

  const backButtonText = useMemo(() => {
    return isEditContract && contract ? formatContractInfo(contract) : 'Новый договор';
  }, [isEditContract, contract]);

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    await submitForm(async validatedData => {
      const config: IServiceConfig = {
        actions: {
          success: onSuccessHandler,
        },
      };
      validatedData.contractDate = formatDateToISO(validatedData.contractDate);

      if (isEditContract) {
        delete validatedData.organizationId;

        await changeContract(contractId, validatedData, config);
      } else {
        await createContract(validatedData, config);
      }
    });
  };

  useEffect(() => {
    if (contractId) {
      fetchContract(contractId);
    }
  }, [contractId]);

  useEffect(() => {
    const newOrganizationOptionList = createOrganizationOptionList([...organizationList]);
    addOptionList('organizationId', newOrganizationOptionList);
  }, [organizationList]);

  useEffect(() => {
    if (!contract) return;

    const { contractDate, name, organization } = contract;

    changeListOfFormValue({
      name,
      contractDate,
      organizationId: organization.id,
    });

    addOptionList('organizationId', [
      {
        label: createOrganizationLabel(organization.orgType.OrgType, organization.name),
        value: organization.id,
      },
    ]);

    if (isEditContract) {
      blockElement('organizationId', true);
    }
  }, [contract]);

  useEffect(() => {
    if (hasAccessToEditContract) {
      blockElement('name', false);
      blockElement('contractDate', false);
    }
  }, [hasAccessToEditContract]);

  const onOrganizationScroll = async () => {
    const newOrganizationOptionList = await onOrganizationListScroll(
      searchQueries.organizationSearchQuery
    );

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('organizationId', [...organizationOptionList, ...newOrganizationOptionList]);

    setOrganizationOptionList([...organizationOptionList, ...newOrganizationOptionList]);
  };

  useEffect(() => {
    addPaginationConfig('organizationId', {
      currentPage: dropdownPageNumbers.organizationCurrentPageNumber,
      totalPages: dropdownPageNumbers.organizationTotalPageNumber,
      onScroll: onOrganizationScroll,
      onPageChange: () => changeDropdownPageNumber('organizationCurrentPageNumber'),
    });
  }, [
    dropdownPageNumbers.organizationCurrentPageNumber,
    dropdownPageNumbers.organizationTotalPageNumber,
    onOrganizationScroll,
    changeDropdownPageNumber,
  ]);

  useEffect(
    () => () => {
      clearCreateEditContractStore();
    },
    []
  );

  if (isLoading) {
    return <SpinnerLoader needToHideContent={true} />;
  }

  return (
    <FullScreen backButtonText={backButtonText} backButtonHandler={backButtonHandler}>
      <Styled.Wrapper onSubmit={handleSubmit} data-test-id="create-edit-contract-wrapper">
        <Styled.Container data-test-id="create-edit-contract-container">
          <Styled.Header data-test-id="create-edit-contract-header">
            <Styled.Title data-test-id="create-edit-contract-header-title">
              Общая информация
            </Styled.Title>
          </Styled.Header>
          <Styled.Body data-test-id="create-edit-contract-body">
            <Styled.Subtitle>Договор</Styled.Subtitle>
            <Styled.Row $marginBottom="16px">
              <Styled.RowItem $width="49%">{ContractDate ? <ContractDate /> : null}</Styled.RowItem>
              <Styled.RowItem $width="49%">
                {Name ? <Name data-test-id="create-edit-contract-name" /> : null}
              </Styled.RowItem>
            </Styled.Row>
            <Styled.Row>
              <Styled.RowItem $width="49%">
                {OrganizationId ? (
                  <OrganizationId data-test-id="create-edit-contract-organization-name" />
                ) : null}
              </Styled.RowItem>
            </Styled.Row>
          </Styled.Body>
        </Styled.Container>

        <Styled.Button
          color={'primary'}
          type={'submit'}
          alignment={'center'}
          disabled={isSubmitDisabled}
        >
          {isEditContract ? 'Сохранить договор' : 'Создать договор'}
        </Styled.Button>
      </Styled.Wrapper>
    </FullScreen>
  );
};

CreateEditContract.displayName = 'CreateEditContract';

export default memo(observer(CreateEditContract));
