import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { IAttachedFile } from '../../../../../../../../../../../api/models/as-fields/experiments';
import { AxiosService } from '../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { ExperimentService } from '../../../../../../../../../../common/mobx/services/as-fields';

@provide.singleton()
export class DocumentsService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  constructor() {
    makeAutoObservable(this);
  }

  downloadFile = async (file: IAttachedFile) => {
    try {
      const response = await this.axiosService.api.downloadFile(
        { objectId: file.id },
        { responseType: 'blob' }
      );

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, file.fileName);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateExperimentAttacherFiles = async (experimentId: string, attachedFiles: string[]) => {
    const experiment = await this.experimentService.updateExperimentAttachedFiles({
      experimentId,
      attachedFiles,
    });

    return experiment;
  };
}
