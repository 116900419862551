import { FC, memo, useEffect, useMemo, useState } from 'react';
import _, { isNil } from 'lodash';

import { LinearDropdown } from '../../../common/features/UI';
import {
  EExperimentStatus,
  EExperimentStepStatus,
} from '../../../../api/models/as-fields/experiments';
import { ILinearDropdownOption } from '../../../common/features/UI/LinearDropdown';
import {
  EExperimentApprovalStatus,
  IExperimentChangeStatusAvailability,
} from '../../../../api/models/as-fields/experiments/Experiment/Experiment.model';

import { getAvailableOptionList } from './utils/getAvailableOptionList';
import { experimentStatusMap } from './config/statuses.config';
import { getChangeStatusMethod } from './utils/statusMapper';

interface IStatusDropdownProps {
  value: EExperimentStatus | EExperimentStepStatus;
  onChange?: (
    status: EExperimentStatus | EExperimentStepStatus,
    eventValue?: EExperimentApprovalStatus
  ) => Promise<void>;
  availabilityScheme?: IExperimentChangeStatusAvailability;
  statusScheme?: 'experiment' | 'observer';
}

const StatusDropdown: FC<IStatusDropdownProps> = ({
  value,
  onChange,
  availabilityScheme,
  statusScheme,
}) => {
  const [status, setStatus] = useState(value);
  const [isOptionListListEmpty, setIsOptionListEmpty] = useState(false);

  const isAudit = statusScheme === 'observer';

  useEffect(() => {
    setStatus(value);
  }, [value]);

  const availableOptionList = useMemo<ILinearDropdownOption<string | number>[]>(() => {
    const optionList = getAvailableOptionList(status, availabilityScheme, isAudit);

    if (!optionList?.length) {
      setIsOptionListEmpty(true);
    } else {
      setIsOptionListEmpty(false);
    }

    return _([...(optionList || []), experimentStatusMap?.get(status)])
      .unionBy('value')
      .filter(option => !isNil(option))
      .value();
  }, [status, value, availabilityScheme]);

  return (
    <LinearDropdown
      onChange={(_value: EExperimentStatus) => {
        onChange?.(_value, getChangeStatusMethod(status, _value))
          ?.then(() => {
            setStatus(_value);
          })
          ?.catch(() => {
            setStatus(value);
          });
      }}
      styles={{
        width: '150px',
        dropdownBody: {
          width: '150px',
        },
        dropdownField: {
          justifyContent: 'flex-end',
        },
      }}
      isDisabled={isOptionListListEmpty}
      selectedOptionValue={status}
      dataTestId="status-dropdown"
      optionList={availableOptionList}
      removeSelectedOptionFromList
    />
  );
};

export default memo(StatusDropdown);
