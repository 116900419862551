import {
  ISidebarProps,
  Icon,
  MenuItemContact,
  MenuItemContactIcon,
  Sidebar as SidebarComponent,
  useSidebar,
} from '@farmlink/farmik-ui';
import React, { FC, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SessionController } from '../../../common/mobx/controllers';
import { ProfileStore } from '../../../common/mobx/stores/ProfileStore';
import { useStore } from '../../../common/utils/helpers/mobx';
import { systemHelperCollection } from '../../../common/utils/helpers/system';
import {
  ObservationIcon,
  ContractIcon,
  ExperimentsIcon,
} from '../../containers/Dashboard/assets/iconComponents';
import { EDashboardExternalRoutes, EDashboardRoute } from '../../routes/DashboardRoute';
import { ReactComponent as ExperimentsSvg } from '../../../common/assets/icons/operations.svg';
import { ReactComponent as FieldsSvg } from '../../../common/assets/icons/fields.svg';
import { ReactComponent as TasksSvg } from '../../../common/assets/icons/tasks.svg';

import Styled from './Sidebar.style';

const Sidebar: FC = observer(() => {
  const sessionController = useStore(SessionController);
  const profileStore = useStore(ProfileStore);

  const navigate = useNavigate();

  const { logout } = sessionController;
  const { user } = profileStore;
  const { getActiveFirstLevelRoot, getProfileUrl } = systemHelperCollection;

  const { changeMenuItem } = useSidebar();

  useEffect(() => {
    changeMenuItem(getActiveFirstLevelRoot(location.pathname));
  }, [location.pathname]);

  const externalProductRedirectLink = useMemo(() => {
    const currentStage = window._env_.CURRENT_STAGE;

    const assistanceBaseUrl =
      window._env_[`ASSISTANCE_${currentStage}_URL`] ?? 'http://localhost:3000/';

    return {
      fields: new URL(EDashboardExternalRoutes.Fields, assistanceBaseUrl).href,
      operations: new URL(EDashboardExternalRoutes.Operations, assistanceBaseUrl).href,
      tasks: new URL(EDashboardExternalRoutes.Tasks, assistanceBaseUrl).href,
    };
  }, []);

  const expArgs: Omit<ISidebarProps, 'activeItemId' | 'setActiveItemId'> = {
    headerConfig: {
      type: 'exp',
      logoLink: '/experiments',
    },
    bodyConfig: {
      bodyTop: [
        {
          iconElement: FieldsSvg,
          title: 'Поля',
          type: 'navigation',
          id: 'fields',
          href: externalProductRedirectLink.fields,
          isExternal: true,
          tooltip: {
            short: {
              content: 'Поля',
              position: 'right',
            },
          },
        },
        {
          iconElement: ExperimentsSvg,
          title: 'Операции',
          type: 'navigation',
          id: 'operations',
          href: externalProductRedirectLink.operations,
          isExternal: true,
          tooltip: {
            short: {
              content: 'Операции',
              position: 'right',
            },
          },
        },
        {
          iconElement: TasksSvg,
          title: 'Задачи',
          type: 'navigation',
          id: 'tasks',
          href: externalProductRedirectLink.tasks,
          isExternal: true,
          tooltip: {
            short: {
              content: 'Задачи',
              position: 'right',
            },
          },
        },
        {
          iconElement: () => <Icon icon="sidebarExperiences" size="sidebar" />,
          title: 'Опыты',
          type: 'navigation',
          id: EDashboardRoute.Experiments,
          onSidebarMenuItemClick: () => {
            navigate(`/${EDashboardRoute.Experiments}`);
          },
          tooltip: {
            short: {
              content: 'Опыты',
              position: 'right',
            },
          },
        },
        {
          iconElement: () => <Icon icon="sidebarObservation" size="sidebar" />,
          title: 'Наблюдения',
          type: 'navigation',
          id: EDashboardRoute.Audits,
          onSidebarMenuItemClick: () => {
            navigate(`/${EDashboardRoute.Audits}`);
          },
          tooltip: {
            short: {
              content: 'Наблюдения',
              position: 'right',
            },
          },
        },
        {
          iconElement: () => <Icon icon="sidebarContracts" size="sidebar" />,
          title: 'Договоры',
          type: 'navigation',
          id: EDashboardRoute.Contracts,
          onSidebarMenuItemClick: () => {
            navigate(`/${EDashboardRoute.Contracts}`);
          },
          tooltip: {
            short: {
              content: 'Договоры',
              position: 'right',
            },
          },
        },
        // {
        //   iconElement: 'question',
        //   title: 'Наблюдения',
        //   type: 'navigation',
        //   id: 'watch',
        // },
      ],
      bodyBottom: [
        {
          iconElement: 'question',
          title: 'Руководство',
          id: 'manual',
          type: 'link',
          href: 'https://guide.digitalagro.ru/',
          tooltip: {
            short: {
              content: 'Руководство',
              position: 'right',
            },
          },
        },
        {
          iconElement: 'support',
          title: 'Служба поддержки',
          id: 'support',
          type: 'link',
          href: 'https://digitalagro.atlassian.net/servicedesk/customer/portal/18',
          tooltip: {
            short: {
              content: 'Служба поддержки',
              position: 'right',
            },
          },
        },
        {
          iconElement: MenuItemContactIcon,
          title: MenuItemContact,
          id: 'mobile',
          type: 'expand',
          tooltip: {
            short: {
              content: 'Контакт',
              position: 'right',
            },
          },
        },
      ],
    },

    footerConfig: {
      name: user?.firstName
        ? `${user.firstName} ${user.secondName} ${user.lastName}`
        : user?.email
        ? user?.email.email
        : user?.phone.phoneNumber,
      avatar() {
        return user?.avatar?.downloadUrl ?? 'https://via.placeholder.com/150';
      },
      handleProfileClick: () => {
        window.location.href = getProfileUrl();
      },
      handleLogoutClick: logout,
      isDisplayShortStateNameTooltip: true,
    },
  };

  return (
    <Styled.SidebarWrapper data-test-id="dashboard-sidebar-wrapper">
      <SidebarComponent
        activeItemId={getActiveFirstLevelRoot(location.pathname)}
        {...expArgs}
        data-test-id="dashboard-sidebar"
      />

      <Outlet />
    </Styled.SidebarWrapper>
  );
});

export default Sidebar;
