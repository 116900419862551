import { FC, memo } from 'react';

import { IEconomyData } from '../../../../model/IEconomyData.model';
import { ConsumptionCell as CellContent } from '../../content';

import Styled from './ConsumptionCell.styles';

interface IConsumptionCellComponentProps {
  row: Pick<IEconomyData, 'id' | 'children'>;
}

const ConsumptionCellComponent: FC<IConsumptionCellComponentProps> = ({ row }) => {
  return (
    <>
      {row.children.length > 0 ? (
        <Styled.Wrapper data-test-id={`consumption-cell-wrapper-${row?.id}`}>
          {row.children.map(value => (
            <CellContent key={value.id} consumption={value.concentration} />
          ))}
        </Styled.Wrapper>
      ) : (
        <Styled.NoInfo>—</Styled.NoInfo>
      )}
    </>
  );
};

ConsumptionCellComponent.displayName = 'ConsumptionCellComponent';

export default memo(ConsumptionCellComponent);
