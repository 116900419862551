import styled from 'styled-components';

import { ECommonColor } from '../../../../../../../../../common/assets/styles/colors';

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: ${ECommonColor.GeneralBlack};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledComparisonTabs = {
  Title,
  Wrapper,
};

export default StyledComparisonTabs;
