import { CellComponentWithTooltip } from '../../../../../../../common/components/ui';
import { formatDate } from '../../../../../../../common/utils/helpers/dates';
import { StatusCellComponent } from '../../components';

import { OperationTypeCell } from './components';

const getObservationColumns = () => [
  {
    field: 'operationType',
    type: 'string',
    headerName: 'Тип операции',
    editable: false,
    cellRenderer: rowData => <OperationTypeCell rowData={rowData} />,
    width: 190,
  },
  {
    field: 'name',
    type: 'string',
    headerName: 'Название',
    editable: false,
    width: `${33.3}%`,
    cellRenderer: rowData => (
      <CellComponentWithTooltip cellInfo={rowData.name} id={rowData.id} dataTestId={'name'} />
    ),
  },
  {
    field: 'planStartDate',
    type: 'string',
    headerName: 'Дата',
    editable: false,
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={formatDate(new Date(rowData?.planStartDate))}
        id={rowData.id}
        dataTestId={'name'}
      />
    ),
    width: 78,
  },
  {
    field: 'experimentNumber',
    type: 'string',
    headerName: '№ опыта',
    editable: false,
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.experiment?.code}
        id={rowData.id}
        dataTestId={'name'}
      />
    ),
    width: 70,
  },
  {
    field: 'contragent',
    type: 'string',
    headerName: 'Контрагент',
    editable: false,
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.organization?.name}
        id={rowData.id}
        dataTestId={'name'}
      />
    ),
    width: `${33.3}%`,
  },
  {
    field: 'assignee',
    type: 'string',
    headerName: 'Исполнитель',
    editable: false,
    cellRenderer: rowData => (
      <CellComponentWithTooltip
        cellInfo={rowData?.assignee?.fullName}
        id={rowData.id}
        dataTestId={'name'}
      />
    ),
    width: `${33.3}%`,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Статус',
    editable: false,
    cellRenderer: rowData => <StatusCellComponent status={rowData?.status} />,
    width: 130,
  },
];

export default getObservationColumns;
