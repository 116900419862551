import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 2px;

  & div:first-child {
    padding-bottom: 32px;
  }

  & div:not(:first-child) {
    padding-bottom: 24px;
  }
`;

const StyledActionCell = {
  Wrapper,
};

export default StyledActionCell;
