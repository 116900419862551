import { makeAutoObservable } from 'mobx';
import { isArray } from 'lodash';

import { provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { IFile } from '../../../../../../../../../../api/models/common';

@provide.singleton()
class AuditDocumentsStore {
  private _totalPageNumber = 0;
  private _currentPageNumber = 0;
  private _documentById: Map<string, IFile> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get totalPageNumber() {
    return this._totalPageNumber;
  }

  get currentPageNumber() {
    return this._currentPageNumber;
  }

  get docList() {
    return [...this._documentById.values()];
  }

  setTotalPageNumber = (value: number): void => {
    this._totalPageNumber = value;
  };

  setCurrentPageNumber = (value: number): void => {
    this._currentPageNumber = value;
  };

  setDocList = (
    docList: IFile[],
    options?: {
      isClearPreviousList?: boolean;
    }
  ): void => {
    const previousEntryList = options?.isClearPreviousList ? [] : [...this._documentById.entries()];
    const newCollection: Map<string, IFile> = new Map(previousEntryList);

    if (isArray(docList)) {
      docList.forEach(doc => newCollection.set(doc.id, doc));
    }

    this._documentById = newCollection;
  };

  clearTotalPageNumber = (): void => {
    this._totalPageNumber = 0;
  };

  clearCurrentPageNumber = (): void => {
    this._currentPageNumber = 0;
  };

  clearDocumentsById = (): void => {
    this._documentById.clear();
  };

  clearAuditTasksStore = (): void => {
    this.clearTotalPageNumber();
    this.clearCurrentPageNumber();

    this.clearDocumentsById();
  };
}

export default AuditDocumentsStore;
