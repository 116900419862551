import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { INutritionHistory } from '../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { calculateRoi } from '../../../../../../../utils/calculation';

const numberRegex = new RegExp(/^\d+(,|\.)?(\d{1,2})?$/);

const parseNumber = (value: string, prevValue: string) => {
  if (!value) {
    return undefined;
  }

  if (numberRegex.test(value)) {
    return value;
  }

  return prevValue;
};

const getNumberValue = (value: string | undefined) => {
  if (value) {
    return Number(value.replace(',', '.'));
  }

  return 0;
};

const useCultureCalculation = (
  cultureZone: IExperimentCultureZone,
  experimentSteps: IExperimentStep[],
  nutritionHistories: INutritionHistory[]
) => {
  const [plannedYield, setPlannedYield] = useState(
    cultureZone?.plannedYield ? String(cultureZone?.plannedYield) : ''
  );
  const [plannedCropPrice, setPlannedCropPrice] = useState(
    cultureZone?.plannedCropPrice ? String(cultureZone?.plannedCropPrice) : ''
  );
  const [valuesChanged, setValuesChanged] = useState(false);

  useEffect(() => {
    if (cultureZone) {
      setPlannedCropPrice(
        cultureZone?.plannedCropPrice ? String(cultureZone?.plannedCropPrice) : ''
      );
      setPlannedYield(cultureZone?.plannedYield ? String(cultureZone?.plannedYield) : '');
    }
  }, [cultureZone]);

  useEffect(() => {
    const plannedYieldEquals = isEqual(
      cultureZone?.plannedYield ? String(cultureZone?.plannedYield) : '',
      plannedYield
    );
    const plannedCropPriceEquals = isEqual(
      cultureZone?.plannedCropPrice ? String(cultureZone?.plannedCropPrice) : '',
      plannedCropPrice
    );

    if (plannedYieldEquals && plannedCropPriceEquals) {
      setValuesChanged(false);
    } else {
      setValuesChanged(true);
    }
  }, [plannedCropPrice, plannedYield, cultureZone]);

  if (!cultureZone) {
    return null;
  }

  const currentNutritionHistory = nutritionHistories.filter(
    item => item.cultureZone.id === cultureZone.cultureZone.id
  );

  const fertilizerCost = currentNutritionHistory.reduce((acc, nutritionHistory) => {
    const nutritionHistoriesCost = nutritionHistory.items.reduce(
      (innerAcc, item) => innerAcc + (item?.concentration ?? 0) * (item?.pricePerUnit ?? 0),
      0
    );

    return acc + nutritionHistoriesCost;
  }, 0);

  const jobCost = experimentSteps.reduce((acc, experimentStep) => {
    // eslint-disable-next-line spaced-comment
    //@ts-ignore
    return acc + (experimentStep?.operation?.servicePricePerArea ?? 0);
  }, 0);

  const summaryCost = fertilizerCost + jobCost;

  const revenue = getNumberValue(plannedCropPrice) * getNumberValue(plannedYield);

  const finishedCost = (summaryCost / revenue) * 100;

  const unitCost = summaryCost / getNumberValue(plannedYield);

  const onChangePlannedYield = (value: string) => {
    setPlannedYield(prevValue => parseNumber(value, prevValue));
  };

  const onChangePlannedCropPrice = (value: string) => {
    setPlannedCropPrice(prevValue => parseNumber(value, prevValue));
  };

  return {
    fertilizerCost,
    jobCost,
    summaryCost,
    plannedCropPrice,
    plannedYield,
    revenue,
    finishedCost,
    unitCost,
    valuesChanged,
    onChangePlannedYield,
    onChangePlannedCropPrice,
    setValuesChanged,
  };
};

export const useCalculation = ({
  cultureZones,
  activeCultureZone,
  experimentSteps,
  experimentStepListOfControlZone,
  nutritionHistories,
  nutritionHistoryListOfControlZone,
}: {
  cultureZones: IExperimentCultureZone[];
  activeCultureZone: IExperimentCultureZone;
  experimentSteps: IExperimentStep[];
  experimentStepListOfControlZone: IExperimentStep[];
  nutritionHistories: INutritionHistory[];
  nutritionHistoryListOfControlZone: INutritionHistory[];
}) => {
  const controlCultureZone = cultureZones.find(
    cultureZone => cultureZone.type === EExperimentCultureZoneType.Control
  );

  const controlZone = useCultureCalculation(
    controlCultureZone,
    experimentStepListOfControlZone,
    nutritionHistoryListOfControlZone
  );

  const currentZone = useCultureCalculation(activeCultureZone, experimentSteps, nutritionHistories);

  if (!controlZone || !currentZone) {
    return { currentZone: null, changed: null };
  }

  const costDiff = currentZone.summaryCost - controlZone.summaryCost;
  const revenueChanged = currentZone.revenue - controlZone.revenue;

  const plannedYieldChanged =
    getNumberValue(currentZone.plannedYield) - getNumberValue(controlZone.plannedYield);

  const roi = Number(
    calculateRoi(
      currentZone.revenue,
      controlZone.revenue,
      currentZone.summaryCost,
      controlZone.summaryCost
    )
  );

  const currentRoi = !Number.isNaN(roi) ? roi : 0;

  const minimalIncrease = costDiff / getNumberValue(currentZone.plannedCropPrice);

  const cashDiff = currentZone.revenue - controlZone.revenue;

  const changed = {
    currentRoi,
    revenueChanged,
    plannedYieldChanged,
    minimalIncrease,
    costDiff,
    cashDiff,
  };

  return {
    currentZone,
    changed,
    valuesChanged: currentZone.valuesChanged,
    setValuesChanged: currentZone.setValuesChanged,
  };
};
