import moment from 'moment';

import { IExperimentStep } from '../../../../../../../../api/models/as-fields/experiments';
import { EClearedPaths } from '../constants';

const formatDate = (date: string): string => moment(date).format('DD.MM.YYYY');
const formatName = (name?: string): string => name || 'Какое-то наблюдение';
const formatText = (name: string, date: string): string => `${name} от ${date}`;

const createBackButtonText = (audit: IExperimentStep, clearedCurrentPath: string): string => {
  if (clearedCurrentPath === EClearedPaths.editAudit) {
    return 'Редактирование наблюдения';
  } else if (audit) {
    return formatText(formatName(audit?.name), formatDate(audit.planStartDate));
  } else {
    return 'Новое наблюдение';
  }
};

const AuditHelpers = { createBackButtonText };

export default AuditHelpers;
