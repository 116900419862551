import styled from 'styled-components';

import { ReactComponent as InfoSvg } from './assets/icons/info.svg';

const InfoIcon = styled(InfoSvg)`
  width: 16px;
  height: 16px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
`;

const TooltipWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 5px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
`;

const StyledExecutionContainerHeader = {
  InfoIcon,
  Description,
  TooltipWrapper,
  Title,
  TitleWrapper,
  Wrapper,
};

export default StyledExecutionContainerHeader;
