import { AxiosError } from 'axios';

import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import {
  TChangeCultureZoneExperimentListReq,
  TChangeCultureZoneExperimentReq,
  TCreateCultureZoneExperimentReq,
  TDeleteCultureZoneExperimentReq,
} from '../../../../../../api';
import { IExperimentCultureZone } from '../../../../../../api/models/as-fields/experiments';
import { IShowErrorModal } from '../../../../utils/hooks/useErrorModal';

@provide.singleton()
class CultureZoneExperimentService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  changeCultureZoneExperimentList = async (
    experimentId: string,
    payload: TChangeCultureZoneExperimentListReq,
    showErrorHandler?: IShowErrorModal
  ): Promise<IExperimentCultureZone[]> => {
    const { changeCultureZoneExperimentList } = this.axiosService.api;

    try {
      const changedZoneList = await changeCultureZoneExperimentList(payload, {
        path: `${experimentId}/cultureZones`,
      });

      return changedZoneList;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      showErrorHandler?.(e as AxiosError);
    }
  };

  changeCultureZoneExperiment = async (
    payload: TChangeCultureZoneExperimentReq
  ): Promise<IExperimentCultureZone> => {
    const { changeCultureZoneExperiment } = this.axiosService.api;

    try {
      const changedZone = await changeCultureZoneExperiment(payload, { omit: ['id'] });

      return changedZone;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createCultureZoneExperiment = async (
    payload: TCreateCultureZoneExperimentReq
  ): Promise<IExperimentCultureZone> => {
    const { createCultureZoneExperiment } = this.axiosService.api;

    try {
      const createdZone = await createCultureZoneExperiment(payload);

      return createdZone;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  deleteCultureZoneExperiment = async (
    payload: TDeleteCultureZoneExperimentReq
  ): Promise<boolean> => {
    const { deleteCultureZoneExperiment } = this.axiosService.api;

    try {
      await deleteCultureZoneExperiment(payload);

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default CultureZoneExperimentService;
