import { EModalType, TModalConfig } from '@farmlink/farmik-ui';

import { CopyAhoForm } from '../../components/CopyAhoForm';
import { CreateAgrochemicalModal } from '../../components/CreateAgrochemicalModal';

export const agrochemicalModalConfig: TModalConfig[] = [
  {
    name: 'createAxoModal',
    type: EModalType.Custom,
    title: 'Параметры почвы',
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 24px 0',
      },
    },
    styledProps: {
      $size: '600px',
      $height: 'calc(100vh - 32px)',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    id: 'createAxoModal',
    children: () => <CreateAgrochemicalModal formType="add" />,
  },
  {
    name: 'editAxoModal',
    type: EModalType.Custom,
    title: 'Параметры почвы',
    styles: {
      title: {
        $size: 'large',
        $margin: '0 auto 24px 0',
      },
    },
    styledProps: {
      $size: '600px',
      $height: 'calc(100vh - 32px)',
      $fullHeight: true,
      $modalPaddings: '40px 60px',
    },
    id: 'editAxoModal',
    children: () => <CreateAgrochemicalModal formType="edit" />,
  },
  {
    name: 'copyAhoModal',
    type: EModalType.Custom,
    title: '',
    id: 'copyAhoModal',
    children: CopyAhoForm,
    dataTestId: 'calculation-copy-aho-modal',
  },
];
