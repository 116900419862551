import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import {
  ENotificationType,
  ENotificatorTheme,
  useModal,
  useNotificator,
} from '@farmlink/farmik-ui';

import { ExperimentStepFooterContainer } from '../../containers';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import useExperimentsParams from '../../../../hooks/useExperimentsParams/useExperimentsParams';
import {
  addPlanInventoryValuesModalConfig,
  addTechoperationModalConfig,
} from '../calculation/containers/Calculation/config/modals/techOperationModalList';
import { useDataTestIdV2 } from '../../../../../../../common/utils/hooks/locators';
import {
  useErrorModal,
  useWarningBeforeLeavingThePage,
} from '../../../../../../../common/utils/hooks';
import { useSelectedExp, useZonesPlugResolver } from '../../hooks';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';
import { EContextualParentPath } from '../../../../../../../common/constants/contextualPath';

import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from './constants/titles';
import Styled from './Execution.styles';
import { ExecutionController } from './mobx/controllers';
import { ExecutionStore } from './mobx/stores';
import { ExecutionCalculations, ExecutionSteps, ExecutionZonesCarousel } from './containers';

const Execution: FC = () => {
  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  useWarningBeforeLeavingThePage(executionStore.isEdited);
  const errorModalActions = useErrorModal();

  const modalActions = useModal();
  const params = useExperimentsParams();

  const selectedExp = useSelectedExp({ isClearOnUnmount: true });
  const isExperimentCreating = params.experimentId === 'create';

  const {
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExp?.id, NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE);
  usePageContextualHelp(EContextualParentPath.ExperimentsExperimentExecution);

  const { setNotification } = useNotificator();

  useEffect(() => {
    modalActions.registerModalList(
      [addPlanInventoryValuesModalConfig, addTechoperationModalConfig],
      'execution'
    );

    return () => {
      executionController.clearStore();
    };
  }, []);

  useEffect(() => {
    if (!isExperimentCreating && executionStore.isPageLoading) {
      executionController.clearStore();
      executionController.initiateTables(params.experimentId);
    }
  }, [params.experimentId, executionStore.isPageLoading]);

  const handleSaveClick = useCallback(() => {
    if (isExperimentCreating) {
      return;
    }

    if (executionController.hasInvalidAttributes) {
      if (executionController.hasInvalidAttributesInSelectedZone) {
        const invalidRowId = executionController.invalidRowIdBySelectedZone;

        executionStore.setUnhandledInvalidRowId(invalidRowId);
      } else {
        const [invalidRowId] = executionStore.invalidRowIdList;

        const zoneId = executionController.getZoneIdByInvalidRowId(invalidRowId);

        executionStore.setSelectedZoneId(zoneId);
        executionStore.setUnhandledInvalidRowId(invalidRowId);
      }

      executionStore.setScrollStep(1);

      return;
    }

    executionController.saveFactTableData(params.experimentId, {
      success: () => {
        setNotification({
          message: 'Данные успешно сохранены',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
        executionController.enablePageLoading();
      },
      rejected: errorModalActions.showErrorModal,
    });
  }, [
    errorModalActions.showErrorModal,
    params.experimentId,
    executionStore.invalidRowIdList,
    executionStore.selectedZoneId,
  ]);

  const getDataTestId = useDataTestIdV2('execution');

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Content {...getDataTestId('content')}>
        {!executionStore.isPageLoading ? <ExecutionZonesCarousel /> : null}

        <ExecutionSteps />

        <ExecutionCalculations />
      </Styled.Content>

      <ExperimentStepFooterContainer
        stepName={'execution'}
        successTitle={'Сохранить'}
        isShowBackButton={false}
        isContinueButtonDisabled={
          isExperimentCreating || !executionController.isZonesChanged || !executionStore.isEdited
        }
        onContinueClick={handleSaveClick}
      />
    </Styled.Wrapper>
  );
};

Execution.displayName = 'Execution';

export default observer(Execution);
