import {
  EExperimentCommentTypeEvent,
  IExperimentComment,
} from '../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';
import {
  EMessageType,
  IChatMessage,
} from '../../../../../../../../common/features/ChatJournal/models';

import { getMessageStyle } from './getMessageStyle';
import { getMessageText } from './getMessageText';

const parseEventRecordToMessage = (
  event: IExperimentComment<EExperimentCommentTypeEvent>,
  isAuthor?: boolean
): IChatMessage => {
  return {
    author: event.createdBy.fullName,
    message: getMessageText(event),
    sendingDate: new Date(event.creationDate),
    messageStyle: getMessageStyle(event),
    messageType:
      event.typeEvent === EExperimentCommentTypeEvent.COMMENT
        ? EMessageType.Message
        : EMessageType.Notification,
    isAuthor,
  };
};

export { parseEventRecordToMessage };
