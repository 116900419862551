import { useRef } from 'react';
import {
  ButtonLink,
  ENotificationType,
  ENotificatorTheme,
  Typography,
  useNotificator,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useFileUpload } from '../../../../../../../../../common/hooks/useFileUpload';
import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { DocumentsStore } from '../../mobx/stores';
import { DocumentsController } from '../../mobx/controllers';
import { EExperimentStatus } from '../../../../../../../../../../api/models/as-fields/experiments';

import Styled from './DocumentsTitle.styles';

export const DocumentsTitle = observer(() => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleFileChange } = useFileUpload(inputRef, { maxSize: 1e8 });

  const { attachedFiles, experimentStatus } = useStore(DocumentsStore);

  const { addExperimentFile } = useStore(DocumentsController);

  const { setNotification } = useNotificator();

  const addFileToExperiment = (fileId: string) => {
    addExperimentFile(fileId);
  };

  const uploadNewDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e)
      .then(file => {
        addFileToExperiment(file.id);
        setNotification({
          message: 'Документ успешно загружен',
          style: {
            placement: 'top-center',
            type: ENotificationType.Success,
            theme: ENotificatorTheme.Dark,
          },
        });
      })
      .catch((err: Error) => {
        setNotification({
          message: err.message,
          style: {
            placement: 'top-center',
            type: ENotificationType.Warning,
            theme: ENotificatorTheme.Dark,
          },
        });
      });
  };

  const handleUploadClick = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = null;
    inputRef.current?.click();
  };

  const isShowUploadButton = experimentStatus !== EExperimentStatus.FullCompleted;

  return (
    <Styled.Wrapper>
      <Typography variant="h5" data-test-id="exp-documents-title">
        Документы
      </Typography>
      {isShowUploadButton ? (
        <div>
          {attachedFiles.length > 0 && (
            <ButtonLink
              color={'accent'}
              onClick={handleUploadClick}
              dataTestId={'exp-documents-upload-file-button'}
            >
              Загрузить документ
            </ButtonLink>
          )}
          <Styled.FileInput type="file" ref={inputRef} onChange={uploadNewDocument} />
        </div>
      ) : null}
    </Styled.Wrapper>
  );
});
