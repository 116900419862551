import { FC, MouseEvent, useCallback } from 'react';
import { AutoTooltip, useDataTestId, useShowTooltip } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ExecutionController } from '../../mobx/controllers';
import { ExecutionTableDeleteIcon } from '../ExecutionTableDeleteIcon';
import { useWarningBeforeDeleting } from '../../../../../../../../../common/utils/hooks';

import Styled from './ExecutionTableTitleRow.styles';

interface IProps {
  rowsGroupId: string;
  name: string;
  stepId: string;
  fertilizerId: string;
  isShowDeleteIcon: boolean;
  dataTestId: string;
}

const ExecutionTableTitleRow: FC<IProps> = ({
  rowsGroupId,
  name,
  stepId,
  fertilizerId,
  isShowDeleteIcon,
  dataTestId,
}) => {
  const executionController = useStore(ExecutionController);

  const warningBeforeDeletingActions = useWarningBeforeDeleting();

  const { ref: titleRef, isShowTooltip: isShowTitleTooltip } = useShowTooltip<HTMLDivElement>();

  const handleDeleteIconClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    warningBeforeDeletingActions.showWarningBeforeDeleting(`ТМЦ: «${name}»`, () => {
      executionController.deleteNutritionHistoryItem(rowsGroupId, stepId, fertilizerId);
    });
  }, []);

  const getDataTestId = useDataTestId(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <AutoTooltip content={name} position={'bottom'} disabled={!isShowTitleTooltip}>
        <Styled.Title ref={titleRef} {...getDataTestId('title')}>
          {name}
        </Styled.Title>
      </AutoTooltip>

      {isShowDeleteIcon ? (
        <ExecutionTableDeleteIcon
          onClick={handleDeleteIconClick}
          dataTestId={getDataTestId('delete-button')['data-test-id']}
        />
      ) : null}
    </Styled.Wrapper>
  );
};

ExecutionTableTitleRow.displayName = 'ExecutionTableTitleRow';

export default observer(ExecutionTableTitleRow);
