import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import fullscreenIcon from '../../assets/icons/fullscreen.svg';

const Wrapper = styled.div`
  width: 280px;
  height: 88px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.borderGray};
  cursor: pointer;

  :hover {
    background-color: ${Colors.secondaryGray};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const Date = styled(Text)`
  color: ${Colors.darkGrey};
`;

const Icon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${fullscreenIcon});
`;

const Title = styled(Text)`
  margin-right: 6px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${Colors.generalBlack};
`;

const StyledSliderItem = { Wrapper, FlexContainer, Date, Icon, Title };

export default StyledSliderItem;
