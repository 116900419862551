import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $isScrollEnabled: boolean }>`
  width: 100%;

  scroll-behavior: smooth;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 204px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ${({ $isScrollEnabled }) =>
    $isScrollEnabled
      ? css`
          overflow-x: scroll;
        `
      : css`
          overflow-x: hidden;
        `}
`;

const StyledSlider = {
  Wrapper,
};

export default StyledSlider;
