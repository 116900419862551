import _ from 'lodash';
import { observer } from 'mobx-react';
import { FC, memo, useCallback, useMemo } from 'react';

import { IExperimentStep } from '../../../../../../../api/models/as-fields/experiments';
import { INutritionHistory } from '../../../../../../../api/models/as-fields/plan/NutrationHistory';
import { SliderContainer } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/containers';
import { activeSubstances } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/utils/const';
import { TActiveSubstances } from '../../../model/IActiveSubstances.model';
import { IActiveSubstancesData } from '../../../model/IActiveSubstancesData.model';
import { ActiveSubstancesRow } from '../content/ActiveSubstanceRow';

import Styled from './ActiveSubstancesCell.styles';

interface IActiveSubstancesCellComponent {
  row: IActiveSubstancesData;
  nutritionHistories: INutritionHistory[];
  experimentSteps: IExperimentStep[];
}

const ActiveSubstancesCellComponent: FC<IActiveSubstancesCellComponent> = ({
  row,
  nutritionHistories,
  experimentSteps,
}) => {
  const calculateTotalSubstanceValue = useCallback<(key: string) => number>(
    key => {
      const totalSubstanceValue = row.children.reduce<number>((acc, nutritionItem) => {
        const validateNumber = Number(nutritionItem.fertilizer.attrs[key]) || 0;

        return acc + validateNumber * nutritionItem.concentration;
      }, 0);

      return totalSubstanceValue;
    },
    [row.children]
  );

  const activeSubstanceColumns = useMemo(
    () =>
      nutritionHistories.reduce<TActiveSubstances>((acc, nutritionHistory) => {
        nutritionHistory.items.forEach(item => {
          const currentActiveSubstance = _.omit(item?.fertilizer?.attrs, 'price', 'cost');

          Object.keys(currentActiveSubstance).forEach(key => {
            const attrName = key.replace('substance.', '');

            if (activeSubstances[attrName]) {
              acc.set(attrName, {
                substanceName: activeSubstances[attrName],
                substanceFullName: key,
                totalSubstanceValue: calculateTotalSubstanceValue(key),
              });
            }
          });
        });

        return acc;
      }, new Map()),
    [nutritionHistories, experimentSteps, row.children]
  );

  const activeSubstancesValues = useMemo(
    () => [...activeSubstanceColumns.entries()],
    [activeSubstanceColumns]
  );

  const sortedActiveSubstanceColumns = useMemo(
    (): TActiveSubstances =>
      new Map(
        activeSubstancesValues.sort(([key1], [key2]) => {
          const activeSubstancesList = Object.keys(activeSubstances);

          if (activeSubstancesList.indexOf(key1) < activeSubstancesList.indexOf(key2)) {
            return -1;
          } else {
            return 1;
          }
        })
      ),
    [activeSubstancesValues]
  );

  const sortedActiveSubstancesValues = useMemo(
    () => [...sortedActiveSubstanceColumns.entries()],
    [sortedActiveSubstanceColumns]
  );

  return (
    <Styled.Wrapper data-test-id={`dosage-cell-wrapper-${row?.id}`}>
      {row.children.length > 0 ? (
        <Styled.ContentWrapper>
          <ActiveSubstancesRow
            activeSubstancesNames={sortedActiveSubstanceColumns}
            paddingBottom={32}
            isTitleRow={true}
          />
          {row.children.map(nutritionHistoryItem => (
            <ActiveSubstancesRow
              key={nutritionHistoryItem.id}
              activeSubstancesNames={sortedActiveSubstanceColumns}
              nutritionHistoryItem={nutritionHistoryItem}
            />
          ))}
        </Styled.ContentWrapper>
      ) : (
        <Styled.NoInfoWrapper>
          <SliderContainer>
            {sortedActiveSubstancesValues.map(item => {
              const [activeSubstanceKey] = item;

              return (
                <Styled.Item data-test-id={`dosage-cell-item-${row?.id}`} key={activeSubstanceKey}>
                  —
                </Styled.Item>
              );
            })}
          </SliderContainer>
        </Styled.NoInfoWrapper>
      )}
    </Styled.Wrapper>
  );
};

ActiveSubstancesCellComponent.displayName = 'ActiveSubstancesCellComponent';

export default memo(observer(ActiveSubstancesCellComponent));
