import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  gap: 13px;
`;

const IconButton = styled.button<{ $icon: string }>`
  background-image: url(${({ $icon }) => $icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
`;

const Styled = {
  ButtonGroup,
  IconButton,
};

export default Styled;
