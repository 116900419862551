import { flatten } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { NutritionHistoryService } from '../../../../../../../../../../../common/mobx/services/as-fields';
import NutritionHistoryItemService from '../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { toNumber } from '../../../../../../../../../../../common/utils/helpers/numbers';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IInventoryValuesForm } from '../../configs/forms/inventoryValuesForm';
import { HistoryStore } from '../stores/History.store';
import { InventoryValueStore } from '../../../../../calculation/containers/Calculation/mobx/store/Calculation/InventoryValue.store';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

import { HistoryController } from './History.contorller';

@provide.singleton()
export class InventoryValueController {
  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(HistoryController)
  historyController: HistoryController;

  @lazyInject(HistoryStore)
  historyStore: HistoryStore;

  @lazyInject(InventoryValueStore)
  inventoryValueStore: InventoryValueStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchFertilizers = async (searchQuery: string, page?: number) => {
    const fertilizers = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'fertilizers',
        attrs: {
          forAgriculturalUse: true,
        },
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return fertilizers;
  };

  getInventoryValueOptionList = async (searchQuery: string, page?: number) => {
    const { content, totalPages } = await this.fetchFertilizers(searchQuery, page);

    const currentExperimentStep = this.historyStore.currentTechOperation;
    const currentNutritionHistory = this.historyStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const currentFertilizerIds =
      currentNutritionHistory?.items?.map(item => item.fertilizer.id) ?? [];

    const currentFertilizers = content.filter(item => !currentFertilizerIds.includes(item.id));

    return {
      fertilizerList: [createDictionaryEntitySelectOptionList(currentFertilizers)],
      totalPages,
    };
  };

  createInventoryValue = async (
    form: IInventoryValuesForm,
    experimentStep: IExperimentStep,
    cultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    await this.nutritionHistoryItemService.createNutritionItemHistory({
      concentration: toNumber(form.dosage),
      cultureZoneExperimentId: cultureZone.id,
      experimentStepId: experimentStep.id,
      fertilizerId: form.nameValue,
      isFact: true,
    });

    await this.historyController.fetchNutritionHistories({
      experimentId,
      cultureZoneExperimentId: cultureZone.id,
      experimentStepType: EExperimentStepType.NutritionHistory,
    });
  };

  fertilizersSearchQueryHandler = async (searchQuery: string): Promise<ISelectOption[]> => {
    const {
      setFertilizerCurrentPage,
      setFertilizerTotalPages,
      setFertilizeSearchQuery,
      setFertilizeOptionList,
    } = this.inventoryValueStore;

    const { fertilizerList, totalPages } = await this.getInventoryValueOptionList(searchQuery, 0);

    const [fertilizers] = fertilizerList;

    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setFertilizerCurrentPage(0);

    setFertilizerTotalPages(totalPages);

    setFertilizeOptionList(fertilizers);

    setFertilizeSearchQuery(searchQuery);

    return flatten(fertilizerList);
  };

  onFertilizeListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { fertilizerCurrentPage } = this.inventoryValueStore;

    const { fertilizerList } = await this.getInventoryValueOptionList(
      searchQuery,
      fertilizerCurrentPage
    );

    return flatten(fertilizerList);
  };

  updateInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string
  ) => {
    await this.nutritionHistoryItemService.updateNutritionItemHistory({
      nutritionHistoryItemId: id,
      concentration: toNumber(form.dosage),
      fertilizerId: form?.nameValue,
    });
    await this.historyController.fetchNutritionHistories({
      experimentId,
      cultureZoneExperimentId: cultureZone.id,
      experimentStepType: EExperimentStepType.NutritionHistory,
    });
  };

  changeFertilizerPageNumber = (): void => {
    const { fertilizerCurrentPage, setFertilizerCurrentPage } = this.inventoryValueStore;

    setFertilizerCurrentPage(fertilizerCurrentPage + 1);
  };
}
