import { isArray } from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';
import { AuditTasksStore } from '../store';
import { TasksService } from '../../../../../../../../../common/mobx/services/as-fields';
import { TGetTaskListReq } from '../../../../../../../../../../api';
import { AuditStore } from '../../../../mobx/store';
import { TasksListConfigsService } from '../service';
import { TableBuilderController } from '../../../../../../../../../common/features/TableBuilder/mobx/controllers';

@provide.transient()
class AuditTasksController {
  @lazyInject(AuditStore)
  protected auditStore: AuditStore;

  @lazyInject(AuditTasksStore)
  protected auditTasksStore: AuditTasksStore;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(TasksListConfigsService)
  protected configService: TasksListConfigsService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  initiateTable = (): void => {
    const config = this.configService.createConfig();

    this.tableBuilderController.initiateTable(config);
  };

  fetchTaskList = async (): Promise<void> => {
    const { selectedAudit, selectedExp } = this.auditStore;
    const { currentPageNumber, setTotalPageNumber, setTaskList } = this.auditTasksStore;

    const { getTaskList } = this.tasksService;

    if (!selectedAudit || !selectedExp) {
      return;
    }

    const payload: TGetTaskListReq = {
      experimentId: selectedExp.id,
      seasonYear: selectedExp.seasonYear,
      organizationId: selectedExp.organization.id,
      experimentStepId: selectedAudit.id,
      page: currentPageNumber,
      size: 25,
    };

    const response = await getTaskList(payload);

    if (isArray(response?.content)) {
      setTaskList(response.content);

      setTotalPageNumber(response?.totalPages ?? 0);
    }
  };

  changePage = (): void => {
    const { currentPageNumber, setCurrentPageNumber } = this.auditTasksStore;

    setCurrentPageNumber(currentPageNumber + 1);
  };
}

export default AuditTasksController;
