import styled, { css } from 'styled-components';

import { TTableBuilderStylePreset as TStylePreset } from './types/styles';

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;

  background-color: white;

  // Для того, чтобы не перекрывался тултип.
  z-index: 2;
`;

const ContentWrapper = styled.div`
  background-color: white;
`;

const DefaultPlug = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 16px;

  background-color: white;
`;

const getStylesByPreset = (preset: TStylePreset) => {
  const primaryPreset = css`
    border-radius: 16px 16px 0 0;

    & ${HeaderWrapper} {
      padding: 20px 24px 0 24px;
      border-radius: 16px 16px 0 0;
    }

    & ${ContentWrapper} {
      padding: 0 24px 20px 24px;
      border-radius: 0 0 16px 16px;
    }
  `;

  const clearedPreset = css``;

  switch (preset) {
    case 'primary':
      return primaryPreset;
    case 'cleared':
      return clearedPreset;
    default:
      return primaryPreset;
  }
};

const Wrapper = styled.div<{ $preset?: TStylePreset }>`
  position: relative;
  height: 100%;

  overflow-y: auto;

  ${({ $preset }) => getStylesByPreset($preset)}
`;

const StyledTableBuilder = {
  HeaderWrapper,
  ContentWrapper,
  DefaultPlug,
  Wrapper,
};

export default StyledTableBuilder;
