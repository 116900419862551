import { FC, PropsWithChildren, MouseEvent, memo, useCallback } from 'react';

import { useDataTestId } from '../../../utils/hooks/locators';

import Styled from './Overlay.styles';

const DEFAULT_DATA_TEST_ID = 'overlay';

export interface IOverlayProps {
  onClose?: () => void;
  dataTestId?: string;
}

const Overlay: FC<PropsWithChildren<IOverlayProps>> = ({ onClose, dataTestId, children }) => {
  const getDataTestId = useDataTestId(dataTestId || DEFAULT_DATA_TEST_ID);

  const handleWrapperClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleContentClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <Styled.Wrapper onClick={handleWrapperClick} {...getDataTestId('wrapper')}>
      <Styled.Content onClick={handleContentClick} {...getDataTestId('content')}>
        {children}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

Overlay.displayName = 'Overlay';

export default memo(Overlay);
