import { lazyInject, provide } from '../../../../utils/helpers/mobx';
import { AxiosService } from '../../axios/AxiosService';
import { TUpdateNutritionHistoryItemReq } from '../../../../../../api';
import { TApiRequest, TApiResponse } from '../../axios/AxiosService/Axios.service.types';

@provide.singleton()
class NutritionHistoryItemService {
  @lazyInject(AxiosService)
  protected axiosService: AxiosService;

  deleteNutritionItemHistory = async (stepId: string, fertilizerId: string): Promise<boolean> => {
    try {
      await this.axiosService.api.deleteNutritionHistoryItem({
        experimentStepId: stepId,
        nutritionHistoryItemId: fertilizerId,
      });

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  createNutritionItemHistory = async (
    payload: TApiRequest<'createNutritionHistoryItem'>
  ): Promise<TApiResponse<'createNutritionHistoryItem'>> => {
    try {
      const response = await this.axiosService.api.createNutritionHistoryItem(payload);

      return response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  updateNutritionItemHistory = async (payload: TUpdateNutritionHistoryItemReq) => {
    try {
      await this.axiosService.api.updateNutritionHistoryItem(payload, {
        omit: ['nutritionHistoryItemId'],
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
}

export default NutritionHistoryItemService;
