import 'leaflet.gridlayer.googlemutant';
import { makeAutoObservable } from 'mobx';
import L, { gridLayer } from 'leaflet';
import _ from 'lodash';

import { provide } from '../../../../../utils/helpers/mobx';

interface IPrevSelectedPolygonData {
  id: number;
  polygon: L.Polygon;
  style: L.PathOptions;
}

@provide.singleton()
class MapStore {
  private _instance: L.Map | null = null;

  private _prevSelectedPolygonData: IPrevSelectedPolygonData | null = null;

  private _idToPolygon: Map<number, L.Polygon> = new Map<number, L.Polygon>();

  constructor() {
    makeAutoObservable(this);
  }

  get instance() {
    return this._instance;
  }

  get prevSelectedPolygonData() {
    return this._prevSelectedPolygonData;
  }

  get polygonList() {
    return Array.from(this._idToPolygon.values());
  }

  getPolygon = (id: number): L.Polygon => {
    return this._idToPolygon.get(id);
  };

  setInstance = (instance: L.Map): void => {
    if (!instance) {
      return;
    }

    // @ts-ignore
    window.leafletMap = instance;
    this._instance = instance;

    // @ts-ignore
    gridLayer.googleMutant({ type: 'hybrid' }).addTo(instance);
    instance.attributionControl.setPrefix('');
  };

  setPrevSelectedPolygonData = (data: IPrevSelectedPolygonData): void => {
    this._prevSelectedPolygonData = data;
  };

  setIdToPolygon = (polygonList: L.Polygon[]): void => {
    const newIdToPolygon: Map<number, L.Polygon> = new Map<number, L.Polygon>();

    if (_.isArray(polygonList)) {
      // @ts-ignore
      polygonList.forEach(polygon => newIdToPolygon.set(polygon._leaflet_id, polygon));
    }

    this._idToPolygon = newIdToPolygon;
  };

  clearInstance = (): void => {
    this._instance = null;
  };

  clearPrevSelectedPolygonData = (): void => {
    this._prevSelectedPolygonData = null;
  };

  clearIdToPolygon = (): void => {
    this._idToPolygon.clear();
  };

  clearStore = (): void => {
    this.clearInstance();
    this.clearPrevSelectedPolygonData();

    this.clearIdToPolygon();
  };
}

export default MapStore;
