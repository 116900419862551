enum EDashboardRoute {
  Experiments = 'experiments',
  Audits = 'audits',
  Contracts = 'contracts',
}

export enum EDashboardExternalRoutes {
  Fields = '',
  Operations = 'operations',
  Tasks = 'tasks',
}

export default EDashboardRoute;
