import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 415px;
  border-radius: 16px;
  margin-bottom: 16px;
`;

const StyledZonesMap = {
  Wrapper,
};

export default StyledZonesMap;
