import { TApiRoute } from '../../../../models/system/ApiRoute.model';
import { IMonitoringReportData } from '../../../../models/as-fields/experiments/MonitoringReportData/MonitoringReportData';

type TGetMonitoringReportDataReq = { id: string };

type TGetMonitoringReportDataRes = IMonitoringReportData;

const getMonitoringReportData: TApiRoute & {
  request: TGetMonitoringReportDataReq;
  response: TGetMonitoringReportDataRes;
} = {
  url: ({ id }) => `/api/as-fields/experimentSteps/${id}/generateMonitoringReportData`,
  method: 'GET',
  request: {} as TGetMonitoringReportDataReq,
  response: {} as TGetMonitoringReportDataRes,
  headers: {},
};

export type { TGetMonitoringReportDataReq, TGetMonitoringReportDataRes };

export { getMonitoringReportData };
