import { Typography } from '@farmlink/farmik-ui';
import { FC, ReactNode } from 'react';

import { IExperiment } from '../../../../../../../../../../api/models/as-fields/experiments';
import { StatusCellComponent } from '../../../../../../containers/Experiments/components';

import Styled from './BackButtonContent.style';

interface IBackButtonContentProps {
  selectedExp: IExperiment;
  /**
   * Иконка контекстной справки.
   */
  ContextualHelpIcon?: ReactNode;
}

const BackButtonContent: FC<IBackButtonContentProps> = ({ selectedExp, ContextualHelpIcon }) => {
  return (
    <Styled.Wrapper>
      <Styled.MainInfoWrapper>
        <Typography variant="h4">{selectedExp.name}</Typography>

        {ContextualHelpIcon ? (
          <Styled.ContextualIconWrapper>{ContextualHelpIcon}</Styled.ContextualIconWrapper>
        ) : null}

        <Styled.StatusComponentWrapper>
          <StatusCellComponent status={selectedExp.status} />
        </Styled.StatusComponentWrapper>
      </Styled.MainInfoWrapper>
      <Styled.AdditionalInfo variant="body">{`${selectedExp.organization.name} • ${selectedExp.culture.name}`}</Styled.AdditionalInfo>
    </Styled.Wrapper>
  );
};

BackButtonContent.displayName = 'BackButtonContent';

export default BackButtonContent;
