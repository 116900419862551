import styled from 'styled-components';
import { Button, Colors } from '@farmlink/farmik-ui';

const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 420px;
`;

const OutlinedButton = styled(Button)`
  height: 40px;
  width: 200px;
  border-radius: 12px;
  flex-shrink: 0;
  justify-content: center;
  background-color: transparent;
  color: ${Colors.secondaryDarkGray};

  font-weight: 600;

  :hover {
    background-color: ${Colors.lightGrey};
  }
`;

const StyledHeaderContent = {
  StyledWrapper,
  OutlinedButton,
};

export default StyledHeaderContent;
