import { FC, memo } from 'react';
import { observer } from 'mobx-react';

import getOperstionTypeSvg from '../../../../../../../../../common/utils/helpers/getOperationTypeSvg/getOperationTypeSvg';

import Styled from './OperationTypeCell.style';

interface IProps {
  rowData: any;
}

const OperationTypeCell: FC<IProps> = ({ rowData }) => {
  return (
    <Styled.Wrapper>
      <Styled.TaskTypeSvg src={getOperstionTypeSvg(rowData?.techOperationType?.id)} />
      <Styled.OperationInfo>{rowData?.techOperationType?.name}</Styled.OperationInfo>
    </Styled.Wrapper>
  );
};

OperationTypeCell.displayName = 'OperationTypeCell';

export default memo(observer(OperationTypeCell));
