import styled from 'styled-components';

const Wrapper = styled.div<{ $paddingBottom: number }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-bottom: ${({ $paddingBottom }) => ($paddingBottom ? `${$paddingBottom}px` : 0)};
`;

const StyledActiveSubstancesRow = {
  Wrapper,
};

export default StyledActiveSubstancesRow;
