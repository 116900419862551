import { FC, memo } from 'react';

import { IAgrochemicalTable } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/components/AgrochemicalTable/AgrochemicalTable';
import { ActionCell as Cell } from '../content';

import Styled from './ActionCell.styles';

interface IActionCellComponent {
  row: IAgrochemicalTable;
  onClickDeleteAho: (row: IAgrochemicalTable) => void;
  onClickEditAho: (row: IAgrochemicalTable) => void;
}

const ActionCellComponentMini: FC<IActionCellComponent> = ({
  row,
  onClickDeleteAho,
  onClickEditAho,
}) => {
  const deleteAhoHandler = () => {
    onClickDeleteAho(row);
  };

  const editAhoHandler = () => {
    onClickEditAho(row);
  };

  return (
    <Styled.Wrapper>
      <Cell deleteHandler={deleteAhoHandler} editHandler={editAhoHandler} />
    </Styled.Wrapper>
  );
};

ActionCellComponentMini.displayName = 'ActionCellComponentMini';

export default memo(ActionCellComponentMini);
