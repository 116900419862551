import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { css } from 'styled-components';

import {
  EExperimentStatus,
  IExperiment,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import {
  MenuDropdown,
  IMenuDropdown,
} from '../../../../../../../../../../../common/features/UI/MenuDropdown';
import editIconSvg from '../../../../../../assets/icons/edit-icon.svg';
import downloadIconSvg from '../../../../../../assets/icons/download-icon.svg';
import { EDashboardRoute } from '../../../../../../../../../../routes/DashboardRoute';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { ContractSidePanelService } from '../../../../../../mobx/services';
import { AccessStore } from '../../../../../../../../../../../common/mobx/stores/AccessStore';

import Styled from './ExperimentOptionsCell.styles';

interface IProps {
  rowData: IExperiment;
}

const ExperimentOptionsCell: FC<IProps> = ({ rowData }) => {
  const contractSidePanelService = useStore(ContractSidePanelService);
  const { isAllowToEditExperiments } = useStore(AccessStore);
  const { generateTechTaskReport } = contractSidePanelService;

  const navigate = useNavigate();

  const generateTechTask = async () => {
    try {
      const response = await generateTechTaskReport(rowData.id);

      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(blob, rowData.name);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const menuDropdownConfig: IMenuDropdown['config'] = useMemo(
    () => ({
      menuItems: [
        {
          icon: downloadIconSvg,
          label: 'Скачать',
          onClick: () => {
            generateTechTask();
          },
        },
      ],
      menu: {
        button: Styled.MenuButton,
        menuStyle: css`
          top: 8px;
          right: -10px;
        `,
      },
    }),
    []
  );

  useEffect(() => {
    const { status, canEditContract } = rowData;

    if ((isAllowToEditExperiments || canEditContract) && status === EExperimentStatus.Draft) {
      menuDropdownConfig.menuItems.push({
        icon: editIconSvg,
        label: 'Изменить',
        onClick: () => {
          navigate(`/${EDashboardRoute.Experiments}/${rowData.id}/attributes`);
        },
      });
    }
  }, [isAllowToEditExperiments, rowData]);

  return <MenuDropdown config={menuDropdownConfig} />;
};

ExperimentOptionsCell.displayName = 'ExperimentOptionsCell';

export default ExperimentOptionsCell;
