import { AutoTooltip } from '@farmlink/farmik-ui';
import { FC, memo, useMemo } from 'react';

import { useShowTooltip } from '../../../../../../utils/hooks';
import { IActiveSubstancesData } from '../../../../model/IActiveSubstancesData.model';
import { getWordByNumber } from '../../helpers';

import Styled from './InventoryValueCellTitle.style';

interface IInventoryValueCellTitleProps {
  row: Pick<IActiveSubstancesData, 'id' | 'children'>;
  isShowJobLine: boolean;
}

const InventoryValueCellTitle: FC<IInventoryValueCellTitleProps> = ({ row, isShowJobLine }) => {
  const { ref, showTooltip } = useShowTooltip();

  const inventoryLength = useMemo(
    () => row?.children?.length + (isShowJobLine ? 1 : 0),
    [row?.children?.length, isShowJobLine]
  );

  switch (inventoryLength) {
    case 0:
      return <Styled.Item data-test-id={`inventory-value-cell-item-${row?.id}`}>—</Styled.Item>;

    default:
      return (
        <AutoTooltip
          content={`${inventoryLength} ${getWordByNumber(inventoryLength, [
            'компонент',
            'компонента',
            'компонентов',
          ])}`}
          position="top"
          disabled={!showTooltip}
        >
          <Styled.Header ref={ref} data-test-id={`inventory-value-cell-header-${row?.id}`}>
            {inventoryLength}{' '}
            {getWordByNumber(inventoryLength, ['компонент', 'компонента', 'компонентов'])}
          </Styled.Header>
        </AutoTooltip>
      );
  }
};

InventoryValueCellTitle.displayName = 'InventoryValueCellTitle';

export default memo(InventoryValueCellTitle);
