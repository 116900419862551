import { EDashboardRoute } from '../../../../../../routes/DashboardRoute';
import { EAuditsRoute } from '../../../../routes';
import { EAuditRoute } from '../../routes';

const createAuditRoute = (path: EAuditRoute): string => {
  return `/${EDashboardRoute.Audits}/${EAuditsRoute.Audit}/${path}`;
};

export const initialAuditTabsList = [
  {
    id: createAuditRoute(EAuditRoute.Info),
    order: 1,
    title: 'Информация',
  },
  {
    id: createAuditRoute(EAuditRoute.Tasks),
    order: 2,
    title: 'Задачи',
  },
  {
    id: createAuditRoute(EAuditRoute.Report),
    order: 3,
    title: 'Отчёт',
  },
  {
    id: createAuditRoute(EAuditRoute.Photos),
    order: 4,
    title: 'Фотографии',
  },
  {
    id: createAuditRoute(EAuditRoute.Approval),
    order: 5,
    title: 'Согласование',
  },
  {
    id: createAuditRoute(EAuditRoute.Documents),
    order: 6,
    title: 'Документы',
  },
];
