import { makeAutoObservable } from 'mobx';

import { ICultureEntity } from '../../../../../../../../../api/models/da-dictionary/culture';
import { IRegionEntity } from '../../../../../../../../../api/models/da-dictionary/region';
import { IOrganization } from '../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { IUser } from '../../../../../../../../../api/models/da-profile/users';
import { AxiosService } from '../../../../../../../../common/mobx/services/axios/AxiosService';
import { EPageSize } from '../../../../../../../../common/utils/constants/sizes';
import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';

@provide.singleton()
class ExperimentsService {
  @lazyInject(AxiosService)
  private axiosService: AxiosService;

  constructor() {
    makeAutoObservable(this);
  }

  fetchExperimentsList = async (payload): Promise<any> => {
    try {
      const fetchedData = await this.axiosService.api.getExperimentsList(payload);

      return fetchedData;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchCultureList = async (
    searchQuery: string,
    page?: number
  ): Promise<{ content: ICultureEntity[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getCulturesList(
        {
          attrs: { useInAssistance: true },
          latestVersion: true,
          nameFilter: searchQuery,
        },
        { query: { size: EPageSize.TwentyPages, page } }
      );

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchFD = async (): Promise<any> => {
    try {
      const fetchedFD = await this.axiosService.api.getRegionList({ level: '2' });

      return fetchedFD;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchRegionList = async (
    searchQuery: string,
    page?: number
  ): Promise<{ content: IRegionEntity[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getRegionList(
        {
          nameFilter: searchQuery,
        },
        { query: { size: EPageSize.TwentyPages, page } }
      );

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  fetchOrganizationList = async (
    searchQuery: string,
    page?: number
  ): Promise<{ content: IOrganization[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getExperimentOrganizationList({
        size: EPageSize.TwentyPages,
        page: page ?? 1,
        nameOrInn: searchQuery,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  fetchAllExperimentUsersList = async (
    searchQuery: string,
    page?: number
  ): Promise<{ content: IUser[]; totalPages: number }> => {
    try {
      const { content, totalPages } = await this.axiosService.api.getExperimentUserList({
        page,
        size: EPageSize.TwentyPages,
        fullName: searchQuery,
      });

      return { content, totalPages };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };
}

export default ExperimentsService;
