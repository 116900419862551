import { Tabs } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { ComparisonStore } from '../../mobx/stores';
import { TComparisonTabs } from '../../models';
import { tabContent } from '../../utils/constants';

import Styled from './ComparisonTabs.styles';

const ComparisonTabs: FC = () => {
  const { activeTab, setActiveTab } = useStore(ComparisonStore);

  const onChangeTab = (selectedId: TComparisonTabs) => {
    setActiveTab(selectedId);
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>Сравнение</Styled.Title>

      <Tabs
        selectedTabId={activeTab}
        onChangeTab={onChangeTab}
        content={tabContent}
        size="small"
        dataTestId="test"
      />
    </Styled.Wrapper>
  );
};

ComparisonTabs.displayName = 'ComparisonTabs';

export default observer(ComparisonTabs);
