import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelectedExp, useZonesPlugResolver } from '../../hooks';
import { ExperimentStepFooterContainer } from '../../containers';
import { useDataTestIdV2 } from '../../../../../../../common/utils/hooks/locators';
import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../common/features/form/utils/hooks';
import { useWarningBeforeLeavingThePage } from '../../../../../../../common/utils/hooks';
import { AccessStore } from '../../../../../../../common/mobx/stores/AccessStore';
import { SpinnerLoader } from '../../../../../../../common/components/ui/loaders';

import { NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE } from './constants/titles';
import { AuditRemarks, RemarksContentLoader, RemarksForm, RemarksPlug } from './components';
import { RemarksController } from './mobx/controllers';
import { RemarksStore } from './mobx/stores';
import {
  createRemarksFormConfig,
  IRemarksForm,
  REMARKS_FORM_KEY,
} from './forms/config/remarksFormConfig';
import { statusListToBlockTextAreas } from './helpers/consts/statusListToBlock';
import Styled from './Remarks.styles';

const Remarks: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { elements, registerForm, submitForm, changeListOfFormValue, blockElement } =
    useForm<IRemarksForm>(REMARKS_FORM_KEY);
  const { isFormChanged, focusChangedElement } = useCheckChangedForm();

  const selectedExperiment = useSelectedExp({ isClearOnUnmount: true });

  useWarningBeforeLeavingThePage(isFormChanged, focusChangedElement);

  const {
    loading,
    showPlug,
    plugType,
    plugTitle,
    Plug: ZonesPlug,
  } = useZonesPlugResolver(selectedExperiment?.id, NO_ZONES_TITLE, NOT_SELECTED_ZONES_TITLE);

  const { experimentStepList, clearRemarksStore } = useStore(RemarksStore);

  const { isAllowToEditExperiments } = useStore(AccessStore);

  const { fetchExperimentSteps, saveExperimentNotes } = useStore(RemarksController);

  const isViewMode = useMemo(() => {
    return (
      !isAllowToEditExperiments || statusListToBlockTextAreas.includes(selectedExperiment?.status)
    );
  }, [isAllowToEditExperiments, selectedExperiment?.status]);

  const isSaveButtonDisabled = useMemo(() => {
    return isViewMode || isLoading || !isFormChanged;
  }, [isViewMode, isLoading, isFormChanged]);

  useEffect(() => {
    registerForm(createRemarksFormConfig(isViewMode));

    return () => {
      clearRemarksStore();
    };
  }, [isViewMode]);

  useEffect(() => {
    if (selectedExperiment?.id) {
      fetchExperimentSteps(selectedExperiment.id);

      changeListOfFormValue({
        planDeviation: selectedExperiment.planDeviation,
        technologicalDeviation: selectedExperiment.technologicalDeviation,
        specialNotes: selectedExperiment.specialNotes,
      });

      if (statusListToBlockTextAreas.includes(selectedExperiment.status)) {
        blockElement('planDeviation', true);
        blockElement('technologicalDeviation', true);
        blockElement('specialNotes', true);
      }
    }
  }, [selectedExperiment?.id]);

  const handleSaveClick = useCallback(async () => {
    setIsLoading(true);
    await submitForm(async valideedData => {
      await saveExperimentNotes({ ...valideedData, experimentId: selectedExperiment.id });
      setIsLoading(false);
    });
  }, [selectedExperiment?.id]);

  const getDataTestId = useDataTestIdV2('remarks');

  const isShowContent = useMemo(
    () =>
      selectedExperiment?.planDeviation ||
      selectedExperiment?.technologicalDeviation ||
      selectedExperiment?.specialNotes ||
      experimentStepList?.length > 0,
    [selectedExperiment, experimentStepList]
  );

  if (loading) return <SpinnerLoader needToHideContent={false} />;

  if (showPlug) {
    return <ZonesPlug type={plugType} title={plugTitle} />;
  }

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      {isShowContent ? (
        <>
          {experimentStepList ? (
            experimentStepList?.length > 0 && (
              <Styled.ContentWrapper {...getDataTestId('content-wrapper')}>
                {experimentStepList.map(auditInfo => (
                  <AuditRemarks auditInfo={auditInfo} key={auditInfo.id}></AuditRemarks>
                ))}
              </Styled.ContentWrapper>
            )
          ) : (
            <RemarksContentLoader />
          )}
          <RemarksForm formElements={elements} />
          <ExperimentStepFooterContainer
            isContinueButtonDisabled={isSaveButtonDisabled}
            stepName={'remarks'}
            successTitle={'Сохранить'}
            isShowBackButton={false}
            onContinueClick={handleSaveClick}
          />
        </>
      ) : (
        <RemarksPlug />
      )}
    </Styled.Wrapper>
  );
};

Remarks.displayName = 'Remarks';

export default observer(Remarks);
