import styled from 'styled-components';

import { Breakpoints } from '../../../../constants/sizes';

const MessageListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  max-height: 100%;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding: 4px 0;
  -ms-overflow-style: none
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MessageFadeWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 90%;

  @media (max-width: ${Breakpoints.medium}) {
    height: 88%;
  }

  ::before,
  ::after {
    content: '';
    width: 100%;
    height: 5px;
    left: 0;
    position: absolute;
    background: rgb(255, 255, 255);
    z-index: 1;
  }

  ::before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    top: 0;
  }

  ::after {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
  }
`;

const Styled = {
  MessageListContainer,
  MessageFadeWrapper,
};

export default Styled;
