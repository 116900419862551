import styled from 'styled-components';

const StyledButton = styled.div`
  width: 160px;
  height: 40px;
  margin-right: 8px;
  border: 1px solid #3fb65f;
  border-radius: 12px;
  background: #3fb65f;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
  padding-top: 9px;
  color: #ffffff;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledHeaderContent = {
  StyledButton,
  StyledWrapper,
};

export default StyledHeaderContent;
