import {
  EExperimentCommentTypeEvent,
  IExperimentComment,
} from '../../../../../../../../../api/models/as-fields/experiments/ExperimentComment';

const getMessageText = (event: IExperimentComment<EExperimentCommentTypeEvent>): string => {
  switch (event.typeEvent) {
    case EExperimentCommentTypeEvent.APPROVED_TO_CANCELED:
    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_CANCELED:
      return 'Отменен';

    case EExperimentCommentTypeEvent.APPROVED_TO_IN_APPROVAL:
    case EExperimentCommentTypeEvent.DRAFT_TO_IN_APPROVAL:
      return 'ТЗ на согласовании';

    case EExperimentCommentTypeEvent.APPROVED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.CANCELED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.COMPLETED_TO_IN_PROGRESS:
    case EExperimentCommentTypeEvent.DRAFT_TO_IN_PROGRESS:
      return 'В работе';

    case EExperimentCommentTypeEvent.CANCELED_TO_APPROVED:
    case EExperimentCommentTypeEvent.IN_APPROVAL_TO_APPROVED:
      return 'ТЗ согласовано';

    case EExperimentCommentTypeEvent.IN_APPROVAL_TO_DRAFT:
    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_DRAFT:
      return 'Возвращено';

    case EExperimentCommentTypeEvent.IN_PROGRESS_TO_COMPLETED:
    case EExperimentCommentTypeEvent.FACT_APPROVED_TO_COMPLETED:
      return 'Завершен';

    case EExperimentCommentTypeEvent.COMPLETED_TO_FACT_APPROVED:
    case EExperimentCommentTypeEvent.FULL_COMPLETED_TO_FACT_APPROVED:
      return 'Отчет согласован';

    case EExperimentCommentTypeEvent.FACT_APPROVED_TO_FULL_COMPLETED:
      return 'Закрыт';

    case EExperimentCommentTypeEvent.ADD_FILE:
      return `Файл ${event.fileName} добавлен`;

    case EExperimentCommentTypeEvent.DELETE_FILE:
      return `Файл ${event.fileName} удален`;

    default:
      return event.comment;
  }
};

export { getMessageText };
