import { FC } from 'react';
import { observer } from 'mobx-react';

import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { ITableBuilderRowsGroup as IRowsGroup } from '../../../models/data/TableBuilderRowsGroup';
import { TableBuilderRowsContainer as RowsContainer } from '../../rows/TableBuilderRowsContainer';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';

interface IProps {
  rowsGroup: IRowsGroup;
  borderType: string;
}

const TableBuilderRowsGroupCustomRenderContainer: FC<IProps> = ({ rowsGroup, borderType }) => {
  const context = useContext();

  const getDataTestId = useDataTestId(context.builderId);

  return (
    <>
      {rowsGroup.customRenderConfig.render(
        rowsGroup,
        <RowsContainer
          rowsGroupId={rowsGroup.id}
          rowIdList={rowsGroup.rowIdList}
          borderType={borderType}
        />,
        getDataTestId('custom-rows-group')['data-test-id']
      )}
    </>
  );
};

TableBuilderRowsGroupCustomRenderContainer.displayName =
  'ComparisonTableBuilderRowsGroupCustomRenderContainer';

export default observer(TableBuilderRowsGroupCustomRenderContainer);
