import styled from 'styled-components';

const Wrapper = styled.div<{ $borderType: string }>`
  width: 100%;

  & > :not(:last-child) {
    border-bottom: ${({ $borderType }) => `1px ${$borderType ?? 'solid'} #e9e6f0`};
  }
`;

const StyledComparisonTableBuilderRowsContainer = {
  Wrapper,
};

export default StyledComparisonTableBuilderRowsContainer;
