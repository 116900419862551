import { FC, ReactNode } from 'react';
import { useDataTestId } from '@farmlink/farmik-ui';

import Styled from './ExecutionContainerHeader.styles';

interface IProps {
  title: string;
  /**
   * Иконка контекстной справки.
   */
  ContextualHelpIcon?: ReactNode;
}

const ExecutionContainerHeader: FC<IProps> = ({ title, ContextualHelpIcon }) => {
  const getDataTestId = useDataTestId('execution-container-header');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Styled.Title {...getDataTestId('title')}>{title}</Styled.Title>

        {ContextualHelpIcon ? ContextualHelpIcon : null}
      </Styled.TitleWrapper>

      <Styled.TooltipWrapper {...getDataTestId('tooltip-wrapper')}>
        <Styled.InfoIcon {...getDataTestId('info-icon')} />

        <Styled.Description {...getDataTestId('info-description')}>
          Внесите фактические значения
        </Styled.Description>
      </Styled.TooltipWrapper>
    </Styled.Wrapper>
  );
};

ExecutionContainerHeader.displayName = 'ExecutionContainerHeader';

export default ExecutionContainerHeader;
