import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledDocumentsTitle = { Wrapper, FileInput };

export default StyledDocumentsTitle;
