import { FC, memo, MouseEvent } from 'react';

import Styled from './DeleteIcon.style';

const DeleteIconComponent: FC<{ onClick: (e: MouseEvent<SVGSVGElement>) => void }> = ({
  onClick,
}) => {
  return (
    <Styled.SVGWrapper
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect width="32" height="32" rx="12" fill="#FEF5F5" />
      <g clip-path="url(#clip0_5682_279787)">
        <path d="M13.2091 14.3301H14.2557V22.2402H13.2091V14.3301Z" fill="#DF2E22" />
        <path d="M15.4769 14.3301H16.5235V22.2402H15.4769V14.3301Z" fill="#DF2E22" />
        <path d="M17.7445 14.3301H18.7911V22.2402H17.7445V14.3301Z" fill="#DF2E22" />
        <path
          d="M22.3429 14.3301H21.9903L22.1465 12.625H23.3265V10.9551C23.3265 10.0343 22.5831 9.28516 21.6693 9.28516H19.725L18.5912 7H13.4089L12.275 9.28516H10.3307C9.41697 9.28516 8.67355 10.0343 8.67355 10.9551V12.625H9.85349L10.0097 14.3301H9.65718C8.74343 14.3301 8 15.0792 8 16C8 16.9208 8.74343 17.6699 9.65718 17.6699H10.3157L10.9874 25H21.0127L21.6843 17.6699H22.3428C23.2566 17.6699 24 16.9208 24 16C24 15.0792 23.2566 14.3301 22.3429 14.3301ZM14.0557 8.05469H17.9443L18.5549 9.28516H13.4452L14.0557 8.05469ZM9.72019 10.9551C9.72019 10.6159 9.99409 10.3398 10.3307 10.3398H21.6693C22.0059 10.3398 22.2799 10.6159 22.2799 10.9551V11.5703H9.72019V10.9551ZM9.65718 16.6152C9.32054 16.6152 9.04664 16.3392 9.04664 16C9.04664 15.6608 9.32054 15.3848 9.65718 15.3848H10.1064L10.2191 16.6152H9.65718ZM20.0582 23.9453H11.9418L10.9046 12.625H21.0954L20.0582 23.9453ZM22.3429 16.6152H21.781L21.8937 15.3848H22.3429C22.6795 15.3848 22.9534 15.6608 22.9534 16C22.9534 16.3392 22.6795 16.6152 22.3429 16.6152Z"
          fill="#DF2E22"
        />
        <path
          d="M13.2091 14.3301H14.2557V22.2402H13.2091V14.3301Z"
          stroke="#DF2E22"
          stroke-width="0.1"
        />
        <path
          d="M15.4769 14.3301H16.5235V22.2402H15.4769V14.3301Z"
          stroke="#DF2E22"
          stroke-width="0.1"
        />
        <path
          d="M17.7445 14.3301H18.7911V22.2402H17.7445V14.3301Z"
          stroke="#DF2E22"
          stroke-width="0.1"
        />
        <path
          d="M22.3429 14.3301H21.9903L22.1465 12.625H23.3265V10.9551C23.3265 10.0343 22.5831 9.28516 21.6693 9.28516H19.725L18.5912 7H13.4089L12.275 9.28516H10.3307C9.41697 9.28516 8.67355 10.0343 8.67355 10.9551V12.625H9.85349L10.0097 14.3301H9.65718C8.74343 14.3301 8 15.0792 8 16C8 16.9208 8.74343 17.6699 9.65718 17.6699H10.3157L10.9874 25H21.0127L21.6843 17.6699H22.3428C23.2566 17.6699 24 16.9208 24 16C24 15.0792 23.2566 14.3301 22.3429 14.3301ZM14.0557 8.05469H17.9443L18.5549 9.28516H13.4452L14.0557 8.05469ZM9.72019 10.9551C9.72019 10.6159 9.99409 10.3398 10.3307 10.3398H21.6693C22.0059 10.3398 22.2799 10.6159 22.2799 10.9551V11.5703H9.72019V10.9551ZM9.65718 16.6152C9.32054 16.6152 9.04664 16.3392 9.04664 16C9.04664 15.6608 9.32054 15.3848 9.65718 15.3848H10.1064L10.2191 16.6152H9.65718ZM20.0582 23.9453H11.9418L10.9046 12.625H21.0954L20.0582 23.9453ZM22.3429 16.6152H21.781L21.8937 15.3848H22.3429C22.6795 15.3848 22.9534 15.6608 22.9534 16C22.9534 16.3392 22.6795 16.6152 22.3429 16.6152Z"
          stroke="#DF2E22"
          stroke-width="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_5682_279787">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </Styled.SVGWrapper>
  );
};

export default memo(DeleteIconComponent);
