import { FC, memo } from 'react';

import { IEconomyData } from '../../../../model/IEconomyData.model';
import { PriceCell as CellContent } from '../../content';

import Styled from './PriceCell.styles';

interface IPriceCellComponentProps {
  row: Pick<IEconomyData, 'id' | 'children'>;
}

const PriceCellComponent: FC<IPriceCellComponentProps> = ({ row }) => {
  return (
    <>
      {row.children.length > 0 ? (
        <Styled.Wrapper data-test-id={`price-cell-wrapper-${row?.id}`}>
          {row.children.map(value => (
            <CellContent key={value.id} price={value.pricePerUnit} />
          ))}
        </Styled.Wrapper>
      ) : (
        <Styled.NoInfo>—</Styled.NoInfo>
      )}
    </>
  );
};

PriceCellComponent.displayName = 'PriceCellComponent';

export default memo(PriceCellComponent);
