import { format } from 'date-fns';

import { IExperimentStep } from '../../../../../api/models/as-fields/experiments';
import { INutritionHistory } from '../../../../../api/models/as-fields/plan/NutrationHistory';
import { ITechOperationData } from '../model/ITechOperationData.model';

const createTechOperationsTableBuilderData = (
  experimentSteps: IExperimentStep[],
  nutritionHistories: INutritionHistory[]
): ITechOperationData[] => {
  const experimentStepWithInventoryValues = experimentSteps.map(experimentStep => ({
    ...experimentStep,
    inventoryValues: nutritionHistories.reduce((acc, nutritionHistory) => {
      if (nutritionHistory.experimentStep.id === experimentStep.id) {
        acc.push(...nutritionHistory.items);
      }

      return acc;
    }, []),
    factStartDate: experimentStep.factStartDate
      ? format(new Date(experimentStep.factStartDate), 'dd.MM.yyyy')
      : null,
    factEndDate: experimentStep.factEndDate
      ? format(new Date(experimentStep.factEndDate), 'dd.MM.yyyy')
      : null,
    planStartDate: experimentStep.planStartDate
      ? format(new Date(experimentStep.planStartDate), 'dd.MM.yyyy')
      : null,
    planEndDate: experimentStep.planEndDate
      ? format(new Date(experimentStep.planEndDate), 'dd.MM.yyyy')
      : null,
    phenophase: experimentStep?.phenophase
      ? experimentStep?.phenophase
      : {
          name: null,
          description: null,
          code: null,
        },
    phenophaseEnd: experimentStep?.phenophaseEnd
      ? experimentStep?.phenophaseEnd
      : {
          name: null,
          description: null,
          code: null,
        },
  }));

  const sortedExperimentStep = experimentStepWithInventoryValues.sort(
    (prev, next) => prev.order - next.order
  );

  const rows = sortedExperimentStep.map((experimentStep, index) => ({
    id: experimentStep.id,
    number: index + 1,
    name: experimentStep.name,
    factStartDate: experimentStep.factStartDate,
    factEndDate: experimentStep.factEndDate,
    planStartDate: experimentStep.planStartDate,
    planEndDate: experimentStep.planEndDate,
    phenophase: experimentStep.phenophase?.name,
    phenophaseEnd: experimentStep.phenophaseEnd?.name,
    bbch: experimentStep?.phenophase?.code,
    bbchEnd: experimentStep?.phenophaseEnd?.code,
    method: experimentStep.techOperationType.name,
    inventoryValue: '—',
    dosage: '—',
    children: experimentStep.inventoryValues,
    actions: null,
    usageMethodTypeName: experimentStep?.usageMethodType?.name,
  }));

  return rows;
};

export { createTechOperationsTableBuilderData };
