import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../common/utils/helpers/mobx';
import {
  IExperiment,
  IExperimentStep,
} from '../../../../../../../../api/models/as-fields/experiments';

@provide.singleton()
class AuditStore {
  private _selectedExp: IExperiment | null = null;

  private _selectedAudit: IExperimentStep | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedExp() {
    return this._selectedExp;
  }

  setSelectedExp = (exp: IExperiment): void => {
    this._selectedExp = exp;
  };

  clearSelectedExp = (): void => {
    this._selectedExp = null;
  };

  get selectedAudit() {
    return this._selectedAudit;
  }

  setSelectedAudit = (audit: IExperimentStep): void => {
    this._selectedAudit = audit;
  };

  clearSelectedAudit = (): void => {
    this._selectedAudit = null;
  };

  clearStore = (): void => {
    this.clearSelectedAudit();
  };
}

export default AuditStore;
