import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: #7e8b9d;
`;

const StyledTableBuilderColumn = {
  Wrapper,
};

export default StyledTableBuilderColumn;
