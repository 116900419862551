import styled from 'styled-components';

const StyledTooltipWrapper = styled.div`
  text-overflow: ellipsis;
  max-width: inherit;
  overflow: hidden;
`;

const StyledTooltipContent = styled.span`
  overflow-wrap: break-word;
  white-space: normal;
`;

export { StyledTooltipWrapper, StyledTooltipContent };
