import { FC } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../../../common/utils/helpers/mobx';
import { DocumentsList, DocumentsTitle } from '../../components';
import { DocumentsStore } from '../../mobx/stores';

import Styled from './Documents.styles';

const Documents: FC = observer(() => {
  const { attachedFiles } = useStore(DocumentsStore);

  return (
    <Styled.Wrapper $isFlexible={attachedFiles.length === 0} data-test-id="preparation-wrapper">
      <DocumentsTitle />
      <DocumentsList />
    </Styled.Wrapper>
  );
});

Documents.displayName = 'Documents';

export default Documents;
