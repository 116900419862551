import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../assets/styles/colors';

// @ts-ignore
import { ReactComponent as InfoIcon } from './assets/images/info-16x16.svg';

const { BorderGray, GeneralDark, SecondaryDarkGray, Pink, GeneralBlack, GeneralWhite } =
  ECommonColor;

enum EFontSize {
  default = 'font-size: 12px; line-height: 16px;',
  defaultBold = 'font-size: 12px; line-height: 16px; font-weight: 600;',
  medium = 'font-size: 14px; line-height: 20px;',
  mediumBold = 'font-size: 14px; line-height: 20px; font-weight: 600;',
  large = 'font-size: 16px; line-height: 20px;',
  largeBold = 'font-size: 16px; line-height: 20px; font-weight: 600;',
  xlarge = 'font-size: 20px; line-height: 28px;',
  xlargeBold = 'font-size: 20px; line-height: 28px; font-weight: 600;',
  xxlargeBold = 'font-size: 24px; line-height: 28px; font-weight: 600;',
  title = 'font-size: 24px; line-height: 28px;',
}

const defaultScheme = `
border-color: ${BorderGray};
color: ${GeneralDark};

&:hover {
  border-color: ${SecondaryDarkGray}
}
`;

const disabledScheme = `
  border-color: ${BorderGray};
  color: ${SecondaryDarkGray};
`;

const hasErrorScheme = `
  border-color: ${Pink};
  color: ${Pink};
`;

const renderColor = (error: boolean, disabled: boolean) => {
  if (disabled) return disabledScheme;

  return error ? hasErrorScheme : defaultScheme;
};

type TStyledInputFieldProps = {
  $error?: string;
  $focus?: boolean;
  $isBlocked?: boolean;
  $isDisabled?: boolean;
  $isResizable?: boolean;
  $height?: string;
  $hideScrollbar?: boolean;
  $as?: string;
};

const InputField = styled.input<TStyledInputFieldProps>`
  // * Настройки временного характера. Как будет интегрирована фильная тема, просьба: обновить
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  // *

  text-align: ${({ $as }) => ($as === 'textarea' ? 'justify' : 'left')};
  background: #faf9fd;
  color: ${props => (props.disabled ? SecondaryDarkGray : GeneralBlack)};
  border: 1px solid;
  border-radius: 12px;
  ${EFontSize.medium}
  width: 100%;
  height: ${({ $height = '42px' }) => $height};
  padding: 12px 16px;
  box-sizing: border-box;
  outline: none;
  ${({ $as }) =>
    $as === 'input' &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ $isResizable = true }) =>
    !$isResizable &&
    css`
      resize: none;
    `}

  ${({ $error, $isDisabled }) => renderColor(Boolean($error), $isDisabled)};

  ${({ $hideScrollbar }) =>
    $hideScrollbar &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ::placeholder {
    color: ${SecondaryDarkGray};
  }

  :disabled {
    border-color: ${BorderGray};
    color: ${GeneralBlack};
  }

  &[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  ${({ $isBlocked }) =>
    $isBlocked &&
    `
    padding-right: 49px;
    pointer-events: none;
    color: ${GeneralBlack};
    background: ${GeneralWhite};

    ::placeholder {
      color: ${GeneralBlack};
    }
  `}
`;
const ErrorMessage = styled.div`
  color: ${Pink};
  font-size: 12px;
  padding: 4px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Label = styled.p<{ required?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${SecondaryDarkGray};
  margin: 0 0 4px;

  &:after {
    color: red;
    visibility: ${({ required }) => (required ? 'visible' : 'hidden')};
    content: '*';
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(InfoIcon)`
  width: 14px;
  height: 14px;
  margin: 0 4px 4px 4px;
  cursor: pointer;
`;

type TStyledIconProps = {
  $align: 'start' | 'end';
  $isLabel: boolean;
};

export const IconWrapper = styled.div<TStyledIconProps>`
  position: absolute;
  height: 40px;
  display: flex;
  align-items: center;

  ${({ $isLabel }) => ($isLabel ? `top: 20px;` : `top: 0;`)}
  ${({ $align }) => ($align === 'start' ? `left: 16px;` : `right: 16px;`)}
`;

const TopBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

type TStyledDropdownIconsMinusProps = {
  $defaultSvg: string;
};

const Minus = styled.div<TStyledDropdownIconsMinusProps>`
  width: 16px;
  height: 16px;

  background: url(${({ $defaultSvg }) => $defaultSvg}) no-repeat center;
`;

export default {
  Wrapper,
  TopBlock,
  LabelWrapper,
  Label,
  Icon,
  ErrorMessage,
  InputField,
  IconWrapper,
  Minus,
};
