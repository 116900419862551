import { FC, memo, useCallback, useEffect, useState } from 'react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesStore } from '../../../../mobx/stores';
import { zonesModalConfig } from '../../../../config/modals';
import { ZonesController } from '../../../../mobx/controllers';
import { IField } from '../../../../../../../../../../../../../../api/models/as-fields/fields/Field.model';
import { useShowTooltip } from '../../../../../../../../../../../../../common/utils/hooks';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';

import Styled from './ZoneListNameCell.styles';

interface IZoneListNameCellProps {
  rowData: ICultureZoneCell;
  isDisabled?: boolean;
}

const ZoneListNameCell: FC<IZoneListNameCellProps> = ({
  rowData: { fwExpCultureZone },
  isDisabled,
}) => {
  const { selectedExp } = useStore(CreateExperimentStore);
  const { setEditableZoneId } = useStore(ZonesStore);
  const { fetchField } = useStore(ZonesController);

  const [field, setField] = useState<IField | null>(null);

  const { ref: zoneNameRef, showTooltip: showZoneNameTooltip } = useShowTooltip<HTMLDivElement>(
    fwExpCultureZone.cultureZone?.name
  );
  const { ref: fieldNameRef, showTooltip: showFieldNameTooltip } = useShowTooltip<HTMLSpanElement>(
    field?.name
  );

  const { openModalByModalId } = useModal();

  useEffect(() => {
    if (!fwExpCultureZone.cultureZone.fieldId) {
      return;
    }

    (async () => {
      const fetchedField = await fetchField({
        id: fwExpCultureZone.cultureZone.fieldId,
        seasonYear: selectedExp.seasonYear,
      });

      if (fetchedField) {
        setField(fetchedField);
      }
    })();
  }, [fwExpCultureZone.cultureZone.fieldId]);

  const handleNameClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (fwExpCultureZone.isSelected) {
      setEditableZoneId(fwExpCultureZone.cultureZoneId);
      openModalByModalId(zonesModalConfig.name.changeCultureZoneName);
    }
  }, [fwExpCultureZone, openModalByModalId, setEditableZoneId, isDisabled]);

  return (
    <Styled.Wrapper $isDisabled={!fwExpCultureZone.isSelected}>
      <Styled.Logo $url={field?.icon?.downloadUrl} />

      <Styled.Description>
        <AutoTooltip
          content={fwExpCultureZone.cultureZone?.name || 'Безымянный'}
          disabled={!showZoneNameTooltip}
          position="top"
        >
          <Styled.ZoneName
            ref={zoneNameRef}
            onClick={handleNameClick}
            $isSelected={fwExpCultureZone.isSelected}
          >
            {fwExpCultureZone.cultureZone?.name || 'Безымянный'}
          </Styled.ZoneName>
        </AutoTooltip>

        <AutoTooltip
          content={field?.name || 'Поле №1'}
          disabled={!showFieldNameTooltip}
          position="top"
        >
          <Styled.FieldName ref={fieldNameRef}>{field?.name || 'Поле №1'}</Styled.FieldName>
        </AutoTooltip>
      </Styled.Description>
    </Styled.Wrapper>
  );
};

ZoneListNameCell.displayName = 'ZoneListNameCell';

export default memo(observer(ZoneListNameCell));
