import { ICulture, ICultureZone } from '../../fields/CultureZone';
import { ISelectOption } from '../../../../../modules/common/components/form/Dropdown/Dropdown.types';
import { IExamination } from '../../../aho/examination/Examination.model';

enum EExperimentCultureZoneType {
  Control = 'CONTROL',
  Experiment = 'EXPERIMENT',
}

enum EExpZoneStyle {
  Experiment = 'EXPERIMENT',
  Control = 'CONTROL',
  ReadyToExperiment = 'READY_TO_EXPERIMENT',
  Other = 'OTHER',
}

interface IExperimentCultureZone {
  aho: IExamination;
  experimentId: string;
  type: EExperimentCultureZoneType;
  id: string;
  cultureZone: ICultureZone;
  previousCultures: ICulture[];
  plannedCropPrice: number | null;
  startedDate: string;
  plannedYield: number | null;
}

interface IChangeExperimentCultureZone {
  experimentId: string;
  type: EExperimentCultureZoneType;
  cultureZoneName: string;
  cultureZoneId: string;
  previousCultureIds: string[];
  plannedYield?: number;
  plannedCropPrice?: number;
}

interface IFWExperimentCultureZone {
  cultureZoneId: string;
  polyId: number | null;
  name: string;
  type: EExperimentCultureZoneType | null;
  isSelected: boolean;
  cultureZone: ICultureZone;
  prevCultureSelectOptionList: ISelectOption[];
  styleType: EExpZoneStyle;
  expZone?: IExperimentCultureZone;
  predecessorId?: string;
}

export type { IExperimentCultureZone, IChangeExperimentCultureZone, IFWExperimentCultureZone };

export { EExperimentCultureZoneType, EExpZoneStyle };
