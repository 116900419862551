import styled from 'styled-components';

const StatusWrapper = styled.div`
  & div {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.1px;
    margin-left: 12px;
    white-space: nowrap;
  }
`;

const StyledAudit = {
  StatusWrapper,
};

export default StyledAudit;
