import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { TableBuilderController } from '../../../../../../../../../../../common/features/TableBuilder/mobx/controllers';
import { TChangeExperimentContractReq } from '../../../../../../../../../../../../api';
import { EContractsTableBuilderId } from '../../../../../../../../constants/configs';
import { DocumentsTableConfigService, DocumentsTableService } from '../services';
import { DocumentsTableStore } from '../stores';

@provide.singleton()
class DocumentsTableController {
  constructor() {
    makeAutoObservable(this);
  }

  @lazyInject(DocumentsTableConfigService)
  protected documentsConfigService: DocumentsTableConfigService;

  @lazyInject(TableBuilderController)
  protected tableBuilderController: TableBuilderController;

  @lazyInject(DocumentsTableService)
  protected documentsTableService: DocumentsTableService;

  @lazyInject(DocumentsTableStore)
  protected documentsTableStore: DocumentsTableStore;

  initiateTable = () => {
    const config = this.documentsConfigService.createConfig();

    this.tableBuilderController.initiateTable(config);
  };

  fetchAttachedFiles = async (contractId: string) => {
    const { showLoader, hideLoader } = this.tableBuilderController;
    const { getExperimentContract } = this.documentsTableService;
    const { setAttachedFiles } = this.documentsTableStore;

    showLoader(EContractsTableBuilderId.Documents);

    const experimentContract = await getExperimentContract(contractId);

    setAttachedFiles(experimentContract.attachedFiles);

    hideLoader(EContractsTableBuilderId.Documents);
  };

  updateAttachedFiles = async (payload: TChangeExperimentContractReq) => {
    const { showLoader, hideLoader } = this.tableBuilderController;
    const { changeExperimentContract } = this.documentsTableService;
    const { setAttachedFiles } = this.documentsTableStore;

    showLoader(EContractsTableBuilderId.Documents);

    const experimentContract = await changeExperimentContract(payload);

    setAttachedFiles(experimentContract.attachedFiles);

    hideLoader(EContractsTableBuilderId.Documents);
  };
}

export default DocumentsTableController;
