import { FC, memo } from 'react';
import { AutoTooltip } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { useShowTooltip } from '../../../../../../../../../../../../../common/utils/hooks';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';

import Styled from './ZoneListCellWithDisabled.styles';
interface IZoneListCellWithDisabledProps {
  rowData: ICultureZoneCell;
  text: string;
}

const ZoneListCellWithDisabled: FC<IZoneListCellWithDisabledProps> = ({
  text,
  rowData: { fwExpCultureZone },
}) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const { selectedExp } = useStore(CreateExperimentStore);

  return (
    <AutoTooltip position="top" content={text} disabled={!showTooltip}>
      <Styled.Wrapper
        ref={ref}
        $isDisabled={
          !fwExpCultureZone?.isSelected || selectedExp?.status !== EExperimentStatus.Draft
        }
      >
        {text}
      </Styled.Wrapper>
    </AutoTooltip>
  );
};

ZoneListCellWithDisabled.displayName = 'ZoneListCellWithDisabled';

export default memo(ZoneListCellWithDisabled);
