import { lazyInject, provide } from '../../../../../../../../common/utils/helpers/mobx';
import {
  OrganizationsService,
  UsersService,
} from '../../../../../../../../common/mobx/services/da-profile';
import { ISelectOption } from '../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import {
  createDictionaryEntitySelectOptionList,
  createOrganizationSelectOptionList,
  createUserSelectOptionList,
} from '../../../../../../../../common/utils/helpers/selectOptions';
import {
  EExperimentStatus,
  EExperimentType,
} from '../../../../../../../../../api/models/as-fields/experiments';
import { DictionaryService } from '../../../../../../../../common/mobx/services/da-dictionary';
import {
  TFilterPaginationQuery,
  TFiltersFilterPaginationQuery as TPageQueryConfig,
} from '../../../../../../../../common/features/TableFiltersBuilder/types/pagination/FiltersFilterPaginationConfig';
import { IExperimentsFilters } from '../../../models';
import { EPageSize } from '../../../../../../../../common/utils/constants/sizes';

@provide.transient()
class ExperimentsFiltersOptionsService {
  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(UsersService)
  protected usersService: UsersService;

  get optionsQueryScheme(): TPageQueryConfig<
    IExperimentsFilters,
    'seasonYearIn' | 'statusIn' | 'typeIn'
  > {
    return {
      assigneeIdIn: {
        requestHandler: (page, searchQuery) => this.createAssigneeOptionList(page, searchQuery),
      },
      cultureIdIn: {
        requestHandler: (page, searchQuery) => this.createCultureOptionList(page, searchQuery),
      },
      districtIdIn: {
        requestHandler: (page, searchQuery) => this.createDistrictOptionList(page, searchQuery),
      },
      organizationIdIn: {
        requestHandler: (page, searchQuery) => this.createOrganizationOptionList(page, searchQuery),
      },
      regionIdIn: {
        requestHandler: (page, searchQuery) => this.createRegionOptionList(page, searchQuery),
      },
    };
  }

  createSeasonYearOptionList = (): ISelectOption[] => {
    const currentYear = new Date().getFullYear();

    return [
      { label: `${currentYear + 2}`, value: `${currentYear + 2}` },
      { label: `${currentYear + 1}`, value: `${currentYear + 1}` },
      { label: `${currentYear}`, value: `${currentYear}` },
      { label: `${currentYear - 1}`, value: `${currentYear - 1}` },
      { label: `${currentYear - 2}`, value: `${currentYear - 2}` },
      { label: `${currentYear - 3}`, value: `${currentYear - 3}` },
      { label: `${currentYear - 4}`, value: `${currentYear - 4}` },
      { label: `${currentYear - 5}`, value: `${currentYear - 5}` },
    ];
  };

  createOrganizationOptionList = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.organizationsService.getExperimentOrganizationList({
      size: EPageSize.TwentyPages,
      page: page ?? 0,
      nameOrInn: searchQuery,
    });

    if (response?.content) {
      return {
        optionList: createOrganizationSelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  createTypeOptionList = (): ISelectOption[] => [
    { label: 'Система питания', value: EExperimentType.MultiProduct },
    { label: 'Монопродуктовый', value: EExperimentType.SingleProduct },
  ];

  createCultureOptionList = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQuery,
      },
      { size: EPageSize.TwentyPages, page: page ?? 0 }
    );

    if (response?.content) {
      return {
        optionList: createDictionaryEntitySelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  createStatusOptionList = (): ISelectOption[] => [
    { label: 'В работе', value: EExperimentStatus.InProgress },
    { label: 'ТЗ на согласовании', value: EExperimentStatus.InApproval },
    { label: 'Завершен', value: EExperimentStatus.Completed },
    { label: 'Черновик', value: EExperimentStatus.Draft },
    { label: 'ТЗ согласовано', value: EExperimentStatus.Approved },
    { label: 'Отчет согласован', value: EExperimentStatus.FactAppruved },
    { label: 'Закрыт', value: EExperimentStatus.FullCompleted },
    { label: 'Отменен', value: EExperimentStatus.Canceled },
  ];

  createRegionOptionList = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'regions',
        nameFilter: searchQuery,
      },
      { size: EPageSize.TwentyPages, page: page ?? 0 }
    );

    if (response?.content) {
      return {
        optionList: createDictionaryEntitySelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  createDistrictOptionList = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'regions',
        level: 2,
        nameFilter: searchQuery,
      },
      { size: EPageSize.TwentyPages, page: page ?? 0 }
    );

    if (response?.content) {
      return {
        optionList: createDictionaryEntitySelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };

  createAssigneeOptionList = async (
    page?: number,
    searchQuery?: string
  ): Promise<TFilterPaginationQuery> => {
    const response = await this.usersService.getExperimentUserList({
      size: EPageSize.TwentyPages,
      page: page ?? 0,
      fullName: searchQuery,
    });

    if (response?.content) {
      return {
        optionList: createUserSelectOptionList(response.content),
        size: EPageSize.TwentyPages,
        totalPages: response.totalPages,
      };
    }

    return { optionList: [], size: EPageSize.TwentyPages, totalPages: response.totalPages ?? 0 };
  };
}

export default ExperimentsFiltersOptionsService;
