import styled, { css } from 'styled-components';

import { ECommonColor } from '../../../../../../../../../../../../../common/assets/styles/colors';

const { SecondaryDarkGray, GeneralBlack } = ECommonColor;

const Logo = styled.div<{ $url?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 16px;
  margin-right: 12px;

  ${({ $url }) =>
    $url
      ? css`
          background: no-repeat center url(${$url});
        `
      : css`
          background-color: ${SecondaryDarkGray};
        `}
`;

const ZoneName = styled.div<{ $isSelected: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${SecondaryDarkGray};
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      cursor: pointer;
      border-bottom: 0.5px solid ${SecondaryDarkGray};
    `}
`;

const FieldName = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${GeneralBlack};
`;

const Description = styled.div`
  flex: 1 1 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Wrapper = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledZoneListNameCell = {
  Logo,
  ZoneName,
  FieldName,
  Description,
  Wrapper,
};

export default StyledZoneListNameCell;
