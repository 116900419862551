import { AutoTooltip, Typography } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';

import { useShowTooltip } from '../../../../../../../../../common/utils/hooks';

import { StyledTooltipContent, StyledTooltipWrapper } from './CellWithTooltip.styles';

const CellWithTooltip: FC<{ children; id: string }> = ({ children, id }) => {
  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  return (
    <AutoTooltip
      disabled={!showTooltip}
      content={<StyledTooltipContent>{children}</StyledTooltipContent>}
      dataTestId={id}
    >
      <StyledTooltipWrapper ref={ref}>
        <Typography>{children}</Typography>
      </StyledTooltipWrapper>
    </AutoTooltip>
  );
};

export default memo(CellWithTooltip);
