import { FC } from 'react';
import { observer } from 'mobx-react';

import { ITableBuilderRowsGroup as IRowsGroup } from '../../../models/data/TableBuilderRowsGroup';
import { TableBuilderRowsGroupAutoRenderContainer as RowsGroupAutoRenderContainer } from '../TableBuilderRowsGroupAutoRenderContainer';
import { TableBuilderRowsGroupCustomRenderContainer as RowsGroupCustomRenderContainer } from '../TableBuilderRowsGroupCustomRenderContainer';

interface IProps {
  rowsGroup: IRowsGroup;
  borderType: string;
}

const TableBuilderRowsGroupContainer: FC<IProps> = ({ rowsGroup, borderType }) => {
  switch (rowsGroup.renderType || 'auto') {
    case 'auto':
      return <RowsGroupAutoRenderContainer rowsGroup={rowsGroup} borderType={borderType} />;

    case 'custom':
      return <RowsGroupCustomRenderContainer rowsGroup={rowsGroup} borderType={borderType} />;

    default:
      return <></>;
  }
};

TableBuilderRowsGroupContainer.displayName = 'TableBuilderRowsGroupContainer';

export default observer(TableBuilderRowsGroupContainer);
