import styled from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';
import { arrowLeft } from '../../assets/icons';

const InputRowWrapper = styled.form`
  width: 100%;
  display: flex;
  margin-top: 16px;
  gap: 16px;

  & > div {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  min-width: 40px;
  height: 40px;
  background-color: ${ECommonColor.AddSky};
  border-radius: 12px;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background-image: url(${arrowLeft});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    background-color: ${ECommonColor.SkyHover};
  }
`;

const Styled = {
  InputRowWrapper,
  SubmitButton,
};

export default Styled;
