import { AuditInfoStore } from '../../stores';
import { lazyInject, provide } from '../../../../../../../../../../../../common/utils/helpers/mobx';
import { AxiosService } from '../../../../../../../../../../../../common/mobx/services/axios/AxiosService';
import { EExperimentStepType } from '../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';

@provide.singleton()
class AuditInfoService {
  @lazyInject(AxiosService)
  axiosService: AxiosService;

  @lazyInject(AuditInfoStore)
  auditInfoStore: AuditInfoStore;

  getAuditInfo = async (auditId: string) => {
    try {
      const content = await this.axiosService.api.getExperimentStepOld({
        type: EExperimentStepType.Audit,
        experimentId: auditId,
      });

      return content;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export default AuditInfoService;
