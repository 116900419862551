import { FC, memo } from 'react';

import { EMessageStyleType } from '../../../../../common/features/ChatJournal/models/common';

import { StatusPill as StyledStatusPill } from './StatusPill.styles';

const StatusPill: FC<{ children; isSelected?: boolean }> = ({ children, isSelected }) => {
  return (
    <StyledStatusPill $colorScheme={EMessageStyleType.Default} $isSelected={isSelected}>
      Завершено {children}
    </StyledStatusPill>
  );
};

export default memo(StatusPill);
