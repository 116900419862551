import styled from 'styled-components';

import { ECommonColor } from '../../../../assets/styles/colors';

const NoMessagePlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  min-height: 324px;

  padding: 22px 94px;
`;

const NoMessageIcon = styled.img`
  height: 48px;
  width: 48px;

  margin-bottom: 40px;
`;

const NoMessageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;

  margin-bottom: 12px;
`;

const NoMessageSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;

  color: ${ECommonColor.SecondaryDarkGray};
`;

const Styled = {
  NoMessagePlaceholderWrapper,
  NoMessageTitle,
  NoMessageSubtitle,
  NoMessageIcon,
};

export default Styled;
